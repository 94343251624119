import axios from "axios";
import { API_URL_PHP } from "../utils/Constants";
import { clearLocalStorage } from "../utils/PersistanceManager";


const signup = (name, email, password, confirmPassword) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/signup", {
      "name": name,
      "email": email,
      "password": password,
      "confirmPassword": confirmPassword,
    })
    .then((response) => {
      return response.data;
    }).catch(function (error) {

      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      else if (error.response) {
        if (error.response.status === 400) {
          return Promise.reject(error)
        }
      }
      else {
        return Promise.reject(error)
      }
    });
};

const login = (username, password, authType) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/login", {
      "email": username,
      "password": password,
      "authType": authType
    })
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      else if (error.response) {
        if (error.response.status === 401) {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    });
};

const SocialLogin = (token) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/login", {
      "email": '',
      "password": '',
      "token": token
    })
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      else if (error.response) {
        if (error.response.status === 401) {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    });
};
const resetPassword = (email) => {

  return axios
    .post(API_URL_PHP + "api/forgetpass", {
      "email": email
    })
    .then((response) => {
      // console.log(response.data, " => ", response);
      return response.data;
    }).catch(function (error) {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      else if (error.response) {
        if (error.response.status === 404) {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    });
};

const logout = () => {
  clearLocalStorage();
};

export default {
  signup,
  login,
  SocialLogin,
  resetPassword,
  logout,
};