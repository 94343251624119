import React from 'react'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { timeFormat, reverse, timeFormatInHMorMS, noDataGraph } from '../../../common/GlobalFunctions'
import Loading from '../../loading';
import './style.css';


const HoursChartProductive = (props) => {

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{reverse(label, '-')}</p>
          <div>
            {payload.map((i, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 2,
                  color: '#272c36',
                  width: '140px'  // Adjust this width as needed
                }}
                key={index}
              >
                <span style={{color: '#f5f5f5'}}>{index === 0 ? 'Productive' : 'Unproductive'}</span>
                <span style={{color: '#f5f5f5'}}>{timeFormatInHMorMS(i.value)}</span>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };
  // console.log(props)
  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y + 5} fill="#666" textAnchor="middle" dy={-6}>{`value: ${timeFormat(value)}`}</text>;
  };

  function mergeTrackedTime(productiveArray, unproductiveArray) {
    const merged = {};

    // Add productive time tracked to the merged object
    productiveArray.forEach(item => {
        merged[item.date] = {
            date: item.date,
            productiveTimeTracked: item.totalTimeTracked,
            unproductiveTimeTracked: 0
        };
    });

    // Add unproductive time tracked to the merged object
    unproductiveArray.forEach(item => {
        if (merged[item.date]) {
            merged[item.date].unproductiveTimeTracked = item.totalTimeTracked;
        } else {
            merged[item.date] = {
                date: item.date,
                productiveTimeTracked: 0,
                unproductiveTimeTracked: item.totalTimeTracked
            };
        }
    });

    // Convert the merged object back to an array
    return Object.values(merged);
  }

  let resultBothRecord = mergeTrackedTime(props.productiveDataArray, props.unproductiveDataArray);
  
  const renderLegend = () => (
    <div className="custom-legend">
      <div>
        <span className="legend-color-box" style={{ backgroundColor: '#6fd2c2' }}></span> Productive
      </div>
      <div>
        <span className="legend-color-box" style={{ backgroundColor: '#ff7878' }}></span> Unproductive
      </div>
    </div>
  );

  return (
    <>
      {props.loading ?
        <div className="text-center loader my-4 pt-1 height-min">
          <Loading />
        </div>
        :
        <div className={"timelines-table mt-3 top-projects-box max-" + (props.loading ? " skeleton1 " : "")}>
          <h5 className=' mb-3 font-bold-500 heading'> Trend in Productive & Unproductive Work Hours </h5>
          <ResponsiveContainer width="100%" aspect={3}>
            {resultBothRecord.some(obj => obj.productiveTimeTracked > 0 || obj.unproductiveTimeTracked > 0) ?
              <AreaChart
                width={730}
                height={250}
                data={resultBothRecord}
                margin={{ top: 5, right: 5, left: -1, bottom: 0 }}
              >
                <XAxis
                  dataKey={props.nameKey}
                  tick={{ fontSize: 12 }}
                  tickFormatter={(label) => `${new Date(label).toLocaleString('en-us', { weekday: 'short' }).charAt(0)}`}
                />
                <YAxis
                  tick={{ fontSize: 12 }}
                  tickFormatter={(label) => `${timeFormatInHMorMS(label)}`}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend content={renderLegend} />
                <Area
                  type="monotone"
                  dataKey="productiveTimeTracked"
                  stroke="#28b09d"
                  strokeWidth={2}
                  fill="#28b09d"
                  fillOpacity={0.1}
                />
                <Area
                  type="monotone"
                  dataKey="unproductiveTimeTracked"
                  stroke="#ff6a6a"
                  strokeWidth={2}
                  fill="#ff6a6a"
                  fillOpacity={0.1}
                />
              </AreaChart> :
              noDataGraph()
            }
          </ResponsiveContainer>
        </div>}
    </>
  )
}
export default HoursChartProductive