import {
    ADDTASK_MEMBER_ADDED_SUCCESS,
    ADDTASK_MEMBER_REMOVE_SUCCESS,
    ADDTASK_ADMIN_ADDED_SUCCESS,
    ADDPROJECT_ADMIN_REMOVE_SUCCESS,
    SET_ADD_TASK_SUCCESS,
    SET_ADD_TASK_FAILED,
    FETCH_TASK_LIST_SUCCESS,
    FETCH_TASK_LIST_FAILED,
    FETCH_TASK_DETAILS_SUCCESS,
    FETCH_TASK_DETAILS_FAILED,
    FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS,
    FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_FAILED,
    FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS,
    FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_FAILED,
    MODIFYTASK_MEMBER_ADDED_SUCCESS,
    MODIFYTASK_MEMBER_REMOVE_SUCCESS,
    MODIFYTASK_ADMIN_ADDED_SUCCESS,
    MODIFYTASK_ADMIN_REMOVE_SUCCESS,
    FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS,
    FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_FAILED,
    FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS,
    FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_FAILED,
    SET_UPDATE_TASK_FAILED,
    SET_UPDATE_TASK_SUCCESS,
    MODIFYTASK_ADMIN_POPULATED_SUCCESS,
    MODIFYTASK_MEMBER_POPULATED_SUCCESS,
    SET_CLOSE_TASK_MODEL_SUCCESS,
    FETCH_MORE_TASK_LIST_SUCCESS,
    FETCH_MORE_TASK_LIST_FAILED,
    SET_TASK_ARCHIVE_DB,
    TASK_MEMBER_ARRAY_RESET
} from "../actions/Types";


let initialState = {
    addTaskSearchedMembers: [],
    addTaskSearchedAdmins: [],
    addTaskSelectedMembers: [],
    addTaskSelectedAdmins: [],
    taskList: { taskList: [], loadMore: false },
    taskDetails: { projectID: "", users: [] },
    taskAdded: { message: "", status: 0, subCode: 0 },
    taskUpdated: { message: "", status: 0, subCode: 0 },

    modifyTaskSearchedMembers: [],
    modifyTaskSearchedAdmins: [],
    modifyTaskSelectedMembers: [],
    modifyTaskSelectedAdmins: [],
};


export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS:
            var selected = state.addTaskSelectedMembers;
            var gotten = payload.addTaskSearchedMembers;
            const filteredAddMembers = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));
            return {
                ...state,
                addTaskSearchedMembers: filteredAddMembers,
            };
        case FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_FAILED:
            return {
                ...state,
                addTaskSearchedMembers: [],
            };
        case FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS:
            var selected = state.addTaskSelectedAdmins;
            var gotten = payload.addTaskSearchedAdmins;
            const filteredAddAdmins = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));
            return {
                ...state,
                addTaskSearchedAdmins: filteredAddAdmins,
            };
        case FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_FAILED:
            return {
                ...state,
                addTaskSearchedAdmins: [],
            };

        case FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS:
            var selected = state.modifyTaskSelectedMembers;
            var gotten = payload.modifyTaskSearchedMembers;
            const filteredModifyMembers = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));
            return {
                ...state,
                modifyTaskSearchedMembers: filteredModifyMembers,
            };
        case FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_FAILED:
            return {
                ...state,
                modifyTaskSearchedMembers: [],
            };
        case FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS:
            var selected = state.modifyTaskSelectedAdmins;
            var gotten = payload.modifyTaskSearchedAdmins;
            const filteredModifyAdmins = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));
            return {
                ...state,
                modifyTaskSearchedAdmins: filteredModifyAdmins,
            };
        case FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_FAILED:
            return {
                ...state,
                modifyTaskSearchedAdmins: [],
            };

        case ADDTASK_MEMBER_ADDED_SUCCESS:
            let memebers = state.addTaskSelectedMembers;
            memebers = memebers.concat(payload.memberAdded);

            return {
                ...state,
                addTaskSelectedMembers: memebers,
            };
        case ADDTASK_MEMBER_REMOVE_SUCCESS:
            let arr = state.addTaskSelectedMembers;
            var filter = arr.filter(a => !(a === payload.memberRemoved));
            console.log(filter);
            return {
                ...state,
                addTaskSelectedMembers: filter
            };
        case ADDTASK_ADMIN_ADDED_SUCCESS:
            let admins = state.addTaskSelectedAdmins;
            admins = admins.concat(payload.adminAdded);

            return {
                ...state,
                addTaskSelectedAdmins: admins,
            };
        case ADDPROJECT_ADMIN_REMOVE_SUCCESS:
            let arr1 = state.addTaskSelectedAdmins;
            var filterapsa = arr1.filter(a => !(a === payload.adminRemoved));
            console.log(filterapsa);
            return {
                ...state,
                addTaskSelectedAdmins: filterapsa
            };

        case FETCH_TASK_LIST_SUCCESS:
            return {
                ...state,
                taskList: {
                    taskList: payload.taskList.taskList
                    , loadMore: payload.loadMore
                },
            };
        case FETCH_TASK_LIST_FAILED:
            return {
                ...state,
                taskList: { taskList: [], loadMore: false },
            };

        case FETCH_MORE_TASK_LIST_SUCCESS:
            let list = state.taskList;
            let con = list.taskList.concat(payload.taskList.taskList);
            return {
                ...state,
                taskList: {
                    taskList: con
                    , loadMore: payload.loadMore
                },
            };
        case FETCH_MORE_TASK_LIST_FAILED:
            return {
                ...state,
                taskList: { taskList: [], loadMore: false },
            };

        case FETCH_TASK_DETAILS_SUCCESS:
            return {
                ...state,
                taskDetails: payload.taskDetails,
            };
        case FETCH_TASK_DETAILS_FAILED:
            return {
                ...state,
                taskDetails: { projectID: "", users: [] },
            };

        case SET_ADD_TASK_SUCCESS:
            return {
                ...state,
                taskAdded: payload.taskAdded,
            };
        case SET_ADD_TASK_FAILED:
            return {
                ...state,
                taskAdded: { message: "", status: 0, subCode: 0 },
            };


        case MODIFYTASK_MEMBER_POPULATED_SUCCESS:
            return {
                ...state,
                modifyTaskSelectedMembers: payload.memberAdded,
            };
        case MODIFYTASK_ADMIN_POPULATED_SUCCESS:
            return {
                ...state,
                modifyTaskSelectedAdmins: payload.adminAdded,
            };

        case MODIFYTASK_MEMBER_ADDED_SUCCESS:
            let modify_memebers = state.modifyTaskSelectedMembers;
            modify_memebers = modify_memebers.concat(payload.memberAdded);

            return {
                ...state,
                modifyTaskSelectedMembers: modify_memebers,
            };
        case MODIFYTASK_MEMBER_REMOVE_SUCCESS:
            let modifyprojectSelecteMembers = state.modifyTaskSelectedMembers;
            var filtermpsm = modifyprojectSelecteMembers.filter(a => !(a === payload.memberRemoved));
            return {
                ...state,
                modifyTaskSelectedMembers: filtermpsm
            };

        case MODIFYTASK_ADMIN_ADDED_SUCCESS:
            let modifySelectedAdmins = state.modifyTaskSelectedAdmins;
            modifySelectedAdmins = modifySelectedAdmins.concat(payload.adminAdded);

            return {
                ...state,
                modifyTaskSelectedAdmins: modifySelectedAdmins,
            };
        case MODIFYTASK_ADMIN_REMOVE_SUCCESS:
            let mpsa = state.modifyTaskSelectedAdmins;
            var filtermps = mpsa.filter(a => !(a === payload.adminRemoved));
            console.log(filtermps);
            return {
                ...state,
                modifyTaskSelectedAdmins: filtermps
            };

        case SET_UPDATE_TASK_SUCCESS:
            return {
                ...state,
                taskUpdated: payload.taskUpdated,
            };
        case SET_UPDATE_TASK_FAILED:
            return {
                ...state,
                taskUpdated: { message: "", status: 0, subCode: 0 },
            };

        case SET_CLOSE_TASK_MODEL_SUCCESS:
            return {
                ...state,

                addTaskSearchedMembers: [],
                addTaskSearchedAdmins: [],
                addTaskSelectedMembers: [],
                addTaskSelectedAdmins: [],

                modifyTaskSearchedMembers: [],
                modifyTaskSearchedAdmins: [],
                modifyTaskSelectedMembers: [],
                modifyTaskSelectedAdmins: [],
            };

        case SET_TASK_ARCHIVE_DB:
            let oldtsk = state.taskList;
            var ndt = oldtsk.taskList.filter(a => !(a.id === payload.taskId));
            return {
                ...state,
                taskList: {
                    taskList: ndt, loadMore: oldtsk.loadMore
                },
            };

        case TASK_MEMBER_ARRAY_RESET:
            return {
                ...state,
                addTaskSelectedMembers: [],
            };    


        default:
            return state;
    }
}
