import subDays from 'date-fns/subDays';
import {
    WEBS_AND_APPS_DATE_SELECTION_SUCCESS,
    // FETCH_WEBS_AND_APPS_USERS_DROPDOWN_FAILED,
    // FETCH_WEBS_AND_APPS_USERS_DROPDOWN_SUCCESS,
    // WEBS_AND_APPS_USER_SELECTION_SUCCESS,
    WEBS_AND_APPS_USER_SELECTION_UPDATE,
    FETCH_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS,
    FETCH_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
    FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS,
    FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
    FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_SUCCESS,
    FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_FAILED,
    WEBS_AND_APPS_USERS_CLEAR_SUCCESS,
    WEBS_AND_APPS_GROUP_SELECTION_UPDATE,
    WEBS_AND_APPS_USER_ADDED_SUCCESS,
    WEBS_AND_APPS_USER_REMOVE_SUCCESS,
    WEBS_AND_APPS_GROUPS_CLEAR_SUCCESS,
    FETCH_WEBS_AND_APPS_USERS_USAGE,
    FETCH_WEBS_AND_APPS_USERS_USAGE_FAILED,
    FETCH_WEBS_AND_APPS_MORE_USERS_USAGE,
    FETCH_MORE_CATALOG_TITLE_DETAILS_SUCCESS,
    FETCH_MORE_CATALOG_TITLE_DETAILS_FAILED,
    FETCH_CATALOG_TITLE_DETAILS_SUCCESS,
    FETCH_CATALOG_TITLE_DETAILS_FAILED
} from "../actions/Types";

import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager';

let selectedDateRange = JSON.parse(getLocalStorageValue("Web_n_Apps_Date_Selection"));
let selectedUsers = JSON.parse(getLocalStorageValue("Web_n_Apps_User_Selection"));
let selectedGroups = JSON.parse(getLocalStorageValue("Web_n_Apps_Group_Selection"));
let listUsers = [];
let listGroups = [];

let initialState = {

    websnAppsDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
    webnAppsUsageDetails: { "catalogUsage": [], "loadMore": false },
    webnAppsUsersDropDown: { users: [], groups: [] },
    webnAppsSelectedUsers: selectedUsers ? selectedUsers : [],
    webnAppsSelectedGroups: selectedGroups ? selectedGroups : [],
    webnAppsUserUsageDetails: { "catalogUser": [], "loadMore": false },
    catalogTitles: { "catalog": [], loadMore: false }
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        // case FETCH_WEBS_AND_APPS_USERS_DROPDOWN_SUCCESS:
        //     payload.webnAppsUsersDropDown.users.forEach((user) => {
        //         user['checked'] = false;
        //         if (selectedUsers) {
        //             selectedUsers.forEach((selectedUser) => {
        //                 if (selectedUser === user.id) {
        //                     user.checked = true;
        //                 }
        //             })
        //         }
        //     });
        //     return {
        //         ...state,
        //         webnAppsUsersDropDown: payload.webnAppsUsersDropDown,
        //     };

        // case FETCH_WEBS_AND_APPS_USERS_DROPDOWN_FAILED:
        //     return {
        //         ...state,
        //         webnAppsUsersDropDown:  { users: [], groups: [] },
        //     };


        case WEBS_AND_APPS_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Web_n_Apps_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                websnAppsDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };

        // All for selected users array
        // case WEBS_AND_APPS_USER_SELECTION_SUCCESS:
        // setLocalStorageValue("Web_n_Apps_User_Selection", JSON.stringify(payload.selectedUsers));
        // return {
        //     ...state,
        //     webnAppsSelectedUsers: payload.selectedUsers
        // };
        // tested
        case WEBS_AND_APPS_USER_SELECTION_UPDATE:
            let newUserListState = state.webnAppsUsersDropDown
            newUserListState.users.forEach((user, index) => {
                if (payload.changedUser.id === user.id) {
                    user.checked = payload.changedUser.checked;
                }
            });
            return {
                ...state,
                webnAppsUsersDropDown: { users: newUserListState.users, groups: [] }
            };
        // tested
        case WEBS_AND_APPS_USER_ADDED_SUCCESS:
            let users = state.webnAppsSelectedUsers;
            users = users.concat(payload.userAdded);
            setLocalStorageValue("Web_n_Apps_User_Selection", JSON.stringify(users));
            let searched = state.webnAppsUsersDropDown.users;
            var filter = searched.filter(a => !(a === payload.userAdded));
            return {
                ...state,
                webnAppsSelectedUsers: users,
                webnAppsUsersDropDown: { users: filter, groups: state.webnAppsUsersDropDown.groups }
            };
        // tested
        case WEBS_AND_APPS_USER_REMOVE_SUCCESS:
            let uzers = state.webnAppsUsersDropDown.users;
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false;
                uzers.push(payload.userRemoved);
                uzers.sort((a, b) => a.name.localeCompare(b.name));
            }

            let arr = state.webnAppsSelectedUsers;
            var filter1 = arr.filter(a => !(a === payload.userRemoved));
            setLocalStorageValue("Web_n_Apps_User_Selection", JSON.stringify(filter1));
            return {
                ...state,
                webnAppsSelectedUsers: filter1,
                webnAppsUsersDropDown: { users: uzers, groups: state.webnAppsUsersDropDown.groups }
            };
        // tested
        case WEBS_AND_APPS_USERS_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false;
            });

            setLocalStorageValue("Web_n_Apps_User_Selection", JSON.stringify(null));
            return {
                ...state,
                webnAppsSelectedUsers: [],
                webnAppsUsersDropDown: { users: listUsers, groups: state.webnAppsUsersDropDown.groups }
            };


        case FETCH_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS:
            return {
                ...state,
                webnAppsUsageDetails: { "catalogUsage": payload.webnAppsUsageDetails.catalogUsage, "loadMore": payload.loadMore }
            };
        case FETCH_WEBS_AND_APPS_USAGE_DETAILS_FAILED:
            return {
                ...state,
                webnAppsUsageDetails: { "catalogUsage": [], "loadMore": false },
            };
        case FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS:
            let uwau = state.webnAppsUsageDetails;
            let temp = uwau.catalogUsage.concat(payload.webnAppsUsageDetails.catalogUsage);

            return {
                ...state,
                webnAppsUsageDetails: { "catalogUsage": temp, loadMore: payload.loadMore },
            };
        case FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_FAILED:
            return {
                ...state,
                webnAppsUsageDetails: { "catalogUsage": [], loadMore: false },
            };
        case FETCH_WEBS_AND_APPS_USERS_USAGE:
            return {
                ...state,
                webnAppsUserUsageDetails: { "catalogUser": payload.listUsers.catalogUser, "loadMore": payload.loadMore }
            };
        case FETCH_WEBS_AND_APPS_USERS_USAGE_FAILED:
            return {
                ...state,
                webnAppsUserUsageDetails: { "catalogUsage": [], "loadMore": false },
            };
        case FETCH_WEBS_AND_APPS_MORE_USERS_USAGE:
            let adcti = state.webnAppsUserUsageDetails;
            let ytemu = adcti.catalogUser.concat(payload.listUsers.catalogUser);

            return {
                ...state,
                webnAppsUserUsageDetails: { "catalogUser": ytemu, "loadMore": payload.loadMore }
            };


        // user and group dropdown code bellow

        // case WEBS_AND_APPS_GROUP_SELECTION_SUCCESS:
        //     setLocalStorageValue("Web_n_Apps_User_Selection", JSON.stringify(payload.selectedUsers));
        //     return {
        //         ...state,
        //         webnAppsSelectedUsers: payload.selectedUsers
        //     };
        case FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Web_n_Apps_User_Selection"));
            selectedGroups = JSON.parse(getLocalStorageValue("Web_n_Apps_Group_Selection"));

            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false;
            });

            listUsers = payload.listUsersDropDown.users;
            const filteredAddMembers = selectedUsers !== null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users;

            listGroups = payload.listUsersDropDown.groups;
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false;
                if (selectedGroups) {
                    selectedGroups.forEach((selectedGroup) => {
                        if (selectedGroup.id === group.id) group.checked = true;
                    })
                }
            });

            return {
                ...state,
                webnAppsUsersDropDown: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            };

        case FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_FAILED:
            return {
                ...state,
                webnAppsUsersDropDown: { users: [], groups: [] },
            };

        // tested
        case WEBS_AND_APPS_GROUP_SELECTION_UPDATE:

            let ff_new_user = state.webnAppsSelectedGroups
            ff_new_user.forEach((v, index) => {
                if (payload.changedGroup.id === v.id) {
                    v.checked = payload.changedGroup.checked;
                } else {
                    v.checked = false
                }
            });

            let newUserListState_0 = state.webnAppsUsersDropDown
            newUserListState_0.groups.forEach((group, index) => {
                if (payload.changedGroup.id === group.id) {
                    group.checked = payload.changedGroup.checked;
                } else {
                    group.checked = false
                }
            });

            setLocalStorageValue("Web_n_Apps_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)));
            return {
                ...state,
                webnAppsUsersDropDown: { users: newUserListState_0.users, groups: newUserListState_0.groups },
                webnAppsSelectedGroups: ff_new_user
            };


        // tested
        case WEBS_AND_APPS_GROUPS_CLEAR_SUCCESS:
            listGroups.forEach((group) => {
                group['checked'] = false;
            });

            setLocalStorageValue("Web_n_Apps_Group_Selection", JSON.stringify(null));
            return {
                ...state,
                webnAppsUsersDropDown: { users: state.webnAppsUsersDropDown.users, groups: listGroups }
            };

        case FETCH_CATALOG_TITLE_DETAILS_SUCCESS:
            return {
                ...state,
                catalogTitles: { "catalog": payload.catalogTitle, loadMore: payload.loadMore },
            };

        case FETCH_CATALOG_TITLE_DETAILS_FAILED:
            return {
                ...state,
                catalogTitles: { "catalog": [], loadMore: false }
            };

        case FETCH_MORE_CATALOG_TITLE_DETAILS_SUCCESS:
            let catTitels = state.catalogTitles.catalog;
            let concatCatTitles = catTitels.concat(payload.moreCatalogTitle);
            return {
                ...state,
                catalogTitles: { "catalog": concatCatTitles, loadMore: payload.loadMore },
            };

        case FETCH_MORE_CATALOG_TITLE_DETAILS_FAILED:
            return {
                ...state,
                catalogTitles: state.catalogTitles,
            };


        default:
            return state;
    }
}
