import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { padToXDigits, timeFormat } from '../../common/GlobalFunctions'
import './style.css';
import CustomToolTip from '../CustomToolTip';
import Loading from '../loading';

const SingleUserTimeTracked = (props) => {
  const { loading, userDashboardComulativeTime } = props;

  //activeUsers: 5  manualTime: 0  neutralTime: 2  productiveTime: 27171  totalTime: 1115669  unProductiveTime: 1070371  unRatedTime: 18125


  const productiveTimePercentage = (
    userDashboardComulativeTime.activityTrack.totalTime <= 0 ? 0 : (userDashboardComulativeTime.activityTrack.Productive * 100) / userDashboardComulativeTime.activityTrack.totalTime
  );
  const unProductiveTimePercentage = (
    userDashboardComulativeTime.activityTrack.totalTime <= 0 ? 0 : (userDashboardComulativeTime.activityTrack.UnProductive * 100) / userDashboardComulativeTime.activityTrack.totalTime
  );

  const unRatedTimePercentage = (
    userDashboardComulativeTime.activityTrack.totalTime <= 0 ? 0 : (userDashboardComulativeTime.activityTrack.Unrated * 100) / userDashboardComulativeTime.activityTrack.totalTime
  );

  const neutralTimePercentage = (
    userDashboardComulativeTime.activityTrack.totalTime <= 0 ? 0 : (userDashboardComulativeTime.activityTrack.Neutral * 100) / userDashboardComulativeTime.activityTrack.totalTime
  );
  const idleTimePercentage = (
    userDashboardComulativeTime.activityTrack.totalTime <= 0 ? 0 : (userDashboardComulativeTime.activityTrack.idleTime * 100) / userDashboardComulativeTime.activityTrack.totalTime
  );

  const productiveTimeFormatted = timeFormat(userDashboardComulativeTime.activityTrack.Productive);
  const timeTrackedFormatted = timeFormat(userDashboardComulativeTime.activityTrack.totalTime);
  const unProductiveTimeFormatted = timeFormat(userDashboardComulativeTime.activityTrack.UnProductive);
  const neutralTimeFormatted = timeFormat(userDashboardComulativeTime.activityTrack.Neutral);
  const idleTimeFormatted = timeFormat(userDashboardComulativeTime.activityTrack.idleTime);
  const unRatedTimeFormatted = timeFormat(userDashboardComulativeTime.activityTrack.Unrated);

  return (
    <>
      <div className={"time-tracked-box single-user-time-tracked d-flex  my-md-4 mt-md-2 justify-content-between gap-20 overflow-inherit" + (loading ? " skeleton1 bg-white box-shadow-light" : "")}>
        {loading ?
          <div className="text-center w-100 bg-white loader my-4 pt-1 height-min">
            <Loading />
          </div>
          :
          <>
            <div className='time last-gray total-time-tracked'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-1-team-dash.svg"} />
                </div>
                <div className="div-1">
                  <h4 className='text-bluish time-track-text fs-16 font-wt-600 mb-md-2'>Total Time Tracked</h4>

                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end mt-3 mt-md-0'>

                <span className='text-black fs-25 fs-lg-30  font-bold ms-2 '>{timeTrackedFormatted} </span>


              </div>
            </div>


            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-2-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-greenish time-track-text fs-16 font-wt-600 mb-md-2'>Productive Time</h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray font-light m-0'><span className='text-green'>{Math.round(productiveTimePercentage)}%</span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end '>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{productiveTimeFormatted}</span>
              </div>
            </div>



            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-3-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-redish time-track-text fs-16 font-wt-600 mb-md-2'>Unproductive Time</h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray  font-light m-0'><span className='text-green'>{Math.round(unProductiveTimePercentage)}%</span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{unProductiveTimeFormatted}</span>
              </div>
            </div>





            {/* <div className='time bg-white'>
              <h4 className='text-black fs-16 font-medium mb-1'>Productive Time</h4>
              <div className='flex icon d-flex align-items-center mb-md-2 pb-1'>
                <div className='img'>
                  <img src={"images/productive-time.svg"} />
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{productiveTimeFormatted}</span>

                  <p className='text-gray ms-2 fs-14 font-light m-0'> <span className='text-green'>{Math.round(productiveTimePercentage)}%</span></p>
                </div>
              </div>
            </div> */}


            {/* <div className='time bg-white'>
              <h4 className='text-black fs-16 font-medium mb-1'> Unproductive Time </h4>
              <div className='flex icon d-flex align-items-center mb-md-2 pb-1'>
                <div className='img'>
                  <img src={"images/unprodutive-time.svg"} />
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{unProductiveTimeFormatted}</span>

                  <p className='text-gray ms-2 fs-14 font-light m-0'> <span className='text-red'>{Math.round(unProductiveTimePercentage)}%</span></p>
                </div>
              </div>
            </div> */}
            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-6-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-yellowish time-track-text fs-16 font-wt-600 mb-md-2'>Neutral Time  <CustomToolTip iconColor="balck" details="Hours spent in apps and websites rated neutral i.e. neither productive or unproductive." /></h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray  font-light m-0'><span className='text-green'>{Math.round(neutralTimePercentage)}%</span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{neutralTimeFormatted}</span>
              </div>
            </div>


            {/* <div className='time bg-white'>
              <h4 className='text-black fs-16 font-medium mb-1'> Neutral Time
                <CustomToolTip iconColor="balck" details="Hours spent in apps and websites rated neutral i.e. neither productive or unproductive." /></h4>
              <div className='flex icon d-flex align-items-center mb-md-2 pb-1'>
                <div className='img'>
                  <img src={"images/neutral-time.svg"} />
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{neutralTimeFormatted}</span>

                  <p className='text-gray ms-2 fs-14 font-light m-0'><span className='bg-text-gray'>{Math.round(neutralTimePercentage)}%</span></p>
                </div>
              </div>
            </div> */}
            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-4-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-greyish time-track-text fs-16 font-wt-600 mb-md-2'>Unrated Time  <CustomToolTip iconColor="balck" details="Hours spent in apps and websites rated neither productive or unproductive." /></h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray font-light m-0'><span className='text-green'>{Math.round(unRatedTimePercentage)}%</span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end '>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{unRatedTimeFormatted}</span>
              </div>
            </div>

            {/* <div className='time bg-white'>
              <h4 className='text-black fs-16 font-medium mb-1'> Unrated Time
                <CustomToolTip iconColor="balck" details="Hours spent in apps and websites rated neither productive or unproductive." /></h4>
              <div className='flex icon d-flex align-items-center mb-md-2 pb-1'>
                <div className='img'>
                  <img src={"images/manual-time.svg"} />
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{unRatedTimeFormatted}</span>

                  <p className='text-gray ms-2 fs-14 font-light m-0'> <span className='text-blue'>{Math.round(unRatedTimePercentage)}%</span></p>
                </div>
              </div>
            </div> */}

            <div className='time bg-white last-gray gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-5-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1 idle-time-tooltip">
                  <h4 className='text-purple time-track-text fs-16 font-wt-600 mb-md-2'>Idle Time  <CustomToolTip iconColor="balck" details="All the recorded hours that do not suspect any mouse or keyboard activity." /></h4>
                  <div className='Total-active d-flex align-items-center'>
                    {/* <p className='text-gray  font-light m-0'><span className='text-green'>{Math.round(idleTimeFormatted)}%</span></p> */}
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end mt-3 mt-md-0'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{idleTimeFormatted}</span>
              </div>
            </div>


            {/* <div className='time bg-white'>
              <h4 className='text-black fs-16 font-medium mb-1'> Idle Time
                <CustomToolTip iconColor="balck" details="All the recorded hours that do not suspect any mouse or keyboard activity." /></h4>
              <div className='flex icon d-flex align-items-center mb-md-2 pb-1'>
                <div className='img'>
                  <img src={"images/idle-time.svg"} />
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-black fs-25 fs-lg-30 mb-3 font-bold ms-2'>{idleTimeFormatted}</span>
                </div>
              </div>
            </div> */}
          </>}

      </div>
    </>
  )
}
export default SingleUserTimeTracked