import React, { useState } from 'react'

const ResultMessage = (props) => {
    const { messageType, message } = props;

    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => {
        setIsVisible(false);
    };

    return isVisible ? (
        <div className={"message-box message-" + messageType}>
            <h5 className='d-flex'>
                <div className='img'>
                    <img className='tick-icon' src={"images/tick-green.svg"} />
                    <img className='warning-icon' src={"images/warning.svg"} />
                    <img className='error-icon' src={"images/error.svg"} />
                </div>
                <p>{message}</p>
            </h5>
            <span className='cross-green'><img src={"images/cross-green.svg"} onClick={() => { handleClose() }} /></span>
            <span className='cross-yellow'><img src={"images/cross-yellow.svg"} onClick={() => { handleClose() }} /></span>
            <span className='cross-red'><img src={"images/cross-red.svg"} onClick={() => { handleClose() }} /></span>
        </div>

    ) : null
}

export default ResultMessage