import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from 'react-redux'
import store from './redux/store'
import Navigation from './navigation';
import OnInitializtion from "./components/OnInitialization/OnInitializtion";

function App() {

  return (
    <>
      <Provider store={store}>
        <OnInitializtion />
        <Navigation />
      </Provider>
    </>
  );
}

export default App;

//its testing commit for sub branch
// https://www.bezkoder.com/react-hooks-redux-login-registration-example/
// https://medium.com/@timtan93/states-and-componentdidmount-in-functional-components-with-hooks-cac5484d22ad