import React, { useEffect, useState } from 'react';
import './style.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { dateToTime, getActiveTimezone } from '../../common/GlobalFunctions';
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";


const CustomLightbox = (props) => {
    const { isOpen, setIsOpen, currentImageIndex, setCurrentImageIndex, screenShotList } = props;

    let arrayImages = [];
    screenShotList.forEach((element, index) => {
        arrayImages.push({
            'src': element['imageUrl'], title: element['userName'] + " - " + dateToTime(element.dateCaptured, getActiveTimezone().timezone),
            // description:
                // <Row className='d-flex m-0 pt-3 justify-content-center key-mouse'>
                //     <div className='w-25'>
                //         <div className='d-flex imge-percent mb-2 align-items-center'>
                //             <img src='images/keyboard-white-icon.svg' alt='mouse' height={15} />
                //             <p className='ms-2'>{element.keyPunchPercentPerMin}%</p>
                //         </div>
                //         <ProgressBar className='fs-keyboard w-100 ' variant="success" now={element.keyPunchPercentPerMin} />
                //     </div>

                //     <div className='mb-3 w-25'>
                //         <div className='d-flex imge-percent mb-2 align-items-center'>
                //             <img src='images/mouse-white-icon.svg' alt='mouse' height={15} />
                //             <p className='ms-2'>{element.mouseMovementPercentPerMin}%</p>
                //         </div>
                //         <ProgressBar className='fs-mouse w-100' variant="success" now={element.mouseMovementPercentPerMin} />
                //     </div>
                // </Row>
        })
    })
    const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(3);
    const [zoomInMultiplier, setZoomInMultiplier] = useState(2);
    const [animationDuration, setAnimationDuration] = useState(500);

    return (
        <Lightbox
            open={isOpen}
            close={() => setIsOpen(false)}
            index={currentImageIndex}
            slides={arrayImages}
            plugins={[Zoom, Fullscreen, Slideshow, Captions]}
            animation={{ zoom: animationDuration }}
            zoom={{
                maxZoomPixelRatio,
                zoomInMultiplier,
            }}
        />
    );
};

export default CustomLightbox;