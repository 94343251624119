import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import './style.css';

export default class SliderCompny extends React.Component {

  state = {
    selectedCompany: { id: "", name: "" }
  }

  companySelection = (company) => {

    this.setState({
      selectedCompany: company
    })
    this.props.onCompanySelection(company)
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="sliderhome">
        <Slider {...settings}>
          {this.props.userCompanies.map((ucomps) => (
            //key given to resolve error of unique key
            <div className={ucomps.userStatus === "Active" && ucomps.orgStatus === "Active" ? "enabled" : "disabled"} key={ucomps.id} onClick={(e) => {
              // this.companySelection(ucomps.id, ucomps.name, ucomps.userRole);
              this.companySelection(ucomps);
            }}>
              <div className={ucomps.id == this.state.selectedCompany.id ? "box active" : "box"}><Link to="#" >{ucomps.name}
              <div className="img-box-building">
              <img className="building-icon" src={"images/building-icon.svg"} />
                <img className="building-black-icon" src={"images/building-black-icon.svg"}/>
                </div>
                <h4>{ucomps.userRole.includes("GroupAdmin") ? "Group Admin" : ucomps.userRole[0]}</h4>
                
                
                </Link>

              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

}



