import {
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_USER_SETTINGS_FAILED,
  FETCH_SINGLE_USER_SETTINGS_SUCCESS,
  FETCH_SINGLE_USER_SETTINGS_FAILED,
  FETCH_MORE_USER_SETTINGS_FAILED,
  FETCH_MORE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_FAILED,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILED,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILED,
  UPDATE_SINGLE_USER_HOUR_RATE_SUCCESS,
  UPDATE_SINGLE_USER_HOUR_RATE_FAILED,
  UPDATE_SINGLE_USER_FULL_NAME_SUCCESS,
  UPDATE_SINGLE_USER_FULL_NAME_FAILED,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE_FAILED,
  UPDATE_SHOWABLE_COLUMNS_SUCCESS,
  UPDATE_USER_HOUR_RATE_SUCCESS,
  SEARCH_USERS_FOR_USER_SETTINGS_SUCCESS,
  SEARCH_USERS_FOR_USER_SETTINGS_FAILED,
  ADD_USER_TO_SETTINGS_SUCCESS,
  CLEARE_SEARCH_USERS_SUCCESS,
  CLEARE_SEARCH_SELECTED_USERS_SUCCESS
} from "../actions/Types";
import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";

const shownColumns = JSON.parse(getLocalStorageValue("shownColumnsInUMS"));
let initialState = {
  singleUserData: {
    "userId": "",
    "userFullName": "",
    "userEmail": "",
    "profileUrl": "",
    "userRole": "",
    "userStatus": "",
    "hourRate": "",
    "appInfo": [],
    "timeZone": "",
    "timeZoneOffset": "",
    "settings": {
        "idleTime": "",
        "silentMonitoring": "",
        "screenShotEnable": "",
        "screenshotCaptureTime": "",
        "developer_enabled": ""
    }
  },
  userSettings: { list: [], loadMore: false },
  enabledDisabledUsers: { list: [] },
  searchedUsers: [],
  searchedSelectedUsers: [],
  showableColumns: shownColumns ? { columns: shownColumns } : { columns: [1, 1, 1, 1, 1] },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_SETTINGS_SUCCESS:
      let newUserSettings = payload.userSettings;
      newUserSettings.forEach((i) => {
        i["loading"] = false;
      });
      return {
        ...state,
        userSettings: { list: newUserSettings, loadMore: payload.loadMore },
      };
    case FETCH_USER_SETTINGS_FAILED:
      return {
        ...state,
        userSettings: { list: [], loadMore: false },
      };
    case FETCH_SINGLE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        singleUserData: payload.singleUserDD,
      };
    case FETCH_SINGLE_USER_SETTINGS_FAILED:
      return {
        ...state,
        singleUserData: {
          "userId": "",
          "userFullName": "",
          "userEmail": "",
          "profileUrl": "",
          "userRole": "",
          "userStatus": "",
          "hourRate": "",
          "appInfo": [],
          "timeZone": "",
          "timeZoneOffset": "",
          "settings": {
              "idleTime": "",
              "silentMonitoring": "",
              "screenShotEnable": "",
              "screenshotCaptureTime": "",
              "developer_enabled": ""
          }
        },
      };
    case FETCH_MORE_USER_SETTINGS_SUCCESS:
      let us = state.userSettings;
      let con = us.list.concat(payload.userSettings);
      con.forEach((i) => {
        i["loading"] = false;
      });
      return {
        ...state,
        userSettings: { list: con, loadMore: payload.loadMore },
      };
    case FETCH_MORE_USER_SETTINGS_FAILED:
      return {
        ...state,
        userSettings: { list: [], loadMore: false },
      };   
    case UPDATE_USER_SETTINGS_SUCCESS:
      let newuserSettings = state.userSettings;
      let aloneUser = state.singleUserData;

      
      if (payload.settingsUpdate.status === 200) {
        newuserSettings.list.forEach((element) => {
          if (element.userId === payload.userId) {
            if (payload.prefName === "screenShotEnable") {
              element.settings.screenShotEnable = payload.prefValue;
              aloneUser.settings.screenShotEnable = payload.prefValue;
            }
            if (payload.prefName === "screenshotCaptureTime") {
              element.settings.screenshotCaptureTime = payload.prefValue;
              aloneUser.settings.screenshotCaptureTime = payload.prefValue;
            }
            if (payload.prefName === "idleTime") {
              element.settings.idleTime = payload.prefValue;
              aloneUser.settings.idleTime = payload.prefValue;
            }
            if (payload.prefName === "silentMonitoring") {
              element.settings.silentMonitoring = payload.prefValue;
              aloneUser.settings.silentMonitoring = payload.prefValue;
            }
            element.loading = false;
          }
        });
      } else {
        newuserSettings.list.forEach((element) => {
          if (element.userId === payload.userId) {
            element.loading = false;
          }
        });
      }
      
      return {
        ...state,
        userSettings: newuserSettings,
        singleUserData: aloneUser,
      };
    case UPDATE_USER_SETTINGS_FAILED:
      return {
        ...state,
        userSettings: { list: [], loadMore: false },
      };
    case UPDATE_USER_ROLE_SUCCESS:
      let newUserRoleUpdate = state.userSettings;

      if (payload.roleUpdate.status === 200) {
        newUserRoleUpdate.list.forEach((element) => {
          if (element.userId === payload.userId) {
            element.userRole = payload.userRole;
            element.loading = false;
          }
        });
      } else {
        newUserRoleUpdate.list.forEach((element) => {
          if (element.userId === payload.userId) {
            element.loading = false;
          }
        });
      }

      return {
        ...state,
        userSettings: newUserRoleUpdate,
      };
    case UPDATE_USER_ROLE_FAILED:
      return {
        ...state,
        userSettings: { list: [], loadMore: false },
      };
    case UPDATE_USER_STATUS_SUCCESS:
      let newuserEDSettings = state.userSettings;

      if (payload.userStatus.status === 200) {
        newuserEDSettings.list.forEach((element) => {
          if (element.userId === payload.userId) {
            element.userStatus = payload.uzerStatus;
            element.loading = false;
          }
        });
      } else {
        newuserEDSettings.list.forEach((element) => {
          if (element.userId === payload.userId) {
            element.loading = false;
          }
        });
      }

      return {
        ...state,
        userSettings: newuserEDSettings,
      };
    case UPDATE_USER_STATUS_FAILED:
      return {
        ...state,
        userSettings: { list: [], loadMore: false },
      };
    case UPDATE_SINGLE_USER_HOUR_RATE_SUCCESS:
      let userUpHrsSettings = state.userSettings;
      userUpHrsSettings.list.forEach((element) => {
        if (element.userId === payload.userId) {
          element.hourRate = payload.hourRate;
          // element.loading = false;
        }
      })
      // console.log('userUpHrsSettings', userUpHrsSettings);
      
      let updateUserHRate = state.singleUserData;
      updateUserHRate.hourRate = payload.hourRate;
      return {
        ...state,
        singleUserData: updateUserHRate,
        userSettings: userUpHrsSettings,
      };
    case UPDATE_SINGLE_USER_HOUR_RATE_FAILED:
      return {
        ...state,
        singleUserData: {
          "userId": "",
          "userFullName": "",
          "userEmail": "",
          "profileUrl": "",
          "userRole": "",
          "userStatus": "",
          "hourRate": "",
          "appInfo": [],
          "timeZone": "",
          "timeZoneOffset": "",
          "settings": {
              "idleTime": "",
              "silentMonitoring": "",
              "screenShotEnable": "",
              "screenshotCaptureTime": "",
              "developer_enabled": ""
          }
        }
      };
    case UPDATE_SINGLE_USER_FULL_NAME_SUCCESS:
      var filtered = state.userSettings.list.filter(obj => {
        return obj.userId === payload.userId;
      });
      filtered[0].userFullName = payload.userName;
      let updateUserFName = state.singleUserData;
      updateUserFName.userFullName = payload.userName;
      return {
        ...state,
        singleUserData: updateUserFName,
      };
    case UPDATE_SINGLE_USER_FULL_NAME_FAILED:
      return {
        ...state,
        singleUserData: {
          "userId": "",
          "userFullName": "",
          "userEmail": "",
          "profileUrl": "",
          "userRole": "",
          "userStatus": "",
          "hourRate": "",
          "appInfo": [],
          "timeZone": "",
          "timeZoneOffset": "",
          "settings": {
              "idleTime": "",
              "silentMonitoring": "",
              "screenShotEnable": "",
              "screenshotCaptureTime": "",
              "developer_enabled": ""
          }
        }
      };
    case UPDATE_PROFILE_IMAGE_SUCCESS:
      let uzers_profile = state.singleUserData;
      let profile_img = payload.profileImage.data.profileUrl;
      uzers_profile.profileUrl = profile_img
      return {
        ...state,
        singleUserData: uzers_profile,
      };
    case UPDATE_PROFILE_IMAGE_FAILED:
      return {
        ...state,
        singleUserData: {
          "userId": "",
          "userFullName": "",
          "userEmail": "",
          "profileUrl": "",
          "userRole": "",
          "userStatus": "",
          "hourRate": "",
          "appInfo": [],
          "timeZone": "",
          "timeZoneOffset": "",
          "settings": {
              "idleTime": "",
              "silentMonitoring": "",
              "screenShotEnable": "",
              "screenshotCaptureTime": "",
              "developer_enabled": ""
          }
        }
      };
    case UPDATE_SHOWABLE_COLUMNS_SUCCESS:
      setLocalStorageValue("shownColumnsInUMS", JSON.stringify(payload.showables.columns));
      return {
        ...state,
        showableColumns: payload.showables,
      };
    case UPDATE_USER_HOUR_RATE_SUCCESS:
      let uzrs_rat = state.userSettings;
      uzrs_rat.list.forEach((element) => {
        if (element.userId === payload.sltUsers) {
          element.hourRate = payload.usrRate;
        }
      })
      return {
        ...state,
        userSettings: uzrs_rat,
      };
    case SEARCH_USERS_FOR_USER_SETTINGS_SUCCESS:
      const searched = state.searchedUsers;
      const oldSelected = state.searchedSelectedUsers;
      const newSearched = payload.searchedUsers;
      const filterUserList = newSearched.filter(
        user => !oldSelected.some(selectedUser => selectedUser.id === user.id)
      );
    
      return {
        ...state,
        searchedUsers: filterUserList,
      };      

    case SEARCH_USERS_FOR_USER_SETTINGS_FAILED:
      return {
        ...state,
      };
    case CLEARE_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchedUsers: [],
      } 
    case CLEARE_SEARCH_SELECTED_USERS_SUCCESS:
      return {
        ...state,
        searchedSelectedUsers: [],
        searchedUsers: []
      }   
    case ADD_USER_TO_SETTINGS_SUCCESS:
      var selected = state.searchedUsers;
      var gotten = payload.addUser;
      const filteredAdded = selected.filter(user => user.id !== gotten.id);
      const selectedFilter = state.searchedSelectedUsers.concat(gotten);
      return {
        ...state,
        searchedUsers: filteredAdded,
        searchedSelectedUsers: selectedFilter
      }; 
    default:
      return state;
  }
}
