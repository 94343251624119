import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import CircularProgressBar from "../CircularProgressBar";
import "./style.css";
import { getRandomColor, noDataSimple } from "../../common/GlobalFunctions";
import CustomToolTip from "../CustomToolTip";
import Loading from "../loading";

const TopUsersUnProductive = (props) => {
  const { loading, dashboardTopUsersUnProductive, OnUserSelect, dashboardDateRange } = props;
  const navigate = useNavigate();

  const handleClickNavigate = () => {
    const params = new URLSearchParams({
      rating: "UnProductive",
      date: dashboardDateRange.dateRange,
      label: dashboardDateRange.label
    });

    navigate(`/activity-summary?${params.toString()}`);
  };

  const dummySkeletonFunction = () => {
    let dummySkeleton = [];

    for (let i = 0; i < 8; i++) {
      dummySkeleton.push(
        <Col className="mb-4 " xs={6} sm={6} md={4} lg={3} key={i}>
          <div className="frame">
            <div>
              <div className="headline"></div>
              <div className="circle-big red">
                <CircularProgressBar
                  value={50}
                  percentage={50}
                  pathColor="#E34444"
                  textColor="#E34444"
                />
              </div>
            </div>
            <div className="names d-flex justify-content-center">
              <span className="d-flex justify-content-center align-items-center u">
                C
              </span>
              <p className="m-0 ms-2">dummy</p>
            </div>
          </div>
        </Col>
      );
    }
    return dummySkeleton;
  };

  return (
    <>
      <Col
        className="box box-fix mt-sm-0 mb-md-0 mb-lg-2 mb-sm-2  mb-xl-0 mt-md-0 mt-lg-2 mt-xl-3"
        md={12}
        lg={12}
        xl={6}
      >
        <div
          className={
            "top-projects-box toltip-right my-0 my-md-4 mt-0 mt-md-2 mt-lg-2 mb-0 topusers mb-md-3 mb-xl-0 box-shadow-light hght overflow-inherit" +
            (loading ? " skeleton1 " : "")
          }
        >
          <div className="bg-white p-0 py-3 px-4 border-radius">
            {loading ? (
              <div className="text-center loader my-4 pt-1 height-min">
                <Loading />
              </div>
            ) : (
              <>
                <div className="heading">
                  <h3 className="heading heading-font d-flex align-items-center">
                    Least Productive Users
                    <CustomToolTip
                      iconColor="black"
                      details="Users with the lowest productivity, based on time spent in apps and websites rated unproductive."
                    />
                  </h3>
                  {/* <p className='text-gray fs-14 font-regular'>Highest % time on un-productive websites and apps</p> */}
                </div>
                <Row className="my-3 mb-0 pb-4 pb-sm-0">
                  {!loading && dashboardTopUsersUnProductive.length > 0
                    ? dashboardTopUsersUnProductive.map((s, index) => (
                      <Col
                        className="mb-4 mb-0 mb-lg-0"
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        key={index}
                      >
                        <div className="frame">
                          <div>
                            <div className="headline"></div>
                            <div className="circle-big red">
                              <CircularProgressBar
                                value={s.percentage}
                                percentage={s.percentage}
                                pathColor="#ff7979"
                                textColor="#ff7979"
                              />
                            </div>
                          </div>
                          <div className="names d-flex justify-content-center">
                            <span
                              style={{
                                backgroundColor: getRandomColor(s.name),
                              }}
                              className="d-flex justify-content-center align-items-center p"
                            >
                              {s.name.charAt(0)}
                            </span>
                            <div className="hover-label">
                              <Link
                                to="/user-dashboard"
                                className="m-0 ms-2"
                                onClick={() => {
                                  OnUserSelect({ id: s.id, name: s.name });
                                }}
                              >
                                {s.name}
                              </Link>
                              {/* <p className='name-show'>{OnUserSelect(s.id)}{s.name}</p> */}
                              <p className="name-show">{s.name}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                    : noDataSimple()}
                </Row>
                <p
                  className="more-btn"
                  onClick={handleClickNavigate}
                >
                  more
                </p>
              </>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};
export default TopUsersUnProductive;
