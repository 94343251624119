import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL_PHP } from "../utils/Constants";

const fetchClientSettings = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/org-clients", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};



const updateClientSettings = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/update-client-settings", requestData, { headers: authHeader() })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const updateClientStatus = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/organization/update-user-status", requestData, { headers: authHeader() })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};


const fetchClientsTeam = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/org-users", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};


const searchUsers = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user/allusersearch", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const AddRemoveUser = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/groups/add-remove-client-members-to-group", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const UpdateNowUserHourRate = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/update-client-user-hour-rate", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};
const getClientToken = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user/view-account", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchUsersGroups = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user/usergroupsearch", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};


export default {
    fetchClientSettings,
    updateClientSettings,
    updateClientStatus,
    fetchClientsTeam,
    searchUsers,
    AddRemoveUser,
    UpdateNowUserHourRate,
    getClientToken,
    fetchUsersGroups
};