import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL_PHP } from "../utils/Constants";

const fetchMacLink = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/timebug-app-version", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error: ", error);
        })
};
const fetchWindowsLink = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/timebug-app-version", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error: ", error);
        })
};
const fetchReactVersion = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/timebug-app-version", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error: ", error);
        })
};

//dashboard, mac , windows

export default {
    fetchReactVersion,
    fetchWindowsLink,
    fetchMacLink,
};