import { subDays } from "date-fns";
import {
    // FETCH_SCREENSHOT_USERS_DROPDOWN_SUCCESS,
    // FETCH_SCREENSHOT_USERS_DROPDOWN_FAILED,
    FETCH_SCREENSHOT_SUCCESS,
    FETCH_SCREENSHOT_FAILED,
    FETCH_MORE_SCREENSHOT_SUCCESS,
    FETCH_MORE_SCREENSHOT_FAILED,
    SCREENSHOT_USER_SELECTION_UPDATE,
    SCREENSHOT_USER_SELECTION_SUCCESS,
    SCREENSHOT_DATE_SELECTION_SUCCESS,
    FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_SUCCESS,
    FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_FAILED,
    SCREENSHOT_USER_ADDED_SUCCESS,
    SCREENSHOT_USER_REMOVE_SUCCESS,
    SCREENSHOT_USERS_CLEAR_SUCCESS,
    SCREENSHOT_GROUPS_CLEAR_SUCCESS,
    SCREENSHOT_GROUP_SELECTION_UPDATE,
    SCREENSHOT_ARCHIVE_SUCCESS
} from "../actions/Types";
import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";

let selectedDateRange = JSON.parse(getLocalStorageValue("Screenshot_Date_Selection"));

let selectedUsers = JSON.parse(getLocalStorageValue("Screenshot_User_Selection"));
let selectedGroups = JSON.parse(getLocalStorageValue("Screenshot_Group_Selection"));
let listUsers = [];
let listGroups = [];

let initialState = {
    screenshotDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
    screenshots: { "screenshots": [] },
    screenshotUsersDropDown: { users: [], groups: [] },
    screenshotSelectedUsers: selectedUsers ? selectedUsers : [],
    screenshotSelectedGroups: selectedGroups ? selectedGroups : [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Screenshot_User_Selection"));
            selectedGroups = JSON.parse(getLocalStorageValue("Screenshot_Group_Selection"));

            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false;
            });

            listUsers = payload.listUsersDropDown.users;
            const filteredAddMembers = selectedUsers !== null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users;

            listGroups = payload.listUsersDropDown.groups;
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false;
                if (selectedGroups) {
                    selectedGroups.forEach((selectedGroup) => {
                        if (selectedGroup.id === group.id) group.checked = true;
                    })
                }
            });

            return {
                ...state,
                screenshotUsersDropDown: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            };

        case FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_FAILED:
            return {
                ...state,
                screenshotUsersDropDown: { users: [], groups: [] },
            };

        // tested
        case SCREENSHOT_GROUP_SELECTION_UPDATE:

            let newUserListState_0 = state.screenshotUsersDropDown
            newUserListState_0.groups.forEach((group, index) => {
                if (payload.changedGroup.id === group.id) {
                    group.checked = payload.changedGroup.checked;
                }else{
                    group.checked = false
                }
            });
            setLocalStorageValue("Screenshot_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)));
            return {
                ...state,
                screenshotUsersDropDown: { users: newUserListState_0.users, groups: newUserListState_0.groups },
                screenshotSelectedGroups: newUserListState_0.groups.filter(obj => obj.checked === true)
            };

        // tested
        case SCREENSHOT_USER_ADDED_SUCCESS:
            let users = state.screenshotSelectedUsers;
            users = users.concat(payload.userAdded);
            setLocalStorageValue("Screenshot_User_Selection", JSON.stringify(users));
            let searched = state.screenshotUsersDropDown.users;
            var filter = searched.filter(a => !(a === payload.userAdded));
            return {
                ...state,
                screenshotSelectedUsers: users,
                screenshotUsersDropDown: { users: filter, groups: state.screenshotUsersDropDown.groups }
            };

        // tested
        case SCREENSHOT_USER_REMOVE_SUCCESS:
            let uzers = state.screenshotUsersDropDown.users;
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false;
                uzers.push(payload.userRemoved);
                uzers.sort((a, b) => a.name.localeCompare(b.name));
            }

            let arr = state.screenshotSelectedUsers;
            var filter1 = arr.filter(a => !(a === payload.userRemoved));
            setLocalStorageValue("Screenshot_User_Selection", JSON.stringify(filter1));
            return {
                ...state,
                screenshotSelectedUsers: filter1,
                screenshotUsersDropDown: { users: uzers, groups: state.screenshotUsersDropDown.groups }
            };

        // tested
        case SCREENSHOT_USERS_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false;
            });

            setLocalStorageValue("Screenshot_User_Selection", JSON.stringify(null));
            return {
                ...state,
                screenshotSelectedUsers: [],
                screenshotUsersDropDown: { users: listUsers, groups: state.screenshotUsersDropDown.groups }
            };

        // tested
        case SCREENSHOT_GROUPS_CLEAR_SUCCESS:
            // console.log("Reducer, listGroups: ", listGroups);
            listGroups.forEach((group) => {
                group['checked'] = false;
            });

            setLocalStorageValue("Screenshot_Group_Selection", JSON.stringify(null));
            return {
                ...state,
                screenshotUsersDropDown: { users: state.screenshotUsersDropDown.users, groups: listGroups }
            };
        // end users dropdown


        case FETCH_SCREENSHOT_SUCCESS:
            return {
                ...state,
                screenshots: { "screenshots": payload.screenshots.screenshots, "loadMore": payload.loadMore }
            };

        case FETCH_SCREENSHOT_FAILED:
            return {
                ...state,
                screenshots: { "screenshots": [], "loadMore": false },
            };

        case FETCH_MORE_SCREENSHOT_SUCCESS:
            let ss = state.screenshots;
            let con = ss.screenshots.concat(payload.screenshots.screenshots);
            return {
                ...state,
                screenshots: { "screenshots": con, loadMore: payload.loadMore },
            };

        case FETCH_MORE_SCREENSHOT_FAILED:
            return {
                ...state,
                screenshots: { "screenshots": [], loadMore: false },
            };

        case SCREENSHOT_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Screenshot_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                screenshotDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };

        // tested
        case SCREENSHOT_USER_SELECTION_SUCCESS:
            setLocalStorageValue("Screenshot_User_Selection", JSON.stringify(payload.selectedUsers));
            return {
                ...state,
                screenshotSelectedUsers: payload.selectedUsers
            };

        case SCREENSHOT_USER_SELECTION_UPDATE:
            let newUserListState = state.screenshotUsersDropDown
            newUserListState.users.forEach((user, index) => {
                if (payload.changedUser.id === user.id) {
                    user.checked = payload.changedUser.checked;
                }
            });
            return {
                ...state,
                screenshotUsersDropDown: { users: newUserListState.users, groups: [] }
            };

        case SCREENSHOT_ARCHIVE_SUCCESS:
            let df = state.screenshots;
            let ndt = payload.ssRemove;
            let cnst_arr = df.screenshots;
            let res = cnst_arr.filter(item => !ndt.includes(item.id));
            return {
                ...state,
                screenshots: { "screenshots": res, loadMore: df.loadMore },
            };



        default:
            return state;
    };
}
