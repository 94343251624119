import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Header from '../../components/Header'
import LeftNav from '../../components/LeftNav'
// import AllUser from '../../components/AllUser'
import DateArrangePicker from '../../components/DateArrangePicker'
import TimeTracked from '../../components/TimeTracked';
import TopProjects from '../../components/TopProjects'
import TopUsersProductive from '../../components/TopUsersProductive'
import TopUsersUnProductive from '../../components/TopUsersUnProductive'
import TrackingMostHours from '../../components/TrackingMostHours'
import TrackingLeastHours from '../../components/TrackingLeastHours'
import ProductiveWebsitesApps from '../../components/ProductiveWebsitesApps'
import UnProductiveWebsitesApps from '../../components/UnProductiveWebsitesApps'
import { useDispatch, connect } from "react-redux";
////////////////
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
///////////////
import {
  DASHBOARD_DATE_SELECTION_SUCCESS,
  // DASHBOARD_USER_SELECTION_SUCCESS,
  TEAMDASHBOARD_USERS_CLEAR_SUCCESS,
  TEAMDASHBOARD_USER_ADDED_SUCCESS,
  TEAMDASHBOARD_USER_REMOVE_SUCCESS,
  USER_SELECTED_FROM_TEAM_DASHBOARD,
  DASHBOARD_GROUP_SELECTION_UPDATE,
  TEAMDASHBOARD_GROUPS_CLEAR_SUCCESS
} from "../../redux/actions/Types";

import {
  fetchDashboardComulativeTime,
  fetchDashboardWebsitesAppsProductive,
  fetchDashboardWebsitesAppsUnProductive,
  fetchDashboardTrackingMostHours,
  fetchDashboardTrackingLeastHours,
  fetchDashboardTopUsersProductive,
  fetchDashboardTopUsersUnProductive,
  fetchDashboardTopProjects,
  // fetchDashboardUsersDropDown,
  fetchSearchableUsersGroups,
}
  from '../../redux/actions/DashboardAction';

import './style.css';
import SearchableUsersGroups from '../../components/SearchableUsersGroups'
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager'


const TeamDashboard = (props) => {
  const { selectedCompany, dashboardComulativeTime,
    dashboardTopUsedWebsitesAppsProductive,
    dashboardTopUsedWebsitesAppsUnProductive,
    dashboardTrackingMostHours,
    dashboardTrackingLeastHours,
    dashboardTopUsersProductive,
    dashboardTopUsersUnProductive,
    dashboardUsersDropDown,
    dashboardTopProjects,
    dashboardDateRange,
    dashboardSelectedUsers,
    leftNavExpand
    // dashboardSelectedGroups
  } = props;

  const dateRanges = [
    {
      label: 'Today',
      closeOverlay: false,
      value: [new Date(), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Yesterday',
      closeOverlay: false,
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'This week',
      closeOverlay: false,
      value: [startOfWeek(new Date()), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last 7 days',
      closeOverlay: false,
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last 30 days',
      closeOverlay: false,
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'This month',
      closeOverlay: false,
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last month',
      closeOverlay: false,
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: "Last six months",
      closeOverlay: false,
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
  var days = 180;

  const dispatch = useDispatch();
  const [comulativeTimeLoading, setComulativeTimeLoading] = useState(false);
  const [topUsedWebSitesAppsProductiveLoading, setTopUsedWebSitesAppsProductiveLoading] = useState(false);
  const [topUsedWebSitesAppsUnProductiveLoading, setTopUsedWebSitesAppsUnProductiveLoading] = useState(false);
  const [trackingMostHoursLoading, setTrackingMostHoursLoading] = useState(false);
  const [trackingLeastHoursLoading, setTrackingLeastHoursLoading] = useState(false);
  const [dashboardTopUsersProductiveLoading, setDashboardTopUsersProductiveLoading] = useState(false);
  const [dashboardTopUsersUnProductiveLoading, setDashboardTopUsersUnProductiveLoading] = useState(false);
  const [dashboardUsersDropDownLoading, setDashboardUsersDropDownLoading] = useState(false);
  const [dashboardTopProjectsLoading, setDashboardTopProjectsLoading] = useState(false);
  const [basedOn, setBasedOn] = useState(getLocalStorageValue("Team_Dashboard_Selected_Tab") || "user");
  // const [skip, setSkip] = useState(0);
  const [trackZeroHrs, setTrackZeroHrs] = useState(0)
  const [isSelectedUr, setIsSelectedUr] = useState('TimeTracked User');
  // var limit = 20;
  // const [selectedGroupsArray, setSelectedGroupsArray] = useState((dashboardUsersDropDown.groups.filter(obj => obj.checked === true)).map(i => i.id));
  const check_available_grp = JSON.parse(getLocalStorageValue("Dashboard_Group_Selection"));

  const [selectedGroupsArray, setSelectedGroupsArray] = useState(check_available_grp !== null ? check_available_grp.map(i => i.id) : '');
  const [selectedUsersArray, setSelectedUsersArray] = useState(dashboardSelectedUsers.map(i => i.id));

  const [ratingScope, setRatingScope] = useState(basedOn === 'group' ? basedOn : 'org');
  const [ratingScopeId, setRatingScopeId] = useState(selectedGroupsArray.toString());

  // console.log('ratingScope', ratingScope)
  // console.log('ratingScopeId', ratingScopeId)
  // console.log('basedOn', basedOn)
  // console.log('Dashboard_Group_Selection', JSON.parse(getLocalStorageValue("Dashboard_Group_Selection")))
  useEffect(() => {
    getDashboardComulativeTime(basedOn);
    getDashboardTopProjects(basedOn);

    getDashboardWebsitesAppsProductive(basedOn);
    getDashboardWebsitesAppsUnProductive(basedOn);

    getDashboardTrackingMostHours(basedOn);
    getDashboardTrackingLeastHours(basedOn);

    getDashboardTopUsersProductive(basedOn);
    getDashboardTopUsersUnProductive(basedOn);

  }, [dashboardDateRange, selectedGroupsArray, selectedUsersArray, basedOn])

  useEffect(() => {
    getUsersDropDown("", 0, 20)
  }, [])


  function getDashboardComulativeTime(_basedOn) {
    setComulativeTimeLoading(true);
    dispatch(fetchDashboardComulativeTime(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn, ratingScope, ratingScopeId))
      .then(() => {
        setComulativeTimeLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setComulativeTimeLoading(false);
      });
  }

  function getDashboardWebsitesAppsProductive(_basedOn) {
    setTopUsedWebSitesAppsProductiveLoading(true);
    dispatch(fetchDashboardWebsitesAppsProductive(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn, ratingScope, ratingScopeId))
      .then(() => {
        setTopUsedWebSitesAppsProductiveLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setTopUsedWebSitesAppsProductiveLoading(false);
      });
  }

  function getDashboardWebsitesAppsUnProductive(_basedOn) {
    setTopUsedWebSitesAppsUnProductiveLoading(true);
    dispatch(fetchDashboardWebsitesAppsUnProductive(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn, ratingScope, ratingScopeId))
      .then(() => {
        setTopUsedWebSitesAppsUnProductiveLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setTopUsedWebSitesAppsUnProductiveLoading(false);
      });
  }

  function getDashboardTrackingMostHours(_basedOn) {
    setTrackingMostHoursLoading(true);
    dispatch(fetchDashboardTrackingMostHours(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn))
      .then(() => {
        setTrackingMostHoursLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setTrackingMostHoursLoading(false);
      });
  }

  function getDashboardTrackingLeastHours(_basedOn) {
    setTrackingLeastHoursLoading(true);
    dispatch(fetchDashboardTrackingLeastHours(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn, trackZeroHrs))
      .then(() => {
        setTrackingLeastHoursLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setTrackingLeastHoursLoading(false);
      });
  }

  function getDashboardTopUsersProductive(_basedOn) {
    setDashboardTopUsersProductiveLoading(true);
    dispatch(fetchDashboardTopUsersProductive(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn, ratingScope, ratingScopeId))
      .then(() => {
        setDashboardTopUsersProductiveLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setDashboardTopUsersProductiveLoading(false);
      });
  }

  function getDashboardTopUsersUnProductive(_basedOn) {
    setDashboardTopUsersUnProductiveLoading(true);
    //  console.log("asdf: ", selectedUsersArray);
    dispatch(fetchDashboardTopUsersUnProductive(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn, ratingScope, ratingScopeId))
      .then(() => {
        setDashboardTopUsersUnProductiveLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setDashboardTopUsersUnProductiveLoading(false);
      });
  }

  function getDashboardTopProjects(_basedOn) {
    setDashboardTopProjectsLoading(true);
    dispatch(fetchDashboardTopProjects(selectedCompany.id, dashboardDateRange, selectedUsersArray, selectedGroupsArray, _basedOn))
      .then(() => {
        setDashboardTopProjectsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setDashboardTopProjectsLoading(false);
      });
  }

  function onDateSelection(newDateRange) {
    dispatch({
      type: DASHBOARD_DATE_SELECTION_SUCCESS,
      payload: { selectedDate: newDateRange },
    });
  }

  function onGroupSelectionChange(changedGroup) {
    setRatingScopeId(changedGroup.id)
    dispatch({
      type: DASHBOARD_GROUP_SELECTION_UPDATE,
      payload: { changedGroup: changedGroup },
    });
  }

  function OnUserSelect(selectedUserId) {
    dispatch({
      type: USER_SELECTED_FROM_TEAM_DASHBOARD,
      payload: { selectedUserId: selectedUserId },
    });
    //console.log("User in Team Dash: ", selectedUserId);

  }

  // function getDashboardUsersDropDown() {
  //   setDashboardUsersDropDownLoading(true);
  //   dispatch(fetchDashboardUsersDropDown(selectedCompany.id))
  //     .then(() => {
  //       setDashboardUsersDropDownLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error: ", error);
  //       setDashboardUsersDropDownLoading(false);
  //     });
  // }

  // function onDropDownClose(usersArray) {
  //   // console.log("users array: ", usersArray);
  //   setSelectedUsersArray(usersArray);
  //   dispatch({
  //     type: DASHBOARD_USER_SELECTION_SUCCESS,
  //     payload: { selectedUsers: usersArray },
  //   });
  // }


  //////////////////////
  const onChangeUserSearchWord = (e) => {
    getUsersDropDown(e, 0, 20)
  }
  const onBlurHandle = (_basedOn) => {
    setLocalStorageValue("Team_Dashboard_Selected_Tab", _basedOn);
    setBasedOn(_basedOn);
    if (_basedOn === "user") {
      setSelectedUsersArray(dashboardSelectedUsers.map(i => i.id));
      setRatingScope('org');
    }

    if (_basedOn === "group") {
      setSelectedGroupsArray((dashboardUsersDropDown.groups.filter(obj => obj.checked === true)).map(i => i.id));
      setRatingScope('group');
    }
  }

  function getUsersDropDown(searchKeyword, skip, limit) {
    setDashboardUsersDropDownLoading(true);
    dispatch(fetchSearchableUsersGroups(selectedCompany.id, searchKeyword, skip, limit))
      .then(() => {
        setDashboardUsersDropDownLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setDashboardUsersDropDownLoading(false);
      });
  }
  const onSelectUser = (user) => {
    dispatch({
      type: TEAMDASHBOARD_USER_ADDED_SUCCESS,
      payload: { userAdded: user },
    });
  }
  const onRemoveUser = (user) => {
    dispatch({
      type: TEAMDASHBOARD_USER_REMOVE_SUCCESS,
      payload: { userRemoved: user },
    });
  }

  const onClearUsers = () => {
    dispatch({
      type: TEAMDASHBOARD_USERS_CLEAR_SUCCESS,
    });
    setSelectedUsersArray([]);
  }

  const onClearGroups = () => {
    dispatch({
      type: TEAMDASHBOARD_GROUPS_CLEAR_SUCCESS,
    });
    setSelectedGroupsArray([]);
  }


  useEffect(() => { }, [dashboardUsersDropDown])

  useEffect(() => {
    getDashboardTrackingLeastHours(basedOn);
  }, [trackZeroHrs])

  const zeroTrackedHrs = (value) => {
    if (value === "All User") {
      setTrackZeroHrs(1)
      setIsSelectedUr('All User')
    } else {
      setTrackZeroHrs(0)
      setIsSelectedUr('TimeTracked User')
    }
  }

  /////////////////////

  return (
    <>
      <Row className='m-0 inherit team-dashboard-pg'>
        <Col className='p-0 side-bar' sm={3}>
          <LeftNav />
        </Col>

        <Col className='bg-light-blue contant-area dashboard team-dashboard' sm={9}>
          <Header title="Team Dashboard" description="Team performance at a glance." fullUrl="https://timebee.app/documentation/dashboards/how-to-use-timebee-team-dashboard/?" contentUrl="https://timebee.app/documentation/dashboards/how-to-use-timebee-team-dashboard/?dashboard-guide-view=1" />
          <Row className='flex tab-responsive mt-4 mb-4'>
            {/* <AllUser loading={dashboardUsersDropDownLoading} usersDropDown={dashboardUsersDropDown} onBlurHandle={onDropDownClose} onSelectionChange={onUserSelectionChange} /> */}

            <SearchableUsersGroups loading={dashboardUsersDropDownLoading} onChangeSearchWord={onChangeUserSearchWord} searchedUsersGroups={dashboardUsersDropDown} selectedUsers={dashboardSelectedUsers} slectedTab={basedOn} onSelectUser={onSelectUser} onRemoveUser={onRemoveUser} onClearUsers={onClearUsers} onGroupSelectionChange={onGroupSelectionChange} onClearGroups={onClearGroups} onBlur={onBlurHandle} getUsersDropDown={getUsersDropDown} leftNavExpand={leftNavExpand} />

            <DateArrangePicker selectedDate={dashboardDateRange} dateRange={dateRanges} onSelectionOK={onDateSelection} maxAllowedDays={days} />
          </Row>
          <TimeTracked loading={comulativeTimeLoading} dashboardComulativeTime={dashboardComulativeTime} />
          <TopProjects loading={dashboardTopProjectsLoading} dashboardTopProjects={dashboardTopProjects} dashboardDateRange={dashboardDateRange} />
          <Row>
            <TopUsersProductive loading={dashboardTopUsersProductiveLoading} dashboardTopUsersProductive={dashboardTopUsersProductive} OnUserSelect={OnUserSelect} dashboardDateRange={dashboardDateRange} />
            <TopUsersUnProductive loading={dashboardTopUsersUnProductiveLoading} dashboardTopUsersUnProductive={dashboardTopUsersUnProductive} OnUserSelect={OnUserSelect} dashboardDateRange={dashboardDateRange} />
          </Row>
          <Row>
            <TrackingMostHours loading={trackingMostHoursLoading} dashboardTrackingMostHours={dashboardTrackingMostHours} dashboardDateRange={dashboardDateRange}  />
            <TrackingLeastHours loading={trackingLeastHoursLoading} dashboardTrackingLeastHours={dashboardTrackingLeastHours} isSelectedUr={isSelectedUr} zeroTrackedHrs={zeroTrackedHrs} dashboardDateRange={dashboardDateRange}/>
          </Row>
          <Row>
            <ProductiveWebsitesApps loading={topUsedWebSitesAppsProductiveLoading} dashboardTopUsedWebsitesAppsProductive={dashboardTopUsedWebsitesAppsProductive} dashboardDateRange={dashboardDateRange}/>
            <UnProductiveWebsitesApps loading={topUsedWebSitesAppsUnProductiveLoading} dashboardTopUsedWebsitesAppsUnProductive={dashboardTopUsedWebsitesAppsUnProductive} dashboardDateRange={dashboardDateRange}/>
          </Row>
        </Col>

      </Row>
    </>
  )
}
const mapStateToProps = state => ({
  dashboardComulativeTime: state.DashboardReducer.dashboardComulativeTime,
  dashboardTrackingMostHours: state.DashboardReducer.dashboardTrackingMostHours,
  dashboardTrackingLeastHours: state.DashboardReducer.dashboardTrackingLeastHours,
  dashboardTopUsedWebsitesAppsProductive: state.DashboardReducer.dashboardTopUsedWebsitesAppsProductive,
  dashboardTopUsedWebsitesAppsUnProductive: state.DashboardReducer.dashboardTopUsedWebsitesAppsUnProductive,
  dashboardTopUsersProductive: state.DashboardReducer.dashboardTopUsersProductive,
  dashboardTopUsersUnProductive: state.DashboardReducer.dashboardTopUsersUnProductive,
  dashboardUsersDropDown: state.DashboardReducer.dashboardUsersDropDown,
  dashboardTopProjects: state.DashboardReducer.dashboardTopProjects,
  dashboardDateRange: state.DashboardReducer.dashboardDateRange,
  selectedCompany: state.UserReducer.selectedCompany,
  dashboardSelectedUsers: state.DashboardReducer.dashboardSelectedUsers,
  leftNavExpand: state.SupportReducer.leftNavExpand,
  // dashboardSelectedGroups: state.DashboardReducer.dashboardSelectedGroups,
});

export default connect(mapStateToProps)(TeamDashboard);