import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import './style.css';

const GuideView = (props) => {
  const { show, handleClose, pageTitle, fullUrl, contentUrl } = props;

  useEffect(() => {
    // Apply CSS rules to the iframe content
    const iframe = document.querySelector('.iframe');
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    const styleTag = iframeDoc.createElement('style');
    styleTag.appendChild(document.createTextNode(
      `
        .dashboard header, 
        .dashboard footer, 
        .dashboard .small-blue-section-main, 
        .dashboard .bottom-links, 
        .dashboard .tabs { 
          display: none !important; 
        }
      `
    ));
    iframeDoc.head.appendChild(styleTag);
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="Profile-modal"
    >
      <Modal.Header closeButton className='d-flex guide-view justify-content-between'>
        <Modal.Title>
          <h2 className='modal-heading m-0'>User Guide for {pageTitle}</h2>
        </Modal.Title>
        <button className="btn btn-link" onClick={() => window.open(fullUrl, "_blank")}>
          <img src="images/dashboard-guide-view.svg" className='btn-img' alt="Open in new tab" style={{ width: '18px', height: '17px', opacity: "60%" }} />
        </button>
      </Modal.Header>

      <Modal.Body className='p-0'>
        <Modal.Body centered>
          {contentUrl ? (
            <iframe
              className='iframe'
              src={contentUrl}
              title="Middle Section"
              style={{ width: '100%', height: '80vh', border: 'none' }}
            ></iframe>
          ) : (
            <iframe
              className='iframe'
              src="https://health.wyo.gov/wp-content/uploads/2023/06/page-under-construction-icon.jpg"
              title="Default Image"
              style={{ width: '100%', height: '80vh', border: 'none' }}
            ></iframe>
          )}
        </Modal.Body>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  updatedProfile: state.UserReducer.updatedProfile,
});

export default connect(mapStateToProps)(GuideView);
