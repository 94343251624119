import React from 'react'
import './style.css';

const CustomToolTip = (props) => {
  const { iconColor, details, compClass } = props;
  return (

    <div className={"tooltip-container  me-2 " + (compClass !== null && compClass)} >
      {iconColor === "white" ? <img width={20} src={"images/info-white.svg"} />
        : <img width={20} src={"images/info.svg"} alt='' />}
      <div className="tooltip tooltip-ul">
        {details}
      </div>
    </div>

  )
}
export default CustomToolTip
