import {
    ADDPROJECT_MEMBER_ADDED_SUCCESS,
    ADDPROJECT_MEMBER_REMOVE_SUCCESS,
    ADDPROJECT_ADMIN_ADDED_SUCCESS,
    ADDPROJECT_ADMIN_REMOVE_SUCCESS,
    SET_ADD_PROJECT_SUCCESS,
    SET_ADD_PROJECT_FAILED,
    FETCH_PROJECT_LIST_SUCCESS,
    FETCH_PROJECT_LIST_FAILED,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_PROJECT_DETAILS_FAILED,
    FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS,
    FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED,
    FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS,
    FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED,
    MODIFYPROJECTS_MEMBER_ADDED_SUCCESS,
    MODIFYPROJECTS_MEMBER_REMOVE_SUCCESS,
    MODIFYPROJECTS_ADMIN_ADDED_SUCCESS,
    MODIFYPROJECTS_ADMIN_REMOVE_SUCCESS,
    FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS,
    FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED,
    FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS,
    FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED,
    SET_UPDATE_PROJECT_FAILED,
    SET_UPDATE_PROJECT_SUCCESS,
    MODIFYPROJECTS_ADMIN_POPULATED_SUCCESS,
    MODIFYPROJECTS_MEMBER_POPULATED_SUCCESS,
    SET_CLOSE_MODEL_SUCCESS,
    FETCH_MORE_PROJECT_LIST_SUCCESS,
    FETCH_MORE_PROJECT_LIST_FAILED,
    SET_PROJECT_SUCCESS,
    SET_PROJECT_AS_ARCHIVE,
    ADDPROJECT_MEMBER_ARRAY_RESET,
    ADDPROJECT_ADMIN_ARRAY_RESET
} from "../actions/Types";


let initialState = {
    addProjectSearchedMembers: [],
    addProjectSearchedAdmins: [],
    addProjectsSelectedMembers: [],
    addProjectsSelectedAdmins: [],
    clickedProject: { value: '', label: '', status: '', sysType: '', scope: '' },
    projectList: { projectList: [], loadMore: false },
    projectDetails: { projectID: "", users: [] },
    projectAdded: { message: "", status: 0, subCode: 0 },
    projectUpdated: { message: "", status: 0, subCode: 0 },

    modifyProjectSearchedMembers: [],
    modifyProjectSearchedAdmins: [],
    modifyProjectSelectedMembers: [],
    modifyProjectSelectedAdmins: [],
};


export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS:
            var selected = state.addProjectsSelectedMembers;
            var gotten = payload.addProjectSearchedMembers;
            const filteredAddMembers = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));

            return {
                ...state,
                addProjectSearchedMembers: filteredAddMembers,
            };
        case FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED:
            return {
                ...state,
                addProjectSearchedMembers: [],
            };
        case FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS:
            var selected = state.addProjectsSelectedAdmins;
            var gotten = payload.addProjectSearchedAdmins;
            const filterdAddAdmins = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));
            return {
                ...state,
                addProjectSearchedAdmins: filterdAddAdmins,
            };
        case FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED:
            return {
                ...state,
                addProjectSearchedAdmins: [],
            };

        case FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS:
            var selected = state.modifyProjectSelectedMembers;
            var gotten = payload.modifyProjectSearchedMembers;
            const filteredModfifyMembers = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));
            return {
                ...state,
                modifyProjectSearchedMembers: filteredModfifyMembers,
            };
        case FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED:
            return {
                ...state,
                modifyProjectSearchedMembers: { orgUsers: [] },
            };
        case FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS:
            var selected = state.modifyProjectSelectedAdmins;
            var gotten = payload.modifyProjectSearchedAdmins;
            const filteredModfifyAdmins = gotten.orgUsers.filter(item => !selected.some(a => a.id === item.id));
            return {
                ...state,
                modifyProjectSearchedAdmins: filteredModfifyAdmins,
            };
        case FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED:
            return {
                ...state,
                modifyProjectSearchedAdmins: [],
            };

        case ADDPROJECT_MEMBER_ADDED_SUCCESS:
            let memebers = state.addProjectsSelectedMembers;
            memebers = memebers.concat(payload.memberAdded);

            return {
                ...state,
                addProjectsSelectedMembers: memebers,
            };
        case ADDPROJECT_MEMBER_REMOVE_SUCCESS:
            let arr = state.addProjectsSelectedMembers;
            var filter = arr.filter(a => !(a === payload.memberRemoved));
            console.log(filter);
            return {
                ...state,
                addProjectsSelectedMembers: filter
            };
        case ADDPROJECT_ADMIN_ADDED_SUCCESS:
            let admins = state.addProjectsSelectedAdmins;
            admins = admins.concat(payload.adminAdded);

            return {
                ...state,
                addProjectsSelectedAdmins: admins,
            };
        case ADDPROJECT_ADMIN_REMOVE_SUCCESS:
            let arr1 = state.addProjectsSelectedAdmins;
            var filterapsa = arr1.filter(a => !(a === payload.adminRemoved));
            console.log(filterapsa);
            return {
                ...state,
                addProjectsSelectedAdmins: filterapsa
            };

        case FETCH_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projectList: {
                    projectList: payload.projectList.projectList
                    , loadMore: payload.loadMore
                },
            };
        case FETCH_PROJECT_LIST_FAILED:
            return {
                ...state,
                projectList: { projectList: [], loadMore: false },
            };

        case FETCH_MORE_PROJECT_LIST_SUCCESS:
            let list = state.projectList;
            let con = list.projectList.concat(payload.projectList.projectList);
            return {
                ...state,
                projectList: {
                    projectList: con
                    , loadMore: payload.loadMore
                },
            };
        case FETCH_MORE_PROJECT_LIST_FAILED:
            return {
                ...state,
                projectList: { projectList: [], loadMore: false },
            };

        case FETCH_PROJECT_DETAILS_SUCCESS:
            return {
                ...state,
                projectDetails: payload.projectDetails,
            };
        case FETCH_PROJECT_DETAILS_FAILED:
            return {
                ...state,
                projectDetails: { projectID: "", users: [] },
            };

        case SET_PROJECT_AS_ARCHIVE:
            let old_proj = state.projectList;
            var ndt = old_proj.projectList.filter(a => !(a.value === payload.projectId));
            return {
                ...state,
                projectList: {
                    projectList: ndt, loadMore: old_proj.loadMore
                },
            };  

        case SET_ADD_PROJECT_SUCCESS:
            return {
                ...state,
                projectAdded: payload.projectAdded,
            };
        case SET_ADD_PROJECT_FAILED:
            return {
                ...state,
                projectAdded: { message: "", status: 0, subCode: 0 },
            };


        case MODIFYPROJECTS_MEMBER_POPULATED_SUCCESS:
            return {
                ...state,
                modifyProjectSelectedMembers: payload.memberAdded,
            };
        case MODIFYPROJECTS_ADMIN_POPULATED_SUCCESS:
            return {
                ...state,
                modifyProjectSelectedAdmins: payload.adminAdded,
            };

        case MODIFYPROJECTS_MEMBER_ADDED_SUCCESS:
            let modify_memebers = state.modifyProjectSelectedMembers;
            modify_memebers = modify_memebers.concat(payload.memberAdded);

            return {
                ...state,
                modifyProjectSelectedMembers: modify_memebers,
            };
        case MODIFYPROJECTS_MEMBER_REMOVE_SUCCESS:
            let modifyprojectSelecteMembers = state.modifyProjectSelectedMembers;
            var filtermpsm = modifyprojectSelecteMembers.filter(a => !(a === payload.memberRemoved));
            return {
                ...state,
                modifyProjectSelectedMembers: filtermpsm
            };

        case MODIFYPROJECTS_ADMIN_ADDED_SUCCESS:
            let modifySelectedAdmins = state.modifyProjectSelectedAdmins;
            modifySelectedAdmins = modifySelectedAdmins.concat(payload.adminAdded);

            return {
                ...state,
                modifyProjectSelectedAdmins: modifySelectedAdmins,
            };
        case MODIFYPROJECTS_ADMIN_REMOVE_SUCCESS:
            let mpsa = state.modifyProjectSelectedAdmins;
            var filtermps = mpsa.filter(a => !(a === payload.adminRemoved));
            console.log(filtermps);
            return {
                ...state,
                modifyProjectSelectedAdmins: filtermps
            };

        case SET_UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                projectUpdated: payload.projectUpdated,
            };
        case SET_UPDATE_PROJECT_FAILED:
            return {
                ...state,
                projectUpdated: { message: "", status: 0, subCode: 0 },
            };

        case SET_CLOSE_MODEL_SUCCESS:
            return {
                ...state,

                addProjectSearchedMembers: [],
                addProjectSearchedAdmins: [],
                addProjectsSelectedMembers: [],
                addProjectsSelectedAdmins: [],

                modifyProjectSearchedMembers: [],
                modifyProjectSearchedAdmins: [],
                modifyProjectSelectedMembers: [],
                modifyProjectSelectedAdmins: [],
            };

        case SET_PROJECT_SUCCESS:
            return {
                ...state,
                clickedProject: payload.project,
            }
        
        case ADDPROJECT_MEMBER_ARRAY_RESET:
            return {
                ...state,
                addProjectsSelectedMembers: []
            };

        case ADDPROJECT_ADMIN_ARRAY_RESET:
            return {
                ...state,
                addProjectsSelectedAdmins: []
            };

        default:
            return state;
    }
}
