import { dateForComparison, yyyymmdd } from './GlobalFunctions';

let initialTimelineJSON = {
    "userId": "",
    "userName": "",
    "endDate": "",
    "timeline": []
}

let initialHoursTrackedJSON = {
    "userId": "",
    "userName": "",
    "endDate": "",
    "timeline": []
}

let initialHoursTrackedJSONP = {
    "userId": "",
    "userName": "",
    "endDate": "",
    "timeline": []
}

let initialActivityPercentageJSON = {
    "userId": "",
    "userName": "",
    "endDate": "",
    "activityTrack": []
}

export const timelineRespGenrator = (respJSON) => {
    let timelineJSON = initialTimelineJSON
    timelineJSON.userId = respJSON.userId;
    timelineJSON.userName = respJSON.userName;
    timelineJSON.endDate = respJSON.endDate;
    let arr = []
    {
        for (let i = 0; i < 7; i++) {
            let object = {
                "date": "",
                "startTime": "--:--",
                "endTime": "--:--",
                "totalTimeTracked": 0,
                "totalTimeTrackedDB": 0,
                "activity": []
            }
            arr.push(object)
        }
    }
    timelineJSON.timeline = arr;

    for (let i = 0; i < 7; i++) {

        var date = new Date(dateForComparison(respJSON.endDate));
        date.setDate(date.getDate() - i);
        timelineJSON.timeline[i].date = dateForComparison(date);
    }
    timelineJSON.timeline.forEach((a) => {
        respJSON.timeline.forEach((tl) => {
            if (a.date === tl.date) {
                a.date = tl.date;
                a.startTime = tl.startTime;
                a.endTime = tl.endTime;
                a.totalTimeTracked = tl.totalTimeTracked;
                a.totalTimeTrackedDB = tl.totalTimeTrackedDB;
                a.activity = tl.activity;
            }
        })
    })
    return timelineJSON;
}

export const hoursTrackedRespGenratorProductive = (respJSON) => {

    let hoursTracked = initialHoursTrackedJSON;
    hoursTracked.userId = respJSON.userId;
    hoursTracked.userName = respJSON.userName;
    hoursTracked.endDate = respJSON.endDate;

    let arr = []
    {
        for (let i = 0; i < 30; i++) {
            let object = {
                "date": "",
                "totalTimeTracked": 0,
            }
            arr.push(object)
        }
    }
    // hoursTracked.timeline = arr;
    for (let i = 0; i < 30; i++) {
        var date = new Date(dateForComparison(respJSON.endDate));
        date.setDate(date.getDate() - i);
        arr[i].date = dateForComparison(date);
    }
    arr.forEach((a) => {
        respJSON.timeline.forEach((ht) => {
            if (a.date === ht.Date) {
                a.date = ht.Date;
                a.totalTimeTracked = ht.totalTimeTracked;
            }
        })
    })

    const strAscending = [...arr].sort((a, b) => a.date > b.date ? 1 : -1);
    hoursTracked.timeline = strAscending;
    return hoursTracked;
}

export const hoursTrackedRespGenratorUnProductive = (respJSON) => {

    let hoursTracked = initialHoursTrackedJSONP;
    hoursTracked.userId = respJSON.userId;
    hoursTracked.userName = respJSON.userName;
    hoursTracked.endDate = respJSON.endDate;

    let arr = []
    {
        for (let i = 0; i < 30; i++) {
            let object = {
                "date": "",
                "totalTimeTracked": 0,
            }
            arr.push(object)
        }
    }
    // hoursTracked.timeline = arr;
    for (let i = 0; i < 30; i++) {
        var date = new Date(dateForComparison(respJSON.endDate));
        date.setDate(date.getDate() - i);
        arr[i].date = dateForComparison(date);
    }
    arr.forEach((a) => {
        respJSON.timeline.forEach((ht) => {
            if (a.date === ht.Date) {
                a.date = ht.Date;
                a.totalTimeTracked = ht.totalTimeTracked;
            }
        })
    })

    const strAscending = [...arr].sort((a, b) => a.date > b.date ? 1 : -1);
    hoursTracked.timeline = strAscending;
    return hoursTracked;
}

export const activityPercentageRespGenrator = (respJSON) => {
    let activityPercentage = initialActivityPercentageJSON;
    activityPercentage.userId = respJSON.userId;
    activityPercentage.userName = respJSON.userName;
    activityPercentage.endDate = respJSON.endDate;

    let arr = []
    {
        for (let i = 0; i < 30; i++) {
            let object = {
                "date": "",
                "totalTimeTracked": 0,
                "Productive": 0,
                "UnProductive": 0,
                "Neutral": 0,
                "Unrated": 0,
            }
            arr.push(object)
        }
    }
    //activityPercentage.activityTrack = arr;

    for (let i = 0; i < 30; i++) {
        var date = new Date(dateForComparison(respJSON.endDate));
        date.setDate(date.getDate() - i);
        arr[i].date = dateForComparison(date);
    }
    arr.forEach((a) => {
        respJSON.activityTrack.forEach((ap) => {
            if (a.date === ap.Date) {
                a.date = ap.Date;
                a.totalTimeTracked = ap.totalTimeTracked;
                if (ap.usage.Neutral) {
                    a.Neutral = ap.usage.Neutral;
                }
                if (ap.usage.Unrated) {
                    a.Unrated = ap.usage.Unrated;
                }
                if (ap.usage.UnProductive) {
                    a.UnProductive = ap.usage.UnProductive;
                }
                if (ap.usage.Productive) {
                    a.Productive = ap.usage.Productive;
                }
            }
        })
    })
    const strAscending = [...arr].sort((a, b) => a.date > b.date ? 1 : -1);
    activityPercentage.activityTrack = strAscending;
    return activityPercentage;
}

