import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { HourOrMinOrSec, noDataGraph, timeFormat } from '../../../common/GlobalFunctions'

const ProjectChart = (props) => {

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label" style={{ color: '#fff' }}>{`${label}`}</p>
          <div>
            {payload.map((i, index) => (
              <div style={{ display: "inline-block", padding: 5 }} key={index}>
                <div style={{ color: '#000000' }}>{timeFormat(i.value)}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y + 5} fill="#666" textAnchor="middle" dy={-6}>{`value: ${timeFormat(value)}`}</text>;
  };

  return (
    <div >
      <ResponsiveContainer width="100%" aspect={2.1}>

        {props.dataArray.length > 0 ? <BarChart width={950} height={300} data={props.dataArray}
          margin={{ top: 15, right: 5, left: 5, bottom: 5 }}>
          <XAxis dataKey={props.nameKey} />
          <YAxis tickFormatter={(label) => `${HourOrMinOrSec(label)}`} />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: ' #cce5ff90' }} itemStyle={{ color: '#C0C0C0' }} />
          <Bar barSize={25} dataKey={props.key1} fill="#939ee0" />
        </BarChart> :
          noDataGraph()
        }


        {/* {props.dataArray.length > 0 ? <BarChart width={1060} height={250} data={data} >
        <XAxis />
        <YAxis tickFormatter={(label) => `${timeFormat(label)}`} />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: '#ffffff00' }} itemStyle={{ color: '#C0C0C0' }} />
        <Bar barSize={25} dataKey="pv" fill="#28b09d" />
        <Bar barSize={25} dataKey="uv" fill="#FF6A6A" />
      </BarChart> : <h6 className='text-center my-2'>{NoDataToDisplay}</h6>} */}

      </ResponsiveContainer>
    </div>
  )
}
export default ProjectChart