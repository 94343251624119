import React, { useState, useEffect } from 'react'
import { ProgressBar, Form, Dropdown } from 'react-bootstrap';
import { catalogNameFormater, timeFormatInHMorMS, webOrApp } from '../../common/GlobalFunctions';
import './style.css';
import { NoDataToDisplay } from '../../utils/Constants';
import Loading from '../loading';
import CatalogTitleDetails from '../CatalogTitleDetails';

//user dashboard

const UserDashboardSingleUserTimeTrackDetails = (props) => {
  const { userWebAppUsage, dateRange, user, loading, moreLoading, onLoadMore, OrderByTimeTracked, onChangeRatingType } = props

  const percentage = (obtain) => {
    return Math.round(totalTime <= 0 ? 0 : (obtain * 100) / totalTime);
  };
  const totalTime = userWebAppUsage.catalogUsage.reduce((a, object) => {
    return a + object.totalActiveTime;
  }, 0);

  const [productiveCheck, setProductiveCheck] = useState("")
  const [unProductiveCheck, setUnproductiveCheck] = useState("")
  const [neutralCheck, setNeutralCheck] = useState("")
  const [unratedCheck, setUnratedCheck] = useState("")
  const [allCheck, setAllCheck] = useState(" bold")


  const [showCatalogDetailsModel, setShowCatalogDetailsModel] = useState(false)

  const [selectedCatalog, setSelectedCatalog] = useState({ "id": "", "name": "", "activeTime": "", "ratingType": "" })
  const openCatalogDetails = (catId, catName, activeTime, rateType) => {
    setSelectedCatalog({ "id": catId, "name": catName, "activeTime": activeTime, "ratingType": rateType })

    setShowCatalogDetailsModel(true)
  }
  const closeCatalogDetails = () => {
    setShowCatalogDetailsModel(false);
  }


  return (
    <div className="user-dashboard-single-tt">
      <div className='overview-box box-shadow-light timelines-table bg-white'>

        <Form className="timelines-form">
          <h5 className="pt-3 ps-4 p-0 bg-white heading font-bold-500">Websites & Apps Usage </h5>
          <div className='computer-time user-dashboard-single bg-white d-flex justify-content-center py-4'>

            <Form.Check className={"All-checkbox" + allCheck}
              inline
              label="All"
              name="group1"
              type="radio"
              id="1"
              onClick={() => {
                setProductiveCheck("");
                setUnproductiveCheck("");
                setNeutralCheck("");
                setUnratedCheck("");
                setAllCheck(" bold");
                onChangeRatingType('')
              }}
            />
            <Form.Check className={"Productive-checkbox" + productiveCheck}
              inline
              label="Productive Time"
              name="group1"
              type="radio"
              id="2"
              onClick={() => {
                setProductiveCheck(" bold");
                setUnproductiveCheck("");
                setNeutralCheck("");
                setUnratedCheck("");
                setAllCheck("");
                onChangeRatingType('Productive')
              }}
            />
            <Form.Check className={"Unproductive-checkbox" + unProductiveCheck}
              inline
              label="Unproductive Time"
              name="group1"
              type="radio"
              id="3"
              onClick={() => {
                setProductiveCheck("");
                setUnproductiveCheck(" bold");
                setNeutralCheck("");
                setUnratedCheck("");
                setAllCheck("");
                onChangeRatingType('UnProductive')
              }}
            />
            <Form.Check className={"Neutral-checkbox" + neutralCheck}
              inline
              label="Neutral"
              name="group1"
              type="radio"
              id="4"
              onClick={() => {
                setProductiveCheck("");
                setUnproductiveCheck("");
                setNeutralCheck(" bold");
                setUnratedCheck("");
                setAllCheck("");
                onChangeRatingType('Neutral')
              }}
            />
            <Form.Check className={"Unrated-checkbox" + unratedCheck}
              inline
              label="Unrated"
              name="group1"
              type="radio"
              id="5"
              onClick={() => {
                setProductiveCheck("");
                setUnproductiveCheck("");
                setNeutralCheck("");
                setUnratedCheck(" bold");
                setAllCheck("");
                onChangeRatingType('Unrated')
              }}
            />
          </div>
        </Form>

        <div className='websites-box scroll table-paragraph user-table'>
          <div className='header-box d-flex align-items-center bg-show'>
            <div className='website px-2 px-sm-3 d-flex align-items-center'>
              <h3 className='text-black table-heading'>Websites & Apps</h3>
            </div>
            <div className='times px-2 px-sm-3 left'>
              <Dropdown onClick={() => { OrderByTimeTracked() }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <h3 className='table-heading'>Times</h3>
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          </div>

          {loading ?
            <div className="text-center my-5 loader">
              <Loading />
            </div> :
            <>
              <div className='scroll-websites scroll-height user-dashboard-web-app'>

                {userWebAppUsage.catalogUsage.map((s, index) => (

                  <div className='header-box d-flex align-items-center' key={index}>
                    <div className='website  px-2 px-sm-3 d-flex align-items-center'>
                      <img src={webOrApp(s.catalogName) === "web" ? "images/browser-icon.svg" : "images/appsicon.svg"} alt="" />
                      <p className='text-black ms-3 cursor-pointer' onClick={() => { openCatalogDetails(s.catalogId, s.catalogName, s.totalActiveTime, s.ratingType); }}>{catalogNameFormater(s.catalogName)}</p>
                    </div>
                    <div className='times px-2 px-sm-3 pl-30 d-flex align-items-center '>
                      <p className='text-black me-3 me-xl-5'>{timeFormatInHMorMS(s.totalActiveTime)}</p>
                      <div className='progress-box ms-2 w-100 d-flex'>
                        <p className='d-flex percentage'>{percentage(s.totalActiveTime) > 1 ? percentage(s.totalActiveTime) : "< 1"} %</p>
                        <ProgressBar className={s.ratingType} variant="success" now={percentage(s.totalActiveTime)} />

                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {userWebAppUsage.catalogUsage.length <= 0 &&
                <div className='no-data'>
                  <img src={"images/NoData.svg"} />
                  <p> {NoDataToDisplay}</p>
                </div>}
            </>
          }
        </div>
        {(() => {
          if (loading) {
            <></>
          }
          else if (moreLoading) {
            return (
              <div className="text-center loader-blue my-4">
                <Loading />
              </div>
            )
          }
          else if (userWebAppUsage.loadMore) {
            return (
              <>

                <div className='loader-blue d-flex justify-content-center align-items-center my-4' onClick={onLoadMore}>
                  <span className="loading">Show More</span>
                  {/* <img src={"images/loader-icon.svg"} alt="" /> */}
                </div>
              </>
            )
          }
          else {
            return (
              <></>
            )
          }
        })()}
      </div>
      {showCatalogDetailsModel && <CatalogTitleDetails show={showCatalogDetailsModel} user={user} handleClose={closeCatalogDetails} dateRange={dateRange} selectedCatalog={selectedCatalog} />}
    </div>
  );
}


export default UserDashboardSingleUserTimeTrackDetails;
