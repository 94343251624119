import React, { useEffect } from 'react';
import './style.css';
import Loading from '../../components/loading';
import { connect } from 'react-redux';
import { ADMIN, CLIENT, GROUPADMIN, OWNER } from '../../utils/Constants';
import { useNavigate } from 'react-router-dom';

const RootNav = (props) => {
    const { selectedCompany } = props;
    const navigate = useNavigate();
    useEffect(() => {

        if (selectedCompany.userRole.some(role => role.toUpperCase() === ADMIN)) {
            navigate("/team-dashboard");
        }

        else if (selectedCompany.userRole.some(role => role.toUpperCase() === OWNER)) {
            navigate("/team-dashboard");
        }

        else if (selectedCompany.userRole.some(role => role.toUpperCase() === GROUPADMIN)) {
            navigate("/team-dashboard");
        }

        else if (selectedCompany.userRole.some(role => role.toUpperCase() === CLIENT)) {
            navigate("/team-dashboard");
        }

        else {
            navigate("/user-dashboard");
        }

    }, [])

    return (
        <>
            <div className='position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center' ><Loading /></div>
        </>
    )
}
const mapStateToProps = state => ({
    selectedCompany: state.UserReducer.selectedCompany,
});
export default connect(mapStateToProps)(RootNav);
