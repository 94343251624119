import {subDays} from 'date-fns'
import {
    EMAIL_NOTIFICATION_DATE_SELECTION_SUCCESS,
    GET_EMAIL_NOTIFICATION_USERS_GROUPS_SUCCESS,
    GET_EMAIL_NOTIFICATION_USERS_GROUPS_FAILED,

    ADD_SELECTED_USERS_EMAIL_NOTIFICATION,
    REMOVE_SELECTED_USERS_EMAIL_NOTIFICATION,
    ADD_NEW_SELECTED_USER_EMAIL_NOTIFICATION,

    ADD_SELECTED_GROUPS_EMAIL_NOTIFICATION,
    ADD_NEW_SELECTED_GROUP_EMAIL_NOTIFICATION,
    REMOVE_SELECTED_GROUPS_EMAIL_NOTIFICATION,

    FETCH_EMAIL_NOTIFICATION_SETTINGS_SUCCESS,
    FETCH_EMAIL_NOTIFICATION_SETTINGS_FAILED,

    RESET_SELECTED_USERS_AND_GROUPS_EMAIL_NOTIFICATION,
    UPDATE_SINGLE_EMAIL_NOTIFICATION_STATUS,
    
    FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS,
    FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_FAILED,
} from "../actions/Types"
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager'

let selectedDateRange = JSON.parse(getLocalStorageValue("Email_Notification_Settings_Date_Selection"))
let selectedUsers = JSON.parse(getLocalStorageValue("Email_Notification_Settings_User_Selection"))
let selectedGroups = JSON.parse(getLocalStorageValue("Email_Notification_Settings_Group_Selection"))


let listUsers = []
let listGroups = []
let initialState = {
    emailNotificationDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? new Date(selectedDateRange.dates) : subDays(new Date(), 1), label: "Select Date" },
    emailNotificationUsersGroupsList: { users: [], groups: [] },
    selectedEmailNotificationUsers: [],
    selectedEmailNotificationGroups: [],


    modifyEmailNotificationSearchedMembers: [],
    emailNotificationSelectedUsers: selectedUsers ? selectedUsers : [],
    emailNotificationSelectedGroups: selectedGroups ? selectedGroups : [],
    emailNotificationSettingsData: { "data": [], "loadMore": false },
}


export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {

        case EMAIL_NOTIFICATION_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Email_Notification_Settings_Date_Selection", JSON.stringify(newDt))
            return {
                ...state,
                emailNotificationDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            }

        case GET_EMAIL_NOTIFICATION_USERS_GROUPS_SUCCESS:
            return {
                ...state,
                emailNotificationUsersGroupsList: { users: payload.listUsersGroups.users, groups: payload.listUsersGroups.groups }
            }

        case GET_EMAIL_NOTIFICATION_USERS_GROUPS_FAILED:
            return {
                ...state,
                emailNotificationUsersGroupsList: { users: [], groups: [] },
            }

        // for multi users and groups selection

        case ADD_SELECTED_USERS_EMAIL_NOTIFICATION:
            let oldUsers = state.emailNotificationUsersGroupsList.users;
            let newslctedUsers = oldUsers.filter(item => !payload.userSelected.includes(item.id));
            let selectedUsers = oldUsers.filter(item => payload.userSelected.includes(item.id));
            // Sort the array alphabetically by the 'name' field
            const sortedArray = newslctedUsers.sort((a, b) => a.name.localeCompare(b.name));

            // Remove duplicates
            const uniqueArray = sortedArray.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.name === item.name && t.id === item.id
                ))
            );
        
            return {
                ...state,
                selectedEmailNotificationUsers: selectedUsers,
                emailNotificationUsersGroupsList: {
                    users: uniqueArray,
                    groups: state.emailNotificationUsersGroupsList.groups
                }
            };

        case REMOVE_SELECTED_USERS_EMAIL_NOTIFICATION:
            let slctUsers = state.selectedEmailNotificationUsers;
            var filterslct = slctUsers.filter(a => !(a === payload.userRemoved));

            let agnUsers = state.emailNotificationUsersGroupsList;
            var agnslct = (agnUsers.users).concat(payload.userRemoved);
            
            // Sort the array alphabetically by the 'name' field
            const sortedArray1 = agnslct.sort((a, b) => a.name.localeCompare(b.name));

            // Remove duplicates
            const uniqueArray1 = sortedArray1.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.name === item.name && t.id === item.id
                ))
            );

            return {
                ...state,
                selectedEmailNotificationUsers: filterslct,
                emailNotificationUsersGroupsList: { users: uniqueArray1, groups: agnUsers.groups }
            }
            
        case ADD_NEW_SELECTED_USER_EMAIL_NOTIFICATION:
            let newSlctUsers = state.selectedEmailNotificationUsers;
            var addSlct = newSlctUsers.concat(payload.newMemberAdded);
            
            let oldUser = state.emailNotificationUsersGroupsList.users;
            let newselectUser = oldUser.filter(a => !(a === payload.newMemberAdded));

            return {
                ...state,
                selectedEmailNotificationUsers: addSlct,
                emailNotificationUsersGroupsList: {
                    users: newselectUser,
                    groups: state.emailNotificationUsersGroupsList.groups
                }
            }

        case ADD_SELECTED_GROUPS_EMAIL_NOTIFICATION:
            let oldGroups = state.emailNotificationUsersGroupsList.groups;
            let newslctedGroup = oldGroups.filter(item => !payload.groupSelected.includes(item.id));
            let selectedGroup = oldGroups.filter(item => payload.groupSelected.includes(item.id));

            const sortedArray2 = newslctedGroup.sort((a, b) => a.name.localeCompare(b.name));
            const uniqueArray2 = sortedArray2.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.name === item.name && t.id === item.id
                ))
            );
        
            return {
                ...state,
                selectedEmailNotificationGroups: selectedGroup,
                emailNotificationUsersGroupsList: {
                    users: state.emailNotificationUsersGroupsList.users,
                    groups: uniqueArray2
                }
            };

                        
        case ADD_NEW_SELECTED_GROUP_EMAIL_NOTIFICATION:
            let newSlctGroups = state.selectedEmailNotificationGroups;
            var addSlct = newSlctGroups.concat(payload.newGroupAdded);
            let oldGroup = state.emailNotificationUsersGroupsList.groups;
            let newselectGroup = oldGroup.filter(a => !(a === payload.newGroupAdded));

            return {
                ...state,
                selectedEmailNotificationGroups: addSlct,
                    emailNotificationUsersGroupsList: {
                        users: state.emailNotificationUsersGroupsList.users,
                        groups: newselectGroup
                    }
            }

        case REMOVE_SELECTED_GROUPS_EMAIL_NOTIFICATION:
            let slctGroups = state.selectedEmailNotificationGroups;
            var filterslct = slctGroups.filter(a => !(a === payload.grupRemoved));

            let agnGroups = state.emailNotificationUsersGroupsList;
            var agnslct = (agnGroups.groups).concat(payload.grupRemoved);
            
            const sortedArray3 = agnslct.sort((a, b) => a.name.localeCompare(b.name));
            const uniqueArray3 = sortedArray3.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.name === item.name && t.id === item.id
                ))
            );

            return {
                ...state,
                selectedEmailNotificationGroups: filterslct,
                emailNotificationUsersGroupsList: { users: state.emailNotificationUsersGroupsList.users, groups: uniqueArray3 }
            }

        case RESET_SELECTED_USERS_AND_GROUPS_EMAIL_NOTIFICATION:
            let rsetMainUsersGroups = state.emailNotificationUsersGroupsList;
            let rsetUsers = state.selectedEmailNotificationUsers;
            let rsetGroups = state.selectedEmailNotificationGroups;

            var agnslctUsr = (rsetMainUsersGroups.users).concat(rsetUsers);
            var agnslctGrp = (rsetMainUsersGroups.groups).concat(rsetGroups);
            
            var sortedArrayuser = agnslctUsr.sort((a, b) => a.name.localeCompare(b.name));
            var uniqueArrayuser = sortedArrayuser.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.name === item.name && t.id === item.id
                ))
            );

            var sortedArraygroup = agnslctGrp.sort((a, b) => a.name.localeCompare(b.name));
            var uniqueArraygroup = sortedArraygroup.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.name === item.name && t.id === item.id
                ))
            );

            return {
                ...state,
                emailNotificationUsersGroupsList: { 
                    users: uniqueArrayuser, 
                    groups: uniqueArraygroup
                },
                selectedEmailNotificationUsers: [],
                selectedEmailNotificationGroups: [],
            }

        case UPDATE_SINGLE_EMAIL_NOTIFICATION_STATUS:
            let notifyList = state.emailNotificationSettingsData.data;
            
            const updatedNotification = notifyList.map(notification => {
                if (notification._id === payload.documentId) {
                    return { ...notification, status: payload.status, loading: false };
                  }
                  return notification;
                })

            return {
                ...state,
                emailNotificationSettingsData: { 
                    "data": updatedNotification, 
                    "loadMore": state.emailNotificationSettingsData.loadMore
                },
            }

        // page data fetching

        case FETCH_EMAIL_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                emailNotificationSettingsData: { 
                    "data": (payload.dataList).map(item => ({
                        ...item,
                        loading: false 
                    })), 
                    loadMore: payload.loadMore },
            };

        case FETCH_EMAIL_NOTIFICATION_SETTINGS_FAILED:
            return {
                ...state,
                emailNotificationSettingsData: { "data": [], "loadMore": false }
            };

        case FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS:
            let moreDataList = state.emailNotificationSettingsData.data;
            moreDataList = moreDataList.concat(payload.dataList);
            return {
                ...state,
                emailNotificationSettingsData: { 
                "data": moreDataList.map(item => ({
                    ...item,
                    loading: false 
                })),
                loadMore: payload.loadMore },
            };

        case FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_FAILED:
            return {
                ...state,
            };    
        default:
            return state
    }
}
