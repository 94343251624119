import {
    CATALOG_RATING_GROUP_SELECTION_UPDATE,
    CATALOG_RATING_USER_SELECTION_UPDATE,
    FETCH_CATALOG_RATING_SCOPE_FAILED,
    FETCH_CATALOG_RATING_SCOPE_SUCCESS,
    FETCH_CATALOG_RATING_UESER_DROPDOWN_FAILED,
    FETCH_CATALOG_RATING_UESER_DROPDOWN_SUCCESS,
    FETCH_CATALOG_RATING_UPDATE_FAILED,
    FETCH_CATALOG_RATING_UPDATE_SUCCESS,
    FETCH_MORE_CATALOG_RATING_SCOPE_FAILED,
    FETCH_MORE_CATALOG_RATING_SCOPE_SUCCESS,
} from "../actions/Types";
//let selectedUserId = JSON.parse(getLocalStorageValue("Catalog_Rating_Selected_User"));
let initialState = {
    catalogRatingScope: { catalogRating: [], loadMore: true },
    catalogRatingUserDropDown: { users: [], groups: [] },
    selectedUserId: "",
    selectedGroupId: "",
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_CATALOG_RATING_SCOPE_SUCCESS:
            let newCatalogRating = payload.catalogRatingScope.catalogRating;
            newCatalogRating.forEach((i) => {
                i['loading'] = false;
            });
            return {
                ...state,
                catalogRatingScope: { catalogRating: newCatalogRating, loadMore: payload.loadMore },
            };

        case FETCH_CATALOG_RATING_SCOPE_FAILED:
            return {
                ...state,
                catalogRatingScope: { catalogRating: [], loadMore: true }
            }

        case FETCH_MORE_CATALOG_RATING_SCOPE_SUCCESS:
            let catalogRatingscope = state.catalogRatingScope;
            catalogRatingscope.catalogRating = catalogRatingscope.catalogRating.concat(payload.catalogRatingScope.catalogRating);

            catalogRatingscope.catalogRating.forEach((i) => {
                i['loading'] = false;
            });
            return {
                ...state,
                catalogRatingScope: { catalogRating: catalogRatingscope.catalogRating, loadMore: payload.loadMore }
            };

        case FETCH_MORE_CATALOG_RATING_SCOPE_FAILED:
            return {
                ...state,
                catalogRatingScope: { catalogRating: [], loadMore: true }
            }

        case CATALOG_RATING_USER_SELECTION_UPDATE:

            let oldGroupListState = state.catalogRatingUserDropDown.groups;
            let newUserListState = state.catalogRatingUserDropDown.users;
            newUserListState.forEach((user) => {
                user.checked = false
                if (payload.changedUser === user.id) {
                    user.checked = true;
                }
            });

            return {
                ...state,
                catalogRatingUserDropDown: { users: newUserListState, groups: oldGroupListState },
                selectedUserId: payload.changedUser,
            };


        case CATALOG_RATING_GROUP_SELECTION_UPDATE:

            let oldUserListState = state.catalogRatingUserDropDown.users;
            let newGroupListState = state.catalogRatingUserDropDown.groups;
            newGroupListState.forEach((group) => {
                group.checked = false
                if (payload.changedGroup === group.id) {
                    group.checked = true;
                }
            });

            return {
                ...state,
                catalogRatingUserDropDown: { users: oldUserListState, groups: newGroupListState },
                selectedGroupId: payload.changedGroup,
            };

        case FETCH_CATALOG_RATING_UESER_DROPDOWN_SUCCESS:

            payload.catalogRatingUserDropDown.users.forEach((user) => {
                user['checked'] = false;
                if (user.id === state.selectedUserId) {
                    user.checked = true;
                }
            })
            payload.catalogRatingUserDropDown.groups.forEach((group) => {
                group['checked'] = false;
                if (group.id === state.selectedGroupId) {
                    group.checked = true;
                }
            })
            return {
                ...state,
                catalogRatingUserDropDown: payload.catalogRatingUserDropDown,
            };

        case FETCH_CATALOG_RATING_UESER_DROPDOWN_FAILED:
            return {
                ...state,
                catalogRatingUserDropDown: { users: [], groups: [] },
            };

        case FETCH_CATALOG_RATING_UPDATE_SUCCESS:

            let newCatalogScope = state.catalogRatingScope;
            if (payload.catalogRatingUpdate.status === 200) {
                newCatalogScope.catalogRating.forEach(element => {
                    if (element.catalogIds === payload.catalogId) {
                        element.rating = payload.rating;
                        element.scope = payload.scope;
                        element.loading = false;
                    }
                });
            }
            else {
                newCatalogScope.catalogRating.forEach(element => {
                    if (element.catalogIds === payload.catalogId) {
                        element.loading = false;
                    }
                });
            }
            return {
                ...state,
                catalogRatingScope: newCatalogScope,
            };

        case FETCH_CATALOG_RATING_UPDATE_FAILED:
            return {
                ...state,
                catalogRatingScope: { catalogRating: [] }
            };


        default:
            return state;
    }
};