import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  INTERNET_AVAILABILITY,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
} from "./Types";
import AuthService from "../../services/AuthService";



export const signup = (name, email, password, confirmPassword) => (dispatch) => {
  return AuthService.signup(name, email, password, confirmPassword).then(
    (data) => {
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: { authTokens: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: SIGNUP_FAIL,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      let message = ""
      if (error.response.status == 400) {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message)
      }
      dispatch({
        type: SIGNUP_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const login = (username, password, authType) => (dispatch) => {
  return AuthService.login(username, password, authType).then(
    (data) => {
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { authTokens: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      let message = ""
      if (error.response.status == 401) {
        message = "Wrong credentials"
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message)
      }
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const SocialLogin = (token) => (dispatch) => {
  return AuthService.SocialLogin(token).then(
    (data) => {
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { authTokens: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      let message = ""
      if (error.response.status == 401) {
        message = "Wrong credentials"
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message)
      }
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};


export const ResetPassword = (email) => (dispatch) => {
  return AuthService.resetPassword(email).then(
    (data) => {
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: { resetPassword: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: RESET_PASSWORD_FAILED,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      let message = ""
      if (error.response.data.status == 404) {
        message = error.response.data.message;
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message)
      }

      dispatch({
        type: RESET_PASSWORD_FAILED,
        payload: { resetFailed: error.response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};