import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import { ResetPassword } from '../../redux/actions/AuthAction';
import { RESET_PASSWORD_DEFAULT } from "../../redux/actions/Types";
import ResultMessage from "../../components/ResultMessage";


const ForgetPassword = props => {
  const { resetPassword, message } = props;
  const form = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resultError, setResultError] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resultMsg, setResultMsg] = useState("");
  const [resultClass, setResultClass] = useState("");
  // const [msgColor, setMsgColor] = useState("");


  useEffect(() => {
    resetState();
  }, [])


  const dispatch = useDispatch();
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email.toLowerCase());
  };

  useEffect(() => {
    if (resetPassword.status === 200) {
      setResultMsg(resetPassword.message);
      setResultClass('success');
      setResetSuccess(true);
      setResetPasswordLoading(false);
      // setTimeout(() => {
      //   navigate("/login");
      // }, 3000);
    } else if (resetPassword.status === 404) {
      setResultMsg(resetPassword.message);
      // setMsgColor(' wrong-msg');
      setResultError(true);
      setResultClass('error');
      // setResetSuccess(true);
      setResetPasswordLoading(false);
    }
  }, [resetPassword])

  const handleResetPassword = (e) => {
    e.preventDefault();
    setResetPasswordLoading(true);
    resetState();
    dispatch(ResetPassword(email))
      .then(() => {
        // setTimeout(() => {
        //   //setEmail("");
        //   setResultMsg("");
        //   setResetSuccess(false);
        //   dispatch({
        //     type: RESET_PASSWORD_DEFAULT,
        //     payload: { resitPassword: 0 },
        //   })
        // }, 3000)
      })
      .catch((error) => {
        // setTimeout(() => {
        //   setEmail("");
        //   setResultMsg("");
        //   setResetSuccess(false);
        //   dispatch({
        //     type: RESET_PASSWORD_DEFAULT,
        //     payload: { resitPassword: 0 },
        //   })
        // }, 3000)
      });
  }

  const resetState = () => {
    setEmail("");
    setResultMsg("");
    setResultClass('');
    setResultError(false);
    setResetSuccess(false);
    dispatch({
      type: RESET_PASSWORD_DEFAULT,
      payload: { resitPassword: 0 },
    })
  }

  return (
    <>
      <div className='forget-page py-4'>
        <img className='before' src={"images/login-before.png"} />
        <img className='after' src={"images/login-after.png"} />
        <Container>
          <div className='logo mb-4 d-flex justify-content-center'>
            <img src={"images/TimebugLogoOnly.svg"} />
            {/* <p>
                <span className="time-text">time</span>
                <span className="bug-text">Bee</span>
              </p> */}
          </div>
          <div className='bg-white py-4 px-4 from-outer'>
            <h3 className='fs-25 fs-lg-30 text-black text-center mb-4 mt-1 font-bold'>Forgot Your Password?</h3>

            <Form onSubmit={handleResetPassword} ref={form} className="w-100">
              <Form.Group className="mb-2 mb-xxl-3 pb-2" controlId="formBasicEmail">
                <Form.Label className='fs-16 font-medium m-0 mb-2'>Email</Form.Label>
                <Form.Control type="email" placeholder="Email Address" onChange={onChangeEmail} value={email} required />
              </Form.Group>

              {/* {resultError && < ResultMessage messageType={resultClass} message={resultMsg} />} */}
              {resetSuccess ?
                <div className="multilineResultMsg text-center mt-2 p-3">
                  <h5 className="mb-2">{resultMsg}</h5>
                  <p className="mb-2">Remember to check your spam/junk folder. The email might take upto 3 minutes to show up in your inbox.</p>
                  {/* <Link className="px-3 py-2" to="/login"  >Back to Login</Link> */}
                </div>
                :
                <div className="d-flex my-3 mt-4 align-items-center">
                  <Button className='fs-16 text-white reset-pwd font-regular py-1 ' type="submit" disabled={resetPasswordLoading}>
                    <span>Reset Password</span>
                    {resetPasswordLoading && (
                      <span className="spinner-border spinner-border-sm">&nbsp;</span>
                    )}
                  </Button>
                  <Link className="backtologin px-3 py-2" to="/login" onClick={() => { resetState(); }} >Back to Login</Link>
                </div>}
             
            </Form>

          </div>

        </Container >
        <div className="linkss d-flex justify-content-center mt-5">
          <a target="_blank" style={{ color: "white" }} href="https://timebee.app/contact-us/">Contact Support</a>
          <span style={{ color: "white" }} className="mx-2">|</span>
          <a target="_blank" style={{ color: "white" }} href="https://timebee.app/privacy-policy/">Privacy Policy</a>
          <span style={{ color: "white" }} className="mx-2">|</span>
          <a target="_blank" style={{ color: "white" }} href="https://timebee.app/terms-of-service/">Terms of Services</a>
        </div>
      </div >
    </>
  )
}
const mapStateToProps = state => ({
  message: state.MessageReducer.message,
  resetPassword: state.AuthReducer.resetPassword,
});

export default connect(mapStateToProps)(ForgetPassword);