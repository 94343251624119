import subDays from 'date-fns/subDays';
import { timelineRespGenrator, hoursTrackedRespGenratorProductive, hoursTrackedRespGenratorUnProductive, activityPercentageRespGenrator } from "../../common/TimeLinesHelper"

import {
    USER_SELECTED_FROM_TEAM_DASHBOARD,
    USER_DASHBOARD_USER_SELECTION_UPDATE,
    USER_DASHBOARD_USER_SELECTION_SUCCESS,
    FETCH_USER_DASHBOARD_USERS_DROPDOWN_SUCCESS,
    FETCH_USER_DASHBOARD_USERS_DROPDOWN_FAILED,
    FETCH_USER_DASHBOARD_COMULATIVETIME_SUCCESS,
    FETCH_USER_DASHBOARD_COMULATIVETIME_FAILED,
    FETCH_USER_DASHBOARD_USERS_TIMELINE_SUCCESS,
    FETCH_USER_DASHBOARD_USERS_TIMELINE_FAILED,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_SUCCESS,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_FAILED,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_SUCCESS,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_FAILED,
    FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_SUCCESS,
    FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_FAILED,
    FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_SUCCESS,
    FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_FAILED,
    USER_DASHBOARD_DATE_SELECTION_SUCCESS,
    FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS,
    FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
    FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS,
    FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
    FETCH_USER_WEB_APP_USAGE_FAILED,
    FETCH_USER_WEB_APP_USAGE_SUCCESS,
    FETCH_MORE_USER_WEB_APP_USAGE_SUCCESS,
    FETCH_MORE_USER_WEB_APP_USAGE_FAILED,
    FETCH_USER_WPRKED_PROJECT_TASK_SUCCESS,
    FETCH_USER_WPRKED_PROJECT_TASK_FAILED,
    USER_DASHBOARD_PROJECT_TASK_LIST,
    USER_DASHBOARD_PROJECT_CHANGE
} from "../actions/Types";
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager';

let selectedUzerId = JSON.parse(getLocalStorageValue("User_Dashboard_Selected_User"));
let selectedDateRange = JSON.parse(getLocalStorageValue("User_Dashboard_Date_Selection"));
// console.log("selectedDateRange: ", selectedDateRange);
//JSON.parse(getLocalStorageValue("User_Profile")).id
let initialState = {
    selectedUserId: selectedUzerId ? selectedUzerId : "",
    userDashboardUsersDropDown: { users: [], groups: [] },
    userDashboardDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
    // userDashboardDateRange: { dateRange: selectedDateRange ? [new Date(selectedDateRange[0]), new Date(selectedDateRange[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
    userDashboardComulativeTime: { "activityTrack": { Neutral: 0, Productive: 0, totalTime: 0, UnProductive: 0, Unrated: 0 }, "userId": "", userName: "" },
    userDashboardUserTimeline: { "userId": "", "userName": "", "endDate": "", "timeline": [] },
    UserDashboardHoursTrackedProductive: { "userId": "", "userName": "", "endDate": "", "timeline": [] },
    UserDashboardHoursTrackedUnProductive: { "userId": "", "userName": "", "endDate": "", "timeline": [] },
    UserDashboardProductivityPercentage: { "userId": "", "userName": "", "endDate": "", "activityTrack": [] },
    UserDashboardHourlyCatalogTrack: { hourlycatalog: [] },
    UserDashboardHourlyScreenshot: { "screenshots": [] },
    userWebAppUsage: { "catalogUsage": [], "loadMore": false },
    userWorkProjectTask: { "projects": [] },
    userDashProjtTask: { "tasks": [] },
};


export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_SELECTED_FROM_TEAM_DASHBOARD:
            console.log("userDashboardSelectedUser", payload.selectedUserId);

            setLocalStorageValue("User_Dashboard_Selected_User", JSON.stringify(payload.selectedUserId));
            return {
                ...state,
                selectedUserId: payload.selectedUserId,
            };

        case FETCH_USER_DASHBOARD_USERS_DROPDOWN_SUCCESS:
            selectedUzerId = getLocalStorageValue("User_Dashboard_Selected_User") !== null
                ? JSON.parse(getLocalStorageValue("User_Dashboard_Selected_User")).id
                : null;

            payload.userDashboardUsersDropDown.users.forEach((user) => {
                user['checked'] = false;
                if (user.id === selectedUzerId) {
                    user.checked = true;
                }
            })
            return {
                ...state,
                userDashboardUsersDropDown: payload.userDashboardUsersDropDown,
            };

        case FETCH_USER_DASHBOARD_USERS_DROPDOWN_FAILED:
            return {
                ...state,
                userDashboardUsersDropDown: { users: [], groups: [] },
            };

        case USER_DASHBOARD_DATE_SELECTION_SUCCESS:
            // setLocalStorageValue("User_Dashboard_Date_Selection", JSON.stringify(payload.selectedDate));
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("User_Dashboard_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                userDashboardDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };

        case USER_DASHBOARD_USER_SELECTION_SUCCESS:
            setLocalStorageValue("User_Dashboard_Selected_User", JSON.stringify(payload.selectedUserId));
            return {
                ...state,
                selectedUserId: payload.selectedUserId,
            };

        case USER_DASHBOARD_USER_SELECTION_UPDATE:
            let newUserListState = state.userDashboardUsersDropDown.users;
            newUserListState.forEach((user, index) => {
                user.checked = false
                if (payload.changedUser.id === user.id) {
                    setLocalStorageValue("User_Dashboard_Selected_User", JSON.stringify(payload.changedUser));
                    user.checked = true;
                }
            });

            return {
                ...state,
                userDashboardUsersDropDown: { users: newUserListState, groups: [] },
            };

        case FETCH_USER_DASHBOARD_COMULATIVETIME_SUCCESS:
            return {
                ...state,
                userDashboardComulativeTime: payload.userDashboardComulativeTime,
            };

        case FETCH_USER_DASHBOARD_COMULATIVETIME_FAILED:
            return {
                ...state,
                userDashboardComulativeTime: { "activityTrack": { Neutral: 0, Productive: 0, totalTime: 0, UnProductive: 0, Unrated: 0 }, "userId": "", userName: "" },
            };

        case FETCH_USER_DASHBOARD_USERS_TIMELINE_SUCCESS:
            let newTimeLineResponse = timelineRespGenrator(payload.userDashboardUserTimeline);
            return {
                ...state,
                userDashboardUserTimeline: newTimeLineResponse,
            };

        case FETCH_USER_DASHBOARD_USERS_TIMELINE_FAILED:
            return {
                ...state,
                userDashboardUserTimeline: { "userId": "", "userName": "", "endDate": "", "timeline": [] },
            };

        case FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_SUCCESS:
            let newHoursTrackedProductiveResponse = hoursTrackedRespGenratorProductive(payload.UserDashboardHoursTrackedProductive);
            return {
                ...state,
                UserDashboardHoursTrackedProductive: newHoursTrackedProductiveResponse,
            };

        case FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_FAILED:
            return {
                ...state,
                UserDashboardHoursTrackedProductive: { "userId": "", "userName": "", "endDate": "", "timeline": [] },
            };

        case FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_SUCCESS:
            let newHoursTrackedUnProductiveResponse = hoursTrackedRespGenratorUnProductive(payload.UserDashboardHoursTrackedUnProductive);
            return {
                ...state,
                UserDashboardHoursTrackedUnProductive: newHoursTrackedUnProductiveResponse,
            };

        case FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_FAILED:
            return {
                ...state,
                UserDashboardHoursTrackedUnProductive: { "userId": "", "userName": "", "endDate": "", "timeline": [] },
            };

        case FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_SUCCESS:
            let newProductivityPercentage = activityPercentageRespGenrator(payload.UserDashboardProductivityPercentage);
            return {
                ...state,
                UserDashboardProductivityPercentage: newProductivityPercentage,
            };

        case FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_FAILED:
            return {
                ...state,
                UserDashboardProductivityPercentage: { "userId": "", "userName": "", "endDate": "", "activityTrack": [] },
            };

        case FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_SUCCESS:
            return {
                ...state,
                UserDashboardHourlyCatalogTrack: payload.UserDashboardHourlyCatalogTrack,
            };

        case FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_FAILED:
            return {
                ...state,
                UserDashboardHourlyCatalogTrack: { hourlycatalog: [] },
            };

        case FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS:
            return {
                ...state,
                UserDashboardHourlyScreenshot: { "screenshots": payload.UserDashboardHourlyScreenshot.screenshots, "loadMore": payload.loadMore }
            };

        case FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED:
            return {
                ...state,
                UserDashboardHourlyScreenshot: { "screenshots": [], "loadMore": false },
            };

        case FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS:
            let ss = state.UserDashboardHourlyScreenshot;
            let con = ss.screenshots.concat(payload.UserDashboardHourlyScreenshot.screenshots);
            return {
                ...state,
                UserDashboardHourlyScreenshot: { "screenshots": con, loadMore: payload.loadMore },
            };

        case FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED:
            return {
                ...state,
                UserDashboardHourlyScreenshot: { "screenshots": [], loadMore: false },
            };


        case FETCH_USER_WEB_APP_USAGE_SUCCESS:
            return {
                ...state,
                userWebAppUsage: { "catalogUsage": payload.userWebAppUsage.catalogUsage, "loadMore": payload.loadMore }
            };

        case FETCH_USER_WEB_APP_USAGE_FAILED:
            return {
                ...state,
                userWebAppUsage: { "catalogUsage": [], "loadMore": false },
            };

        case FETCH_MORE_USER_WEB_APP_USAGE_SUCCESS:
            let uwau = state.userWebAppUsage;
            let temp = uwau.catalogUsage.concat(payload.userWebAppUsage.catalogUsage);

            return {
                ...state,
                userWebAppUsage: { "catalogUsage": temp, loadMore: payload.loadMore },
            };

        case FETCH_MORE_USER_WEB_APP_USAGE_FAILED:
            return {
                ...state,
                userWebAppUsage: { "catalogUsage": [], loadMore: false },
            };

        case FETCH_USER_WPRKED_PROJECT_TASK_SUCCESS:
            // console.log(payload.workProjectTask)

            return {
                ...state,
                userWorkProjectTask: payload.workProjectTask.projects,
            };

        case FETCH_USER_WPRKED_PROJECT_TASK_FAILED:
            return {
                ...state,
                userWorkProjectTask: { "projects": [] },
            };

        case USER_DASHBOARD_PROJECT_TASK_LIST:

            let projt = [];
            payload.project_task.map((projo, index) => {
                projo.task.map((tisko, indi) => {
                    projt.push({ 'taskId': tisko.taskId, 'taskName': tisko.taskName, 'totalTaskTime': tisko.totalTaskTime, 'projectId': projo.projectId, 'projectName': projo.projectName })
                })
            })
            const orderedData = projt.slice().sort((a, b) => b.totalTaskTime - a.totalTaskTime);

            return {
                ...state,
                userDashProjtTask: { "tasks": orderedData }
            };

        case USER_DASHBOARD_PROJECT_CHANGE:

            let kl = state.userWorkProjectTask;
            var filter_prok = kl.filter(a => (a.projectId === payload.project_ids));
            let provj = [];
            filter_prok.map((projo, index) => {
                projo.task.map((tisko, indi) => {
                    provj.push({ 'taskId': tisko.taskId, 'taskName': tisko.taskName, 'totalTaskTime': tisko.totalTaskTime, 'projectId': projo.projectId, 'projectName': projo.projectName })
                })
            })
            const orderedData_proj = provj.slice().sort((a, b) => b.totalTaskTime - a.totalTaskTime);

            return {
                ...state,
                userDashProjtTask: { "tasks": orderedData_proj }
            };

        default:
            return state;
    };
}
