import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { capitalizeTheFirstLetterOfEachWord, noDataGraph, reverse, timeFormat, timeFormatInHMorMS } from '../../../common/GlobalFunctions'
import './style.css';
import Loading from '../../loading';
const ProductivityChart = (props) => {


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{reverse(label, '-')}</p>
          <div>
            {payload.map((i, index) => (
              <div style={{ display: "inline-block", padding: 5 }} key={index}>
                <div>{capitalizeTheFirstLetterOfEachWord(i.dataKey)} </div>
                <div style={{ color: '#272c36' }}> {timeFormatInHMorMS(i.value)}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };


  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul>
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`}>{capitalizeTheFirstLetterOfEachWord(entry.value)}</li>
          ))
        }
      </ul>
    );
  }
  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y + 5} fill="#666" textAnchor="middle" dy={-6}>{`value: ${timeFormat(value)}`}</text>;
  };

  return (
    <>
      {props.loading ?
        <div className="text-center loader my-4 pt-1 height-min">
          <Loading />
        </div>
        :
        
        <div className={"timelines-table mt-3 top-projects-box vertical-box" + (props.loading ? " skeleton1 " : "")}>
          <ResponsiveContainer width="100%" height="100%" aspect={3}>
              {props.dataArray.some(obj => obj.totalTimeTracked > 0) ?
                <BarChart width={1060} height={250} data={props.dataArray} margin={{ top: 5, right: 5, left: -1, bottom: 0 }}>
                  <XAxis dataKey={props.nameKey} tick={{ fontSize: 12 }} tickFormatter={(label) => `${new Date(label).toLocaleString('en-us', { weekday: 'short' }).charAt(0)}`} />
                  <YAxis tick={{ fontSize: 12 }} tickFormatter={(label) => `${timeFormat(label)}`} />
                  <Tooltip content={<CustomTooltip />} cursor={{ fill: ' #cce5ff90' }} />
                  {/* <Legend /> */}
                  <Legend
                    formatter={(value, entry, index) => <span key={index} className="text-color-class">{capitalizeTheFirstLetterOfEachWord(value)}</span>}
                  />

                  <Bar dataKey="Productive" stackId="a" fill="#6fd2c2" /> 
                  <Bar dataKey="UnProductive" stackId="a" fill="#ff7878" /> 
                  <Bar dataKey="Neutral" stackId="a" fill="#fdb66a" /> 
                  <Bar dataKey="Unrated" stackId="a" fill="#b2b2b2" />
                </BarChart> :
                noDataGraph()}
          </ResponsiveContainer> 
        </div>
        }
    </>
  )
}
export default ProductivityChart

