import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import MessageReducer from "./MessageReducer";
import UserReducer from "./UserReducer";
import DashboardReducer from "./DashboardReducer";
import UserDashboardReducer from "./UserDashboardReducer";
import CatalogRatingReducer from "./CatalogRatingReducer";
import HoursTrackedReducer from "./HoursTrackedReducer";
import ActivitySummaryReducer from "./ActivitySummaryReducer";
import WebsnAppsReducer from "./WebsnAppsReducer";
import ScreenshotReducer from "./ScreenshotReducer";
import SupportReducer from "./SupportReducer";
import UserManagementReducer from "./UserManagementReducer";
import ProjectsReducer from "./ProjectsReducer";
import TasksReducer from "./TasksReducer";
import GroupsReducer from "./GroupsReducer";
import ProjectCostingReducer from "./ProjectCostingReducer";
import PerformanceComparisonReducer from "./PerformanceComparisonReducer";
import CompanyProfileReducer from "./CompanyProfileReducer";
import BillingDetailReducer from "./BillingDetailReducer";
import UserPayRoleReducer from "./UserPayRoleReducer";
import LeftNavReducer from "./LefNavReducer";
import ProjectProgressReducer from "./ProjectProgressReducer";
import ClientManagementReducer from "./ClientManagementReducer";
import AttendanceReportReducer from "./AttendanceReportReducer";
import EmailNotificationSettingsReducer from "./EmailNotificationSettingsReducer";
import UserProfitibilityReducer from "./UserProfitibilityReducer";

export default combineReducers({
  AuthReducer,
  MessageReducer,
  UserReducer,
  DashboardReducer,
  UserDashboardReducer,
  CatalogRatingReducer,
  HoursTrackedReducer,
  ActivitySummaryReducer,
  WebsnAppsReducer,
  ScreenshotReducer,
  SupportReducer,
  LeftNavReducer,
  UserManagementReducer,
  ProjectsReducer,
  TasksReducer,
  GroupsReducer,
  ProjectCostingReducer,
  PerformanceComparisonReducer,
  CompanyProfileReducer,
  BillingDetailReducer,
  UserPayRoleReducer,
  ProjectProgressReducer,
  ClientManagementReducer,
  AttendanceReportReducer,
  EmailNotificationSettingsReducer,
  UserProfitibilityReducer
});