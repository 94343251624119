import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import './style.css';
import { timeFormat } from '../../common/GlobalFunctions'
import CustomToolTip from '../CustomToolTip';
import Loading from '../loading';
const TimeTracked = (props) => {
  const { loading, dashboardComulativeTime } = props;


  //activeUsers: 5  manualTime: 0  neutralTime: 2  productiveTime: 27171  totalTime: 1115669  unProductiveTime: 1070371  unRatedTime: 18125

  const productiveTimePercentage = (
    dashboardComulativeTime.totalTime <= 0 ? 0 : (dashboardComulativeTime.productiveTime * 100) / dashboardComulativeTime.totalTime
  );
  const unProductiveTimePercentage = (
    dashboardComulativeTime.totalTime <= 0 ? 0 : (dashboardComulativeTime.unProductiveTime * 100) / dashboardComulativeTime.totalTime
  );
  const manualTimePercentage = (
    dashboardComulativeTime.totalTime <= 0 ? 0 : (dashboardComulativeTime.manualTime * 100) / dashboardComulativeTime.totalTime
  );

  const neutralTimePercentage = (
    dashboardComulativeTime.totalTime <= 0 ? 0 : (dashboardComulativeTime.neutralTime * 100) / dashboardComulativeTime.totalTime
  );

  const unRatedTimePercentage = (
    dashboardComulativeTime.totalTime <= 0 ? 0 : (dashboardComulativeTime.unRatedTime * 100) / dashboardComulativeTime.totalTime
  );
  const idleTimePercentage = (
    dashboardComulativeTime.totalTime <= 0 ? 0 : (dashboardComulativeTime.idleTime * 100) / dashboardComulativeTime.totalTime
  );

  const productiveTimeFormatted = timeFormat(dashboardComulativeTime.productiveTime);
  const timeTrackedFormatted = timeFormat(dashboardComulativeTime.totalTime);
  const unProductiveTimeFormatted = timeFormat(dashboardComulativeTime.unProductiveTime);
  const neutralTimeFormatted = timeFormat(dashboardComulativeTime.neutralTime);
  const manualTimeFormatted = timeFormat(dashboardComulativeTime.manualTime);
  const unRatedTimeFormatted = timeFormat(dashboardComulativeTime.unRatedTime);
  const idleTimeFormatted = timeFormat(dashboardComulativeTime.idleTime);
  // (loading ? " skeleton1 bg-white" : "")

  return (
    <>
      <div className={`time-tracked-box gap-20 team d-flex justify-content-between my-md-4 mt-md-2  overflow-inherit ${loading ? " skeleton1 bg-white loader-div-rounded" : ""}`}>

        {loading ? (
          <div className="text-center loader my-4 pt-1 height-min">
            <Loading />
          </div>
        ) : (
          <>
            <div className='time total-time-tracked'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-1-team-dash.svg"} alt="Panel 1" />
                </div>
                <div className="div-1">
                  <h4 className='text-bluish tab-text time-track-text fs-16 font-wt-600 mb-md-2'>Total Time Tracked</h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-black text-nowrap'>Total Active User:</p>
                    <p className='text-center text-black ms-1 my-0'>{dashboardComulativeTime.activeUsers}</p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end'>
                <span className='text-black span-time-text fs-25 fs-lg-30 text-start font-bold'>{timeTrackedFormatted}</span>
              </div>
            </div>

            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-2-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-greenish time-track-text fs-16 font-wt-600 mb-md-2'>Productive Time</h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray font-light m-0'><span className='text-green'>{Math.round(productiveTimePercentage)}%</span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{productiveTimeFormatted}</span>
              </div>
            </div>

            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-3-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-redish time-track-text fs-16 font-wt-600 mb-md-2'>Unproductive Time</h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray  font-light m-0'><span className='text-green'>{Math.round(unProductiveTimePercentage)}%</span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{unProductiveTimeFormatted}</span>
              </div>
            </div>
            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-6-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-yellowish time-track-text fs-16 font-wt-600 mb-md-2'>Neutral Time  <CustomToolTip iconColor="balck" details="Hours spent in apps and websites rated neutral i.e. neither productive or unproductive." /></h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray  font-light m-0'><span className='text-green'>
                      {Math.round(neutralTimePercentage)}%
                    </span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>
                  {neutralTimeFormatted}
                </span>
              </div>
            </div>


            <div className='time bg-white gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-4-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1">
                  <h4 className='text-greyish time-track-text fs-16 font-wt-600 mb-md-2'>Unrated Time  <CustomToolTip iconColor="black" details="Time spent in apps that have not yet been rated by your administrator." /></h4>
                  <div className='Total-active d-flex align-items-center'>
                    <p className='text-gray font-light m-0'><span className='text-green'>{Math.round(unRatedTimePercentage)}%</span></p>
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{unRatedTimeFormatted}</span>
              </div>
            </div>
            {/* <div className='time bg-white gray'>
              <h4 className='text-black fs-16 font-medium mb-1'>Unrated Time
                <CustomToolTip iconColor="black" details="Time spent in apps that have not yet been rated by your administrator." /></h4>
              <div className='flex icon d-flex align-items-center mb-2 pb-1'>
                <div className='img'>
                  <img src={"images/manual-time.svg"} alt="Manual Time" />
                </div>
                <div className='d-flex flex-column'>
                  <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{unRatedTimeFormatted}</span>
                  <p className='text-gray ms-2 fs-14 font-light m-0'><span className='text-blue'>{Math.round(unRatedTimePercentage)}%</span></p>
                </div>
              </div>
            </div> */}

            <div className='time bg-white last-gray gray'>
              <div className='total-time-tracked-inner-div'>
                <div className='img me-2 panel-img'>
                  <img src={"images/panel-5-team-dash.svg"} alt="Panel 2" />
                </div>
                <div className="div-1 idle-time-tooltip">
                    <h4 className='text-purple tab-text time-track-text fs-16 font-wt-600 mb-md-2'>Idle Time <CustomToolTip iconColor="black" details="Periods of inactivity when no keyboard or mouse activity is detected on your computer." /></h4>
                  <div className='Total-active d-flex align-items-center'>
                    {/* <p className='text-gray  font-light m-0'><span className='text-green'>{Math.round(idleTimeFormatted)}%</span></p> */}
                  </div>
                </div>
              </div>
              <div className='flex icon d-flex align-items-center justify-content-end mt-3 mt-lg-0'>
                <span className='text-black fs-25 fs-lg-30 font-bold ms-2'>{idleTimeFormatted}</span>
              </div>
            </div>


          </>
        )}
      </div>
    </>
  )
}
export default TimeTracked;