import {
    SET_MESSAGE,
    FETCH_COMANY_PROFILE_SETTING_SUCCESS,
    FETCH_COMANY_PROFILE_SETTING_FAILED,
    UPDATE_COMANY_PROFILE_SETTING_SUCCESS,
    UPDATE_COMANY_PROFILE_SETTING_FAILED,
    FETCH_COMANY_ATTENDANCE_SETTING_SUCCESS,
    FETCH_COMANY_ATTENDANCE_SETTING_FAILED,
    UPDATE_COMANY_ATTENDANCE_SETTING_SUCCESS,
    INTERNET_AVAILABILITY
} from "./Types";
import CompanyProfileService from "../../services/CompanyProfileService";
import { getActiveTimezone } from "../../common/GlobalFunctions";

export const fetchCompanyProfileSettings = (orgId) => (dispatch) => {

    var data = {
        "organizationId": orgId,
    }
    return CompanyProfileService.fetchCompanySettings(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_COMANY_PROFILE_SETTING_SUCCESS,
                    payload: { companySettings: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_COMANY_PROFILE_SETTING_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_COMANY_PROFILE_SETTING_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const updateCompanyProfileSettings = (orgId, company_name, screenShots_val, idleTime_val, silentMonitoring_val, timeZone_val, timeZone_txt, defaultCurrency_val) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "organizationName": company_name,
        "screenShots": screenShots_val,
        "idleTime": idleTime_val,
        "silentMonitoring": silentMonitoring_val,
        "timeZoneOffset": timeZone_val,
        "timeZone": timeZone_txt,
        "currency": defaultCurrency_val,
    }
    return CompanyProfileService.updateCompanyProfileSettings(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_COMANY_PROFILE_SETTING_SUCCESS,
                    payload: { resMessage: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_COMANY_PROFILE_SETTING_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }

        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: UPDATE_COMANY_PROFILE_SETTING_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const updateCompanyAttendanceConfiguration = (orgId, selectedWeekDays, shiftStartTime, shiftEndTime, lateArrivalTime, earlyLeaveTime, minimumRequiredHours) => (dispatch) => {

    var data_array = {
        "organizationId": orgId,
        "workDays": selectedWeekDays,
        "startTime": shiftStartTime,
        "endTime": shiftEndTime,
        "minimumRequiredHours": minimumRequiredHours,
        "lateArrivalMargin": lateArrivalTime,
        "earlyLeaveMargin": earlyLeaveTime,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return CompanyProfileService.updateCompanyAttendanceConfiguration(data_array).then(
        (data) => {
            dispatch({
                type: UPDATE_COMANY_ATTENDANCE_SETTING_SUCCESS,
                payload: { resdata: data_array },
            });
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchAttendanceConfiguration = (orgId) => (dispatch) => {

    var data = {
        "organizationId": orgId,
    }
    return CompanyProfileService.fetchAttendanceConfigurationSettings(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_COMANY_ATTENDANCE_SETTING_SUCCESS,
                    payload: { companyAttendance: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_COMANY_ATTENDANCE_SETTING_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_COMANY_ATTENDANCE_SETTING_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
