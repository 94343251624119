import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL_PHP } from "../utils/Constants";

const fetchCompanySettings = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user/orgownerinfo", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const updateCompanyProfileSettings = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user/orgo-info-update", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const updateCompanyAttendanceConfiguration = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/attendance/update-configuration", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchAttendanceConfigurationSettings = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/attendance/fetch-configuration", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};


export default {
    fetchCompanySettings,
    updateCompanyProfileSettings,
    updateCompanyAttendanceConfiguration,
    fetchAttendanceConfigurationSettings
};