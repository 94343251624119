import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './style.css';

export default function CircularProgressBar(props) {
     const { value, percentage, pathColor, textColor } = props;

     return (
          <>
               <div>
                    <CircularProgressbar value={value} text={`${percentage}%`}
                         styles={buildStyles({
                              rotation: 0.50,
                              strokeLinecap: 'round',
                              textSize: '20px',
                              pathTransitionDuration: 0.5,
                              pathColor: pathColor,
                              textColor: textColor,
                              trailColor: '#d6d6d6',
                              backgroundColor: '#000',
                         })} />
               </div>
          </>
     )
}
