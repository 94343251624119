import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";
import {
    FETCH_DASHBOARD_SUCCESS,
    FETCH_DASHBOARD_FAILED,
    FETCH_MAC_SUCCESS,
    FETCH_MAC_FAILED,
    FETCH_WINDOWS_SUCCESS,
    FETCH_WINDOWS_FAILED,
    LEFT_NAV_EXPANDED_TRUE_FALSE,
    INTERNET_AVAILABILITY,
    IS_CLIENT_VIEW,
} from "../actions/Types";


let isClientView = JSON.parse(getLocalStorageValue("Is_Client_View"));
let initialState = {
    macLink: [],
    windowsLink: [],
    reactLink: [],
    leftNavExpand: "No",
    internetAvailable: true,
    isClientView: isClientView ? isClientView : false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_DASHBOARD_SUCCESS:
            return {
                ...state,
                reactLink: payload.reactLink,
            };

        case FETCH_DASHBOARD_FAILED:
            return {
                ...state,
                reactLink: [],
            };

        case FETCH_WINDOWS_SUCCESS:
            return {
                ...state,
                windowsLink: payload.windowsLink,
            };

        case FETCH_WINDOWS_FAILED:
            return {
                ...state,
                windowsLink: [],
            };

        case FETCH_MAC_SUCCESS:
            return {
                ...state,
                macLink: payload.macLink,
            };

        case FETCH_MAC_FAILED:
            return {
                ...state,
                macLink: [],
            };

        case LEFT_NAV_EXPANDED_TRUE_FALSE:
            return {
                ...state,
                leftNavExpand: payload.leftNavExpand,
            };

        case INTERNET_AVAILABILITY:
            return {
                ...state,
                internetAvailable: payload.internetAvailable,
            };

        case IS_CLIENT_VIEW:
            setLocalStorageValue("Is_Client_View", JSON.stringify(payload.isEnabled));
            return {
                ...state,
                isClientView: payload.isEnabled,
            };
        default:
            return state;
    };
}

