import subDays from 'date-fns/subDays';
import {
    PROJECT_PROGRESS_DATE_SELECTION_SUCCESS,
    FETCH_ALL_ORGANIZATION_PROJECT_SUCCESS,
    FETCH_ALL_ORGANIZATION_PROJECT_FAILED,
    FETCH_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS,
    FETCH_ALL_ORGANIZATION_PROJECT_TASK_FAILED,
    FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS,
    FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_FAILED,
    FETCH_ALL__PROJECT_TASK_USERS_SUCCESS,
    FETCH_ALL__PROJECT_TASK_USERS_FAILED,
    FETCH_MORE_ALL__PROJECT_TASK_USERS_SUCCESS,
    FETCH_MORE_ALL__PROJECT_TASK_USERS_FAILED,
    FETCH_MORE_ORGANIZATION_PROJECT_SUCCESS,
    FETCH_MORE_ORGANIZATION_PROJECT_FAILED,
    PROJECT_PROGRESS_USERS_DROPDOWN_SUCCESS,
    PROJECT_PROGRESS_USERS_DROPDOWN_FAILED,
    PROJECT_PROGRESS_USER_ADDED_SUCCESS,
    PROJECT_PROGRESS_USER_REMOVE_SUCCESS,
    PROJECT_PROGRESS_USER_CLEAR_SUCCESS,
    PROJECT_PROGRESS_GROUPS_CLEAR_SUCCESS,
    PROJECT_PROGRESS_GROUP_SELECTION_SUCCESS,
    PROJECT_PROGRESS_DASHBOARD_SELECTED_USER
} from "../actions/Types";
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager';

let selectedDateRange = JSON.parse(getLocalStorageValue("Project_n_Progress_Date_Selection"));
let selectedUsers = JSON.parse(getLocalStorageValue("Project_n_Progress_User_Selection"));
let selectedGroups = JSON.parse(getLocalStorageValue("Project_n_Progress_Group_Selection"));
let listUsers = [];
let listGroups = [];

let initialState = {
    projectProgressDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
    projectList: { projects: [], loadMore: false },
    taskList: { tasks: [], loadMore: false },
    userList: { users: [], loadMore: false },
    projectProgressUsersDropDown: { users: [], groups: [] },
    projectProgressSelectedUsers: selectedUsers ? selectedUsers : [],
    projectProgressSelectedGroups: selectedGroups ? selectedGroups : [],
};


export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case PROJECT_PROGRESS_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Project_n_Progress_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                projectProgressDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };

        case FETCH_ALL_ORGANIZATION_PROJECT_SUCCESS:
            return {
                ...state,
                projectList: { projects: payload.projects.projectList, loadMore: payload.loadMore }
            };
        case FETCH_ALL_ORGANIZATION_PROJECT_FAILED:
            return {
                ...state,
                projectList: { projects: [], loadMore: false },
            };
        case FETCH_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS:
            return {
                ...state,
                taskList: { tasks: payload.tasks.taskList, loadMore: payload.loadMore }
            };
        case FETCH_ALL_ORGANIZATION_PROJECT_TASK_FAILED:
            return {
                ...state,
                taskList: { tasks: [], loadMore: false },
            };
        case FETCH_ALL__PROJECT_TASK_USERS_SUCCESS:
            return {
                ...state,
                userList: { users: payload.users.userList, loadMore: payload.loadMore }
            };
        case FETCH_ALL__PROJECT_TASK_USERS_FAILED:
            return {
                ...state,
                userList: { users: [], loadMore: false },
            };
            
        case FETCH_MORE_ORGANIZATION_PROJECT_SUCCESS:
            let list = state.projectList;
            let con = list.projects.concat(payload.projects.projectList);
            return {
                ...state,
                projectList: { projects: con, loadMore: payload.loadMore }
            }
        case FETCH_MORE_ORGANIZATION_PROJECT_FAILED:
            let listy = state.projectList;
            return {
                ...state,
                projectList: { projects: listy, loadMore: false },
            };
        case FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS:
            let list_t = state.taskList;
            let con_t = list_t.tasks.concat(payload.tasks.taskList);
            return {
                ...state,
                taskList: { tasks: con_t, loadMore: payload.loadMore }
            }
        case FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_FAILED:
            let list_f = state.taskList;
            return {
                ...state,
                taskList: { tasks: list_f, loadMore: false },
            };
        case FETCH_MORE_ALL__PROJECT_TASK_USERS_SUCCESS:
            let list_u = state.userList;
            let con_u = list_u.users.concat(payload.users.userList);
            return {
                ...state,
                userList: { users: con_u, loadMore: payload.loadMore }
            }
        case FETCH_MORE_ALL__PROJECT_TASK_USERS_FAILED:
            let list_uf = state.userList;
            return {
                ...state,
                userList: { users: list_uf, loadMore: false },
            };

        // user and group dropdown code bellow
        case PROJECT_PROGRESS_USERS_DROPDOWN_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Project_n_Progress_User_Selection"));
            selectedGroups = JSON.parse(getLocalStorageValue("Project_n_Progress_Group_Selection"));
        
            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false;
            });
        
            listUsers = payload.listUsersDropDown.users;
            const filteredAddMembers = selectedUsers !== null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users;
        
            listGroups = payload.listUsersDropDown.groups;
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false;
                if (selectedGroups) {
                selectedGroups.forEach((selectedGroup) => {
                    if (selectedGroup.id === group.id) group.checked = true;
                })
                }
            });
        
            return {
                ...state,
                projectProgressUsersDropDown: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            };
        
        case PROJECT_PROGRESS_USERS_DROPDOWN_FAILED:
            return {
                ...state,
                projectProgressUsersDropDown: { users: [], groups: [] },
            };

        case PROJECT_PROGRESS_USER_ADDED_SUCCESS:
            let users = state.projectProgressSelectedUsers;
            users = users.concat(payload.userAdded);
           setLocalStorageValue("Project_n_Progress_User_Selection", JSON.stringify(users));
            let searched = state.projectProgressUsersDropDown.users;
            var filter_0 = searched.filter(a => !(a === payload.userAdded));
            return {
                ...state,
                projectProgressSelectedUsers: users,
                projectProgressUsersDropDown: { users: filter_0, groups: state.projectProgressUsersDropDown.groups }
            };

        case PROJECT_PROGRESS_USER_REMOVE_SUCCESS:
            let uzers = state.projectProgressUsersDropDown.users;
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false;
                uzers.push(payload.userRemoved);
                uzers.sort((a, b) => a.name.localeCompare(b.name));
            }else{
                uzers.push(payload.userRemoved);
                uzers.sort((a, b) => a.name.localeCompare(b.name));

            }
        
            let arr = state.projectProgressSelectedUsers;
            var filter_1 = arr.filter(a => !(a === payload.userRemoved));
           setLocalStorageValue("Project_n_Progress_User_Selection", JSON.stringify(filter_1));
            return {
                ...state,
                projectProgressSelectedUsers: filter_1,
                projectProgressUsersDropDown: { users: uzers, groups: state.projectProgressUsersDropDown.groups }
            };

        case PROJECT_PROGRESS_USER_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false;
            });
        
           setLocalStorageValue("Project_n_Progress_User_Selection", JSON.stringify(null));
            return {
                ...state,
                projectProgressSelectedUsers: [],
                projectProgressUsersDropDown: { users: listUsers, groups: state.projectProgressUsersDropDown.groups }
            };

        case PROJECT_PROGRESS_GROUP_SELECTION_SUCCESS:
            let newUserListState_0 = state.projectProgressUsersDropDown
            let oldUserListState_slt = state.projectProgressSelectedGroups

            oldUserListState_slt.forEach((group, index) => {
            if (payload.changedGroup.id === group.id) {
                group.checked = payload.changedGroup.checked;
            }else{
                group.checked = false
            }
            });

            newUserListState_0.groups.forEach((group, index) => {
            if (payload.changedGroup.id === group.id) {
                group.checked = payload.changedGroup.checked;
            }else{
                group.checked = false
            }
            });

           setLocalStorageValue("Project_n_Progress_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)));

            return {
            ...state,
            projectProgressUsersDropDown: { users: newUserListState_0.users, groups: newUserListState_0.groups },
            projectProgressSelectedGroups: oldUserListState_slt,
            };

        case PROJECT_PROGRESS_DASHBOARD_SELECTED_USER:
            let userx = state.projectProgressSelectedUsers;
            userx.forEach((user) => {
                user['checked'] = false;
            });
            userx = [];
            let updatedObject = { ...payload.selectedUser, checked: true };
            userx.push(updatedObject);
           setLocalStorageValue("Project_n_Progress_User_Selection", JSON.stringify(userx));
           setLocalStorageValue("projectProgress_Selected_Tab", "user")
            return {
                ...state,
                projectProgressSelectedUsers: userx,
            };

        default:
            return state;
    }
}
