import React from 'react'

export default function Loading() {
  return (
    <div className="host">
      <div className="loadings loading-0"></div>
      <div className="loadings loading-1"></div>
      <div className="loadings loading-2"></div>
      <div className="loadings loading-3"></div>
    </div>
  )
}
