import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL_PHP } from "../utils/Constants";

const fetchProfile = () => {
  return axios
    .get(API_URL_PHP + "api/v1/user/details", { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      // console.log("Error:  ", error.response);
      return error.response;
    })
};

const fetchUserCompanies = () => {
  return axios
    .get(API_URL_PHP + "api/v1/user/organizations", { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      // console.log("Error:  ", error.response);
      return error.response;
    })
};

const fetchAddNewUser = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/adduser", requestData, { headers: authHeader() })
    .then((response) => {
      // console.log("service data: ", response);
      return response;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      // console.log("Error:  ", error.response);
      return error.response;
    })
};

const updateProfileSettings = (requestData) => {
  return axios
    // .post(API_URL_PHP + "api/v1/user/update-user-profile", requestData, { headers: authHeader() })
    .post(API_URL_PHP + "api/v1/user/changeuserprofile", requestData, { headers: authHeader() })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const addNewCompanyService = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/organization/organization-create", requestData, { headers: authHeader() })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      return error;
    })
};




export default {
  fetchProfile,
  fetchUserCompanies,
  fetchAddNewUser,
  updateProfileSettings,
  addNewCompanyService
};