
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { connect } from "react-redux";
import { ADMIN, OWNER, GROUPADMIN, MEMBER, CLIENT, BASIC, ULTRA, PREMIUM } from '../utils/Constants'
import ScrollToTop from "./ScollToTop";
import { allowedBasedOnPackage, allowedBasedOnRole, dateForComparison } from '../common/GlobalFunctions';

import RootNav from '../pages/RootNav';
import SignUp from '../pages/SignUp';
import Login from '../pages/Login';
import ForgetPassword from '../pages/ForgetPassword';
import Loading from '../components/loading';
import CompanySelection from '../pages/CompanySelection';
import TeamDashboard from '../pages/TeamDashboard';
import UserDashboard from '../pages/UserDashboard';
// import ProjectCostingNewPg from '../pages/ProjectCostingNewPg';
//import UserManagement from '../pages/UserManagement';

// const Login = lazy(() => import('../pages/Login'));
// const ForgetPassword = lazy(() => import('../pages/ForgetPassword'));

// const TeamDashboard = lazy(() => import('../pages/TeamDashboard'));
// const CompanySelection = lazy(() => import('../pages/CompanySelection'));
// const UserDashboard = lazy(() => import('../pages/UserDashboard'));
const CatalogRating = lazy(() => import('../pages/CatalogRating'));
const ActivitySummary = lazy(() => import('../pages/ActivitySummary'));
const HoursTracked = lazy(() => import('../pages/HoursTracked'));
const TeamWebApp = lazy(() => import('../pages/TeamWebApp'));
const Downloads = lazy(() => import('../pages/Downloads'));
const Screenshots = lazy(() => import('../pages/ScreenShots'));
const UserManagement = lazy(() => import('../pages/UserManagement'));
const UserProfitibility = lazy(() => import('../pages/UserProfitibility'));
const AddEditProject = lazy(() => import('../pages/AddEditProject'));
const ProjectCosting = lazy(() => import('../pages/ProjectCosting'));
const AddEditTask = lazy(() => import('../pages/AddEditTask'));
const AddEditGroup = lazy(() => import('../pages/AddEditGroup'));
const PerformanceComparison = lazy(() => import('../pages/PerformaceComparison'));
const BillingDetails = lazy(() => import('../pages/BillingDetails'));
const PayRole = lazy(() => import('../pages/PayRole'));
const ProjectProgress = lazy(() => import('../pages/ProjectProgress'));
const Clients = lazy(() => import('../pages/Clients'));
const MyTeam = lazy(() => import('../pages/MyTeam'));
const ClientsTeam = lazy(() => import('../pages/ClientsTeam'));
const AutoLoginClient = lazy(() => import('../pages/AutoLogin'));
const OrganizationConfiguration = lazy(() => import('../pages/OrganizationConfiguration'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const AttendanceReport = lazy(() => import('../pages/AttendanceReport'));
const EmailNotification = lazy(() => import('../pages/EmailNotification'));
const UpgradeInsist = lazy(() => import('../pages/Upgrade'));
const ProjectCostingNewPg = lazy(() => import('../pages/ProjectCostingNewPg'));


// import AutoLoginClient from '../pages/AutoLogin';

// import CatalogRating from '../pages/CatalogRating';
// import ActivitySummary from '../pages/ActivitySummary';
// import HoursTracked from '../pages/HoursTracked';
// import TeamWebApp from '../pages/TeamWebApp';
// import Downloads from '../pages/Downloads';
// import Screenshots from '../pages/ScreenShots';

// import ProjectCosting from '../pages/ProjectCosting';
// import AddEditProject from '../pages/AddEditProject';
// import AddEditTask from '../pages/AddEditTask';
// import AddEditGroup from '../pages/AddEditGroup';
// import PerformanceComparison from '../pages/PerformaceComparison';
// import BillingDetails from '../pages/BillingDetails';
// import PayRole from '../pages/PayRole';
// import ProjectProgress from '../pages/ProjectProgress';
// import Clients from '../pages/Clients';
// import MyTeam from '../pages/MyTeam';
// import ClientsTeam from '../pages/ClientsTeam';


const ResRoute = ({ component: Component, isLoggedIn, ...rest }) => {
    return isLoggedIn ? <Component /> : <Navigate to="/login" />;
}

const RestrictedRoute = ({ component: Component, isLoggedIn, selectedCompany, allowedRoles, allowedPackages, title, description, ...rest }) => {
    if (isLoggedIn) {
        if (selectedCompany.id !== "") {

            if (allowedRoles) {
                const isAllowed = allowedBasedOnRole(allowedRoles, selectedCompany.userRole)
                // if (allowedRoles.includes(selectedCompany.userRole)) {
                if (isAllowed) {
                    if (allowedPackages) {
                        const tuple = allowedBasedOnPackage(allowedPackages, selectedCompany.billing?.package)
                        // console.log("isAllowed: 88 ", tuple);
                        if (tuple.isAllowed) {
                            return <Component />
                        } else {
                            console.log("title, description: ", title, description);
                            return <Navigate to="/upgrade-please" state={{
                                title: title, description: description, imgSrc: "", minPackage: tuple.minPackage
                            }} />
                        }
                    }
                    return <Component />

                } else {
                    return <Navigate to="/user-dashboard" />
                }
            }
            else {
                return <Component />
            }
        }
        else {
            return <Navigate to="/company-selection" />
        }
    }
    else {
        return <Navigate to="/login" />
    }
};



const Navigation = props => {
    const { isLoggedIn, selectedCompany } = props

    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Suspense fallback={
                    <div className='position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center' ><Loading /></div>}>
                    <Routes>

                        <Route exact path="/" element={<RestrictedRoute component={RootNav} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, GROUPADMIN, CLIENT]} />} />

                        <Route exact path="/cl" element={<AutoLoginClient />} />
                        <Route exact path="/upgrade-please" element={<UpgradeInsist />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/signup" element={<SignUp />} />
                        <Route exact path="/forget-password" element={<ForgetPassword />} />
                        {/* <Route exact path="/company-selection" element={<CompanySelection />} /> */}

                        <Route exact path="/company-selection" element={<ResRoute component={CompanySelection} isLoggedIn={isLoggedIn} />} />

                        <Route exact path="/team-dashboard" element={<RestrictedRoute component={TeamDashboard} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, GROUPADMIN, CLIENT]} />} />


                        <Route exact path="/user-dashboard" element={<RestrictedRoute component={UserDashboard} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} />} />

                        <Route exact path="/webapp-rating" element={<RestrictedRoute component={CatalogRating} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} />} />

                        <Route exact path="/activity-summary" element={<RestrictedRoute component={ActivitySummary} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, GROUPADMIN, CLIENT]} />} />

                        <Route exact path="/hours-track" element={<RestrictedRoute component={HoursTracked} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, GROUPADMIN, CLIENT]} />} />

                        <Route exact path="/webs-apps" element={<RestrictedRoute component={TeamWebApp} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedPackages={[PREMIUM, ULTRA]} title="Websites & Apps Usage" description="Total time spent on websites and apps, classified by productivity." />} />

                        <Route exact path="/downloads" element={<RestrictedRoute component={Downloads} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} />} />

                        <Route exact path="/organization" element={<RestrictedRoute component={OrganizationConfiguration} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} />} />
                        <Route exact path="/profile" element={<RestrictedRoute component={UserProfile} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} />} />



                        <Route exact path="project-hours" element={<RestrictedRoute component={ProjectProgress} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedPackages={[ULTRA]} title="Project Progress" description="Progress Monitor all projects at a glance." />} />

                        <Route exact path="/screenshots" element={<RestrictedRoute component={Screenshots} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} />} />

                        <Route exact path="/quarterly-performance" element={<RestrictedRoute component={PerformanceComparison} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany}
                            allowedPackages={[PREMIUM, ULTRA]} title="Quarterly Performance" description="Work hour trends over a quarter." />} />

                        <Route exact path="/projects" element={<RestrictedRoute component={AddEditProject} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, , GROUPADMIN, CLIENT]} />} />

                        <Route exact path="/tasks" element={<RestrictedRoute component={AddEditTask} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, GROUPADMIN, CLIENT]} />} />

                        <Route exact path="/groups" element={<RestrictedRoute component={AddEditGroup} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, GROUPADMIN, CLIENT]} allowedPackages={[PREMIUM, ULTRA]} title="Groups" description="Create, edit, or archive groups for your organization" />} />

                        <Route exact path="/user-management" element={<RestrictedRoute component={UserManagement} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} />} />

                        <Route exact path="/clients" element={<RestrictedRoute component={Clients} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} allowedPackages={[ULTRA]} title="Clients" description="Manage your clients." />} />

                        <Route exact path="/my-team" element={<RestrictedRoute component={MyTeam} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[CLIENT]} />} />

                        <Route exact path="/client-team" element={<RestrictedRoute component={ClientsTeam} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} />} />

                        <Route exact path="/payroll" element={<RestrictedRoute component={PayRole} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, CLIENT]} />} />

                        <Route exact path="/project-costing" element={<RestrictedRoute component={ProjectCostingNewPg} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, CLIENT]} allowedPackages={[ULTRA]} title="Groups" description="Project budget and costing" />} />

                        {/* <Route exact path="/project-costing-new" element={<RestrictedRoute component={ProjectCostingNewPg} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER, CLIENT]} allowedPackages={[ULTRA]} title="Groups" description="Project budget and costing" />} /> */}

                        <Route exact path="/billing-details" element={<RestrictedRoute component={BillingDetails} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} />} />

                        <Route exact path="/attendance-report" element={<RestrictedRoute component={AttendanceReport} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} allowedPackages={[PREMIUM, ULTRA]} title="Attendance Report" description="Breakdown of activity, by productivity per user." />} />

                        <Route exact path="/email-notification" element={<RestrictedRoute component={EmailNotification} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} allowedPackages={[PREMIUM, ULTRA]} title="Email Notification" description="Customize report preferences, receive timely updates in your inbox." />} />



                        <Route exact path="/profitability-report" element={<RestrictedRoute component={UserProfitibility} isLoggedIn={isLoggedIn} selectedCompany={selectedCompany} allowedRoles={[ADMIN, OWNER]} />} />

                        <Route path="*" element={<RootNav />} />

                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>
    )
};

const mapStateToProps = state => ({
    isLoggedIn: state.AuthReducer.isLoggedIn,
    selectedCompany: state.UserReducer.selectedCompany,
});

export default connect(mapStateToProps)(Navigation);