import React from "react";
import { Col, Row, Form, Dropdown, DropdownButton } from "react-bootstrap";
import "./style.css";
import {
  getRandomColor,
  noDataSimple,
  timeFormat,
} from "../../common/GlobalFunctions";
import { Link, useNavigate } from "react-router-dom";
import CustomToolTip from "../CustomToolTip";
import Loading from "../loading";

const TrackingLeastHours = (props) => {
  const { loading, dashboardTrackingLeastHours, isSelectedUr, zeroTrackedHrs, dashboardDateRange } = props;

  const navigate = useNavigate();

  function FindMax(array) {
    return Math.max(...array.map((x) => x.timeTracked));
  }

  function FindPercentange(array, element) {
    return FindMax(dashboardTrackingLeastHours) <= 0
      ? 0
      : (element * 100) / FindMax(array);
  }

  const dummySkeletonFunction = () => {
    let dummySkeleton = [];

    for (let i = 0; i < 6; i++) {
      dummySkeleton.push(
        <div
          className="line-hours d-flex justify-content-between align-items-center mb-4"
          key={i}
        >
          <div className="names d-flex align-items-center">
            <span className="d-flex justify-content-center align-items-center u">
              a
            </span>
            <p className="m-0 ms-2">---</p>
          </div>
          <div className="line">
            <div className="red" style={{ width: "100px" }}></div>
          </div>
          <span className="time">--</span>
        </div>
      );
    }
    return dummySkeleton;
  };

  const handleClickNavigate = () => {
    const params = new URLSearchParams({
      timetrack: 1,
      date: dashboardDateRange.dateRange,
      label: dashboardDateRange.label
    });

    navigate(`/hours-track?${params.toString()}`);
  };

  return (
    <>
      <Col className="box m-0  mb-sm-2 mt-sm-0 mb-md-0" md={12} lg={12} xl={6}>
        <div
          className={
            "top-projects-box my-3 my-md-1 my-lg-3 mb-0 mt-lg-2 mt-xl-2 box-shadow-light trackinghours overflow-inherit" +
            (loading ? " skeleton1 " : "")
          }
        >
          <div className="bg-white p-0  py-3 py-md-2 pb-md-3 px-4 border-radius">
            {loading ? (
              <div className="text-center loader my-4 pt-1 height-min">
                <Loading />
              </div>
            ) : (
              <>
                <div className="heading d-flex lowest-tracking-tooltip  justify-content-between align-items-baseline pt-lg-1  ">
                  <h3 className="heading heading-font mb-4 align-items-center text-wrap text-sm-nowrap transform-tooltip team-dashboard-tooltips">
                    Lowest Tracked Hours
                    <CustomToolTip details="Users who have tracked the least amount of work hours." />
                  </h3>
                  <Row className="flex team-dashboard-dropdown-adjustment justify-content-center">
                    <DropdownButton
                      className="zero-tracked pe-3 d-flex justify-content-end ms-1"
                      title={isSelectedUr}
                      onSelect={zeroTrackedHrs}
                    >
                      <Dropdown.Item eventKey="All User">
                        All User
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="TimeTracked User">
                        Users who have tracked time
                      </Dropdown.Item>
                    </DropdownButton>
                  </Row>
                </div>

                <Row>
                  <Col sm={12}>
                    {!loading && dashboardTrackingLeastHours.length > 0
                      ? dashboardTrackingLeastHours.map((s, index) => (
                        <div key={index}>
                          <div className="line-hours d-flex justify-content-between align-items-center mb-4">
                            <div className="names d-flex align-items-center">
                              <span
                                style={{
                                  backgroundColor: getRandomColor(s.name),
                                }}
                                className="d-flex justify-content-center align-items-center u"
                              >
                                {s.name.charAt(0)}
                              </span>
                              <p className="m-0 ms-2">{s.name}</p>
                            </div>
                            <div className="line UnProductive">
                              <div
                                className="red progress-bar"
                                style={{
                                  width: `${FindPercentange(
                                    dashboardTrackingLeastHours,
                                    s.timeTracked
                                  )}%`,
                                }}
                              ></div>
                            </div>
                            <span className="time pe-2 d-flex justify-content-end">
                              {timeFormat(s.timeTracked)}
                            </span>
                          </div>
                        </div>
                      ))
                      : noDataSimple()}
                  </Col>
                </Row>
                <p
                  className="more-btn"
                  onClick={handleClickNavigate}
                >
                  {" "}
                  more{" "}
                </p>
              </>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default TrackingLeastHours;
