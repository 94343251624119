import {
    FETCH_CLIENTS_SUCCESS,
    FETCH_CLIENTS_FAILED,
    USER_PROFITIBILITY_DATE_SELECTION_SUCCESS,
    FETCH_USERS_PROFITIBILITY_FAILED,
    FETCH_USERS_PROFITIBILITY_SUCCESS,
    FETCH_MORE_USERS_PROFITIBILITY_SUCCESS,
    FETCH_MORE_USERS_PROFITIBILITY_FAILED,
    FETCH_MORE_CLIENTS_FAILED,
    FETCH_MORE_CLIENTS_SUCCESS,
    FETCH_CLIENT_PROJECTS_SUCCESS,
    FETCH_MORE_CLIENT_PROJECTS_SUCCESS,
    FETCH_MORE_CLIENT_PROJECTS_FAILED,
    FETCH_CLIENT_PROJECTS_FAILED,
} from "../actions/Types";

import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";
import { subDays } from "date-fns";


let selectedDateRange = JSON.parse(getLocalStorageValue("User_Profitibility_Date_Selection"))
let selectedClient = JSON.parse(getLocalStorageValue("User_Profitibility_Client_Selection"))
let selectedGroups = JSON.parse(getLocalStorageValue("Activity_Summary_Group_Selection"))


let initialState = {
    listUsers: { list: [], loadMore: false },
    listClients: { list: [], loadMore: false },
    clientProjects: { list: [], loadMore: false },
    usersProfitibilityDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
    userProfitibilitySelectedClient: selectedClient ? selectedClient : [],
    userProfitibilitySelectedGroups: selectedGroups ? selectedGroups : [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case USER_PROFITIBILITY_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("User_Profitibility_Date_Selection", JSON.stringify(newDt))
            return {
                ...state,
                usersProfitibilityDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            }


        case FETCH_USERS_PROFITIBILITY_SUCCESS:
            let newUsers = payload.listUsers;
            newUsers.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                listUsers: { list: newUsers, loadMore: payload.loadMore },
            };

        case FETCH_USERS_PROFITIBILITY_FAILED:
            return {
                ...state,
                listUsers: { list: [], loadMore: false },
            };

        case FETCH_MORE_USERS_PROFITIBILITY_SUCCESS:
            let us = state.listUsers;
            let con = us.list.concat(payload.listUsers);
            con.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                listUsers: { list: con, loadMore: payload.loadMore },
            };

        case FETCH_MORE_USERS_PROFITIBILITY_FAILED:
            return {
                ...state,
                listUsers: { list: [], loadMore: false },
            };

        case FETCH_CLIENTS_SUCCESS:
            let newClients = payload.listClients;
            newClients.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                listClients: { list: newClients, loadMore: payload.loadMore },
            };

        case FETCH_CLIENTS_FAILED:
            return {
                ...state,
                listClients: { list: [], loadMore: false },
            };

        case FETCH_MORE_CLIENTS_SUCCESS:
            let currentClients = state.listClients;

            let newMoreClients = currentClients.list.concat(payload.listClients);
            newMoreClients.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                listClients: { list: newMoreClients, loadMore: payload.loadMore },
            };

        case FETCH_MORE_CLIENTS_FAILED:
            return {
                ...state,
                listClients: { list: [], loadMore: false },
            };


        case FETCH_CLIENT_PROJECTS_SUCCESS:
            let clientProjects = payload.clientProjects;
            clientProjects.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                clientProjects: { list: clientProjects, loadMore: payload.loadMore },
            };

        case FETCH_CLIENT_PROJECTS_FAILED:
            return {
                ...state,
                clientProjects: { list: [], loadMore: false },
            };

        case FETCH_MORE_CLIENT_PROJECTS_SUCCESS:
            let cClientProjects = state.clientProjects;

            let newMoreClientProjects = cClientProjects.list.concat(payload.clientProjects);
            newMoreClientProjects.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                clientProjects: { list: newMoreClientProjects, loadMore: payload.loadMore },
            };

        case FETCH_MORE_CLIENT_PROJECTS_FAILED:
            return {
                ...state,
                clientProjects: { list: [], loadMore: false },
            };



        default:
            return state;
    }
}
