
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import TimezoneSelect from 'react-timezone-select'
import { Form } from 'react-bootstrap';
import './style.css';
import { allowedBasedOnRole } from '../../common/GlobalFunctions';
import { ADMIN, OWNER } from '../../utils/Constants';
import { useDispatch, connect } from "react-redux";


const CompanyTimeZone = (props) => {
  const { defaultTimeZone, currentUserRole, timeZoneRef, setTimeZoneValue, setOffSetValue } = props

  // const [selectedTimezone, setSelectedTimezone] = useState(defaultTimeZone.length === 0 || defaultTimeZone === 'UTC' ? 'Etc/GMT' : defaultTimeZone)
  const [selectedTimezone, setSelectedTimezone] = useState('Etc/GMT')

  const customOffsetFormat = (offset) => {
    const sign = offset >= 0 ? '+' : '-';
    const hours = Math.floor(Math.abs(offset));
    const minutes = Math.abs(Math.round((offset % 1) * 60));
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${sign}${formattedHours}:${formattedMinutes}`;
  };

  const handleChange = (selectedOption) => {
    // You may need to adapt this depending on the structure of the selected option
    const customOffset = customOffsetFormat(selectedOption.offset);
    setOffSetValue(customOffset)
    setTimeZoneValue(selectedOption.value === 'Etc/GMT' ? 'UTC' : selectedOption.value)
    setSelectedTimezone(selectedOption.value)
  };

  useEffect(() => {
    setSelectedTimezone(defaultTimeZone.length === 0 || defaultTimeZone === 'UTC' ? 'Etc/GMT' : defaultTimeZone)
  }, [defaultTimeZone])

  return (
    <>
      {/* <label>Time Zone</label> */}
      <TimezoneSelect
        value={selectedTimezone}
        onChange={handleChange}
        displayValue={'UTC'}
        className='custom-timezone-class'
      />
    </>
  );
}
export default CompanyTimeZone;
