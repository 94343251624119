import { getActiveTimezone } from "../../common/GlobalFunctions";
import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";
import {
    FETCH_ADD_NEW_USER_FAILED,
    FETCH_ADD_NEW_USER_SUCCESS,
    FETCH_LOGGED_IN_USER_DETAILS_SUCCESS,
    FETCH_USER_COMPANIES_SUCCESS,
    SELECTED_COMPANY_SELECTION_SUCCESS,
    UPDATE_PROFILE_SETTINGS_FAILED,
    UPDATE_PROFILE_SETTINGS_SUCCESS,
    ADD_NEW_USER_SUCCUCCESS_RESET,    
} from "../actions/Types";

const selectedCompany = JSON.parse(getLocalStorageValue("Selected_Company"));
const userProfile = JSON.parse(getLocalStorageValue("User_Profile"));

let initialState = {
    userProfile: userProfile ? userProfile : { id: "", email: "", role: "", name: "", status: "", profileUrl: "", timezone: "", timezoneOffset: "" },
    selectedCompany: selectedCompany ? selectedCompany : { id: '', name: '', orgStatus: '', userRole: [], userStatus: '' },
    userCompanies: [],
    addNewUsers: { "data": { "message": '', 'email': '', "status": '' } },
    updatedProfile: [{ "data": { "message": '' } }],
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_LOGGED_IN_USER_DETAILS_SUCCESS:
            setLocalStorageValue("User_Profile", JSON.stringify(payload.userProfile));
            return {
                ...state,
                userProfile: payload.userProfile,
            };
        case FETCH_USER_COMPANIES_SUCCESS:
            return {
                ...state,
                userCompanies: payload.userCompanies,
            };

        case SELECTED_COMPANY_SELECTION_SUCCESS:
            setLocalStorageValue("Selected_Company", JSON.stringify(payload.selectedCompany));
            return {
                ...state,
                selectedCompany: payload.selectedCompany,
            };


        case FETCH_ADD_NEW_USER_SUCCESS:
            return {
                ...state,
                addNewUsers: payload.addNewUsers,
            };

        case FETCH_ADD_NEW_USER_FAILED:
            return {
                ...state,
                addNewUsers: { "data": { "message": '', 'email': '', "status": '' } }
            };
        case ADD_NEW_USER_SUCCUCCESS_RESET:
            return {
                ...state,
                addNewUsers: { "data": { "message": '', 'email': '', "status": '' } }
            };

        case UPDATE_PROFILE_SETTINGS_SUCCESS:
            return {
                ...state,
                updatedProfile: payload.updatedProfile,
            };

        case UPDATE_PROFILE_SETTINGS_FAILED:
            return {
                ...state,
                updatedProfile: [{ "data": { "message": 'something went wrong' } }]
            };
     
        

        default:
            return state;
    }
};