import config from "../config";

export const API_URL = config.API.API_URL_DEVICE_PYTHON;
export const API_URL_PHP = config.API.API_URL_WEB_PHP;
export const profile = config.profile.name;


export const NoDataToDisplay = "No data available. Try adjusting the filters."
/// USER ROLES////
export const OWNER = "OWNER";
export const ADMIN = "ADMIN";
export const MEMBER = "MEMBER";
export const GROUPADMIN = "GROUPADMIN";
export const CLIENT = "CLIENT";

export const BASIC = "BASIC";
export const PREMIUM = "PREMIUM";
export const ULTRA = "ULTRA";


export const NO_INTERNET = "No Internet Access";
export const PACKAGE_EXPIRE = "Product has been expired!";

// export const VERSION = "2023-07-05-19:15";
export const VERSION = "24071819";