import { SET_LEFTNAV_SUCCESS } from "../actions/Types";


let initialState = {
    leftNavSelectedItem: { selectedItem: "", parentName: "" },
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_LEFTNAV_SUCCESS:
            return {
                ...state,
                leftNavSelectedItem: payload.leftNavSelectedItem,
            };

        default:
            return state;
    };
}

