import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL_PHP } from "../utils/Constants";

const fetchUserDashboardUsersDropDown = (requestData) => {
    return axios
        // .post(API_URL_PHP + "api/v1/user-dashboard/org/usergroup", requestData, { headers: authHeader() })
        .post(API_URL_PHP + "api/v1/user/usergroupsearch", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};


const fetchUserDashboardComulativeTime = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/userstatsdata", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchUserDashboardUsersTimeline = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/usertimeline2", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchUserDashboardHoursTrackedProductive = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/useractivityday", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchUserDashboardHoursTrackedUnProductive = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/useractivityday", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchUserDashboardProductivityPercentage = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/useractivitycatalog", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchUserDashboardHourlyCatalogTrack = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/hourlycatalogtrack", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};
const fetchUserDashboardHourlyScreenshot = (requestData) => {
    return axios
        // .post(API_URL_PHP + "api/v1/user-dashboard/userscreenshots", requestData, { headers: authHeader() })
        .post(API_URL_PHP + "api/v1/user/userwisescreenshot", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};
const fetchSingleUserCatalogDetails = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/userwebappusage", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchSingleUserProjectTaskServ = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/user-project-task", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchSingleCatalogTitle = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user-dashboard/catalog-title", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

export default {

    fetchUserDashboardUsersDropDown,
    fetchUserDashboardComulativeTime,
    fetchUserDashboardUsersTimeline,
    fetchUserDashboardHoursTrackedProductive,
    fetchUserDashboardHoursTrackedUnProductive,
    fetchUserDashboardProductivityPercentage,
    fetchUserDashboardHourlyCatalogTrack,
    fetchUserDashboardHourlyScreenshot,
    fetchSingleUserCatalogDetails,
    fetchSingleUserProjectTaskServ,
    fetchSingleCatalogTitle
};