import {
    FETCH_COMANY_PROFILE_SETTING_SUCCESS,
    FETCH_COMANY_PROFILE_SETTING_FAILED,
    UPDATE_COMANY_PROFILE_SETTING_SUCCESS,
    UPDATE_COMANY_PROFILE_SETTING_FAILED,
} from "../actions/Types";

let initialState = {
    companySettingsData:
    {
        "organizationId": "",
        "organizationName": "",
        "ownerId": "",
        "ownerName": "",
        "ownerEmail": "",
        "timeZone": "",
        "timeZoneOffset": "",
        "currency": "",
        "settings": {
            "idleTime": "",
            "silentMonitoring": "",
            "screenShotEnable": "",
            "screenshotCaptureTime": "",
            "developer_enabled": ""
        }
    },
    resMessage:{'message': ''},
  };

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_COMANY_PROFILE_SETTING_SUCCESS:
            return {
                ...state,
                companySettingsData: payload.companySettings,
              };
        case FETCH_COMANY_PROFILE_SETTING_FAILED:
            return {
                ...state,
                companySettingsData: 
                {
                    "organizationId": "",
                    "organizationName": "",
                    "ownerId": "",
                    "ownerName": "",
                    "ownerEmail": "",
                    "timeZone": "",
                    "timeZoneOffset": "",
                    "currency": "",
                    "settings": {
                        "idleTime": "",
                        "silentMonitoring": "",
                        "screenShotEnable": "",
                        "screenshotCaptureTime": "",
                        "developer_enabled": ""
                    }
                },
            };
        case UPDATE_COMANY_PROFILE_SETTING_SUCCESS:
            return {
                ...state,
                resMessage: payload.resMessage,
                };
        case UPDATE_COMANY_PROFILE_SETTING_FAILED:
            return {
                ...state,
                resMessage:{'message': ''},
            };

        default:
            return state;
    };
}
