import { subDays } from "date-fns";
import { timeFormatInHMorMS } from '../../common/GlobalFunctions';
import {
    USER_PAY_ROLE__DATE_SELECTION_SUCCESS,
    FETCH_USER_PAY_ROLE_DETAILS_SUCCESS,
    FETCH_USER_PAY_ROLE_DETAILS_FAILED,
    FETCH_MORE_USER_PAY_ROLE_SUCCESS,
    FETCH_MORE_USER_PAY_ROLE_FAILED,
    UPDATE_USER_PAYROLE_CURRENCY,
    UPDATE_USER_ADJUSTMENT_PRICE,
    UPDATE_USER_HOUR_RATE,
    FETCH_PAYROLL_CLIENT_FAILED,
    FETCH_MORE_PAYROLL_CLIENT_SUCCESS,
    FETCH_MORE_PAYROLL_CLIENT_FAILED,
    FETCH_PAYROLL_CLIENT_SUCCESS,
    PAYROLL_USER_ADDED_SUCCESS,
    PAYROLL_USER_REMOVE_SUCCESS,
    PAYROLL_USERS_CLEAR_SUCCESS,
    PAYROLL_GROUPS_CLEAR_SUCCESS,
    FETCH_PAYROLL_USERS_DROPDOWN_SUCCESS,
    FETCH_PAYROLL_USERS_DROPDOWN_FAILED,
    PAYROLL_GROUP_SELECTION_UPDATE,
    PAYROLL_USER_SELECTION_SUCCESS,
    PAYROLL_CLIENT_SELECTION_UPDATE,
    PAYROLL_CLIENTS_CLEAR_SUCCESS
} from "../actions/Types";

import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";

let selectedDateRange = JSON.parse(getLocalStorageValue("Pay_Role_Date_Selection"));
let selectedUsers = JSON.parse(getLocalStorageValue("Payroll_User_Selection"));
let selectedGroups = JSON.parse(getLocalStorageValue("Payroll_Group_Selection"));
let listUsers = [];
let listGroups = [];



let initialState = {
    userPayRoleDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },

    payRoleData: { userPayRole: [], loadMore: false },
    payrollClients: { list: [], loadMore: false },
    payrollUsersGroups: { users: [], groups: [] },
    payrollSelectedUsers: selectedUsers ? selectedUsers : [],
    payrollSelectedGroups: selectedGroups ? selectedGroups : [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_PAYROLL_CLIENT_SUCCESS:
            
            let frm_local = JSON.parse(getLocalStorageValue("Payroll_Client_Selection"));
            payload.payrollClients.forEach((user) => {
                if(frm_local !== null && frm_local[0].id === user.id){
                    user['checked'] = true;
                }else{
                    user['checked'] = false;
                }
            });

            return {
                ...state,
                payrollClients: { list: payload.payrollClients, loadMore: payload.loadMore }
            };

        case FETCH_PAYROLL_CLIENT_FAILED:
            return {
                ...state,
                payrollClients: { list: [], loadMore: false }
            };

        case FETCH_MORE_PAYROLL_CLIENT_SUCCESS:
            let us = state.payrollClients;

            payload.payrollClients.forEach((user) => {
                user['checked'] = false;
            });

            let con = us.list.concat(payload.payrollClients);
            return {
                ...state,
                payrollClients: { list: con, loadMore: payload.loadMore }
            };

        case FETCH_MORE_PAYROLL_CLIENT_FAILED:

            return {
                ...state,
                payrollClients: { list: [], loadMore: false }
            };

        // user and group dropdown code bellow
        case FETCH_PAYROLL_USERS_DROPDOWN_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Payroll_User_Selection"));
            selectedGroups = JSON.parse(getLocalStorageValue("Payroll_Group_Selection"));

            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false;
            });

            listUsers = payload.listUsersDropDown.users;
            const filteredAddMembers = selectedUsers !== null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users;

            listGroups = payload.listUsersDropDown.groups;
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false;
                if (selectedGroups) {
                    selectedGroups.forEach((selectedGroup) => {
                        if (selectedGroup.id === group.id) group.checked = true;
                    })
                }
            });

            return {
                ...state,
                payrollUsersGroups: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            };

        case FETCH_PAYROLL_USERS_DROPDOWN_FAILED:
            return {
                ...state,
                payrollUsersGroups: { users: [], groups: [] },
            };


        case PAYROLL_GROUP_SELECTION_UPDATE:

            let newUserListState_0 = state.payrollUsersGroups
            newUserListState_0.groups.forEach((group, index) => {
                if (payload.changedGroup.id === group.id) {
                    group.checked = payload.changedGroup.checked;
                } else {
                    group.checked = false
                }
            });
            setLocalStorageValue("Payroll_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)));

            return {
                ...state,
                payrollUsersGroups: { users: listUsers, groups: newUserListState_0.groups },
            };

        case PAYROLL_CLIENT_SELECTION_UPDATE:
            let newClientListState_0 = state.payrollClients
            newClientListState_0.list.forEach((client, index) => {
                if (payload.changedClient.id === client.id) {
                    client.checked = payload.changedClient.checked;
                } else {
                    client.checked = false
                }
            });
            setLocalStorageValue("Payroll_Client_Selection", JSON.stringify(newClientListState_0.list.filter(obj => obj.checked === true)));

            return {
                ...state,
                payrollClients: { list: newClientListState_0.list, loadMore: false }
            };


        case PAYROLL_USER_ADDED_SUCCESS:
            let users = state.payrollSelectedUsers;
            users = users.concat(payload.userAdded);
            setLocalStorageValue("Payroll_User_Selection", JSON.stringify(users));
            let searched = state.payrollUsersGroups.users;
            var filter_0 = searched.filter(a => !(a === payload.userAdded));

            return {
                ...state,
                payrollSelectedUsers: users,
                payrollUsersGroups: { users: filter_0, groups: listGroups },
            };


        case PAYROLL_USER_REMOVE_SUCCESS:
            let uzers = state.payrollUsersGroups.users;
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false;
                uzers.push(payload.userRemoved);
                uzers.sort((a, b) => a.name.localeCompare(b.name));
            }

            let arr = state.payrollSelectedUsers;
            var filter_1 = arr.filter(a => !(a === payload.userRemoved));
            setLocalStorageValue("Payroll_User_Selection", JSON.stringify(filter_1));
            return {
                ...state,
                payrollSelectedUsers: filter_1,
                payrollUsersGroups: { users: uzers, groups: state.payrollUsersGroups.groups }
            };


        case PAYROLL_USERS_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false;
            });

            setLocalStorageValue("Payroll_User_Selection", JSON.stringify(null));
            return {
                ...state,
                payrollSelectedUsers: [],
                payrollUsersGroups: { users: listUsers, groups: state.payrollUsersGroups.groups }
            };

        case PAYROLL_GROUPS_CLEAR_SUCCESS:
            // console.log("Reducer, listGroups: ", listGroups);
            listGroups.forEach((group) => {
                group['checked'] = false;
            });

            setLocalStorageValue("Payroll_Group_Selection", JSON.stringify(null));
            return {
                ...state,
                payrollUsersGroups: { users: state.payrollUsersGroups.users, groups: listGroups }
            };
        case PAYROLL_CLIENTS_CLEAR_SUCCESS:
            const clnts = state.payrollClients;
            clnts.list.forEach((client) => {
                client['checked'] = false;
            });

            setLocalStorageValue("Payroll_Client_Selection", JSON.stringify(null));
            return {
                ...state,
                payrollClients: { list: clnts.list, loadMore: false }
            };

        case PAYROLL_USER_SELECTION_SUCCESS:
            setLocalStorageValue("Payroll_User_Selection", JSON.stringify(payload.selectedUsers));
            return {
                ...state,
                payrollSelectedUsers: payload.selectedUsers
            };



        case USER_PAY_ROLE__DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Pay_Role_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                userPayRoleDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };

        case FETCH_USER_PAY_ROLE_DETAILS_SUCCESS:
            let n_arr = payload.fetchPayRoleData;
            let alt_arr = [];
            if (n_arr.length > 0) {
                n_arr.map((s, index) => (
                    alt_arr.push({
                        'name': s.name,
                        'totalTime': timeFormatInHMorMS(s.totalActiveTime),
                        'realTime': s.totalActiveTime,
                        'currency': 'USD',
                        'adjustmentAmount': 0,
                        'hourRate': s.hourRate,
                        'totalPay': (s.hourRate) === null ? '0.00' : (((s.totalActiveTime) / 3600) * s.hourRate).toFixed(2)
                    })
                ));
            }
            return {
                ...state,
                payRoleData: { userPayRole: alt_arr, loadMore: payload.loadMore },
            };

        case FETCH_USER_PAY_ROLE_DETAILS_FAILED:
            return {
                ...state,
                payRoleData: { userPayRole: [], loadMore: false },
            };

        case FETCH_MORE_USER_PAY_ROLE_SUCCESS:
            let o_arr = payload.fetchPayRoleData;
            let flt_arr = [];
            if (o_arr.length > 0) {
                o_arr.map((s, index) => (
                    flt_arr.push({
                        'name': s.name,
                        'totalTime': timeFormatInHMorMS(s.totalActiveTime),
                        'realTime': s.totalActiveTime,
                        'currency': 'USD',
                        'adjustmentAmount': 0,
                        'hourRate': s.hourRate,
                        'totalPay': (s.hourRate) === null ? '0.00' : (((s.totalActiveTime) / 3600) * s.hourRate).toFixed(2)
                    })
                ));
            }

            let old_pay_role = state.payRoleData.userPayRole;
            let combine_array = old_pay_role.concat(flt_arr);
            return {
                ...state,
                payRoleData: { userPayRole: combine_array, loadMore: payload.loadMore },
            };

        case FETCH_MORE_USER_PAY_ROLE_FAILED:
            return {
                ...state,
                payRoleData: { userPayRole: [], loadMore: false },
            };
        case UPDATE_USER_PAYROLE_CURRENCY:
            let old_loadMore = state.payRoleData.loadMore;
            let up_ind = payload.index;
            let up_curr = payload.currency;
            let old_currency_arr = state.payRoleData.userPayRole;
            old_currency_arr[up_ind].currency = up_curr;
            return {
                ...state,
                payRoleData: { userPayRole: old_currency_arr, loadMore: old_loadMore },
            };

        case UPDATE_USER_ADJUSTMENT_PRICE:
            let ur_loadMore = state.payRoleData.loadMore;
            let old_arr = state.payRoleData.userPayRole;
            let ur_ind = payload.index;
            let ur_pri = payload.price;


            let old_price_rate = old_arr[ur_ind].hourRate;
            let old_real_time = old_arr[ur_ind].realTime;
            let value_price = (old_real_time) === null ? '0.00' : (((old_real_time) / 3600) * old_price_rate).toFixed(2)

            // console.log('value_price', value_price)
            let old_cst = parseFloat(value_price) + parseFloat(ur_pri);
            old_arr[ur_ind].totalPay = old_cst.toFixed(2);
            old_arr[ur_ind].adjustmentAmount = ur_pri;
            return {
                ...state,
                payRoleData: { userPayRole: old_arr, loadMore: ur_loadMore },
            };

        case UPDATE_USER_HOUR_RATE:

            let oldloadMore = state.payRoleData.loadMore;
            let oldPayRole = state.payRoleData.userPayRole;
            let changedIndex = payload.index;
            let changedRate = payload.hourRate;

            let oldAdjAmount = oldPayRole[changedIndex].adjustmentAmount;
            let oldreal_time = oldPayRole[changedIndex].realTime;
            let timeXrate = (oldreal_time) === null ? '0.00' : (((oldreal_time) / 3600) * changedRate).toFixed(2)

            let newCost = parseFloat(timeXrate) + parseFloat(oldAdjAmount);
            oldPayRole[changedIndex].totalPay = newCost.toFixed(2);
            oldPayRole[changedIndex].hourRate = changedRate;
            return {
                ...state,
                payRoleData: { userPayRole: oldPayRole, loadMore: oldloadMore },
            };



        default:
            return state;
    }
};