import secureLocalStorage from "react-secure-storage";

export function getLocalStorageValue(key) {
    if (process.env.REACT_APP_STAGE === "dev") {
        return localStorage.getItem(key);
    }
    else {
        return secureLocalStorage.getItem(key);
    }
}
export function setLocalStorageValue(key, value) {
    if (process.env.REACT_APP_STAGE === "dev") {
        localStorage.setItem(key, value);
    }
    else {
        secureLocalStorage.setItem(key, value);
    }
}
export function removeLocalStorageValue(key) {
    if (process.env.REACT_APP_STAGE === "dev") {
        localStorage.removeItem(key);
    }
    else {
        secureLocalStorage.removeItem(key);
    }
}
export function clearLocalStorage() {
    if (process.env.REACT_APP_STAGE === "dev") {
        localStorage.clear();
    }
    else {
        secureLocalStorage.clear();
    }
}