import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_DEFAULT,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL
} from "../actions/Types";
const authTokens = JSON.parse(getLocalStorageValue("Auth_Tokens"));
// const initialState = authTokens
//   ? { isLoggedIn: true, authTokens }
//   : { isLoggedIn: false, authTokens: null };

let initialState = {
  resetPassword: { message: "", status: 0, subCode: 0 },
  isLoggedIn: authTokens ? true : false,
  authTokens: authTokens ? authTokens : null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SIGNUP_SUCCESS:
      if (payload.authTokens.accessToken) {
        setLocalStorageValue("Auth_Tokens", JSON.stringify(payload.authTokens));
      }
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case LOGIN_SUCCESS:
      if (payload.authTokens.accessToken) {
        setLocalStorageValue("Auth_Tokens", JSON.stringify(payload.authTokens));
      }
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: payload.resetPassword,
      };

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPassword: payload.resetFailed,
      };
    case RESET_PASSWORD_DEFAULT:
      return {
        ...state,
        resetPassword: { message: "", status: 0, subCode: 0 },
      };



    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}