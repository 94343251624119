import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addNewCompanyAction } from "../../redux/actions/UserAction";
import { useDispatch } from "react-redux";
import ResultMessage from '../ResultMessage';
import './style.css';
import Loading from '../loading';

function AddNewCompany(props) {
  const { show, handleClose, setShowAddCompany } = props;
  const dispatch = useDispatch();
  const [inputCompany, setInputCompany] = useState("");
  const [message, setMessage] = useState("");
  const [resultClass, setResultClass] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  
  const handleInputChange = (event) => {
    setInputCompany(event.target.value);
  };

  const addCompanyNew = (event) => {
    event.preventDefault();
    setFormLoading(true)
    dispatch(addNewCompanyAction(inputCompany))
      .then((res) => {
        setFormLoading(false)
        if (res.status === 200) {
          setMessage(res.message)
          setResultClass("success")
          setShowMessage(true);
        } else {
          setMessage(res.message)
          setResultClass("error");
          setShowMessage(true);
        }
        // setTimeout(() => {
        //   setShowAddCompany(false)
        //   window.location.reload();
        // }, 3000);

      })
      .catch((err) => {
        setFormLoading(false)
        console.log('catch', err)
      });

  }

  return (
    <>
      <div className='addusermodal '>
        <Modal className='adduserpopap add-new-org' show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <h4>Add New Organization</h4>
          </Modal.Header>
          <div>
            <Modal.Body>
              <form className="App px-3 py-3" autoComplete="off" onSubmit={addCompanyNew}>
                <Form.Group className="mb-3" controlId="1">
                  <Form.Label className='addCompanyLabel mb-lg-3' >What is your organization's name?</Form.Label>
                  <Form.Control type="text" value={inputCompany} onChange={handleInputChange} required />
                </Form.Group>
                {!showMessage && <>
                  {formLoading ?
                    <div className='my-5'>
                      <Loading />
                    </div>
                    :
                    <Button type="submit" className='mb-3 m-3 px-4' disabled={formLoading}>
                      <span className='text-white'>Create</span>
                    </Button>}
                </>}
                <>
                  {/* {showMessage && <ResultMessage messageType={resultClass} message={message} />} */}
                  {showMessage &&
                    <div className="multilineResultMsg text-center mt-2 p-3">
                      <h5 className="mb-2 py-3">{message}</h5>

                      {/* <Link className="px-3 py-2" to="/login"  >Back to Login</Link> */}
                      <Button className='NextBtnOnCreateOrg' onClick={() => {
                        setShowAddCompany(false)
                        window.location.reload();
                      }}> Next </Button>
                    </div>
                  }
                </>
              </form>
            </Modal.Body>
          </div >
        </Modal>
      </div>
    </>
  );
}

export default AddNewCompany;