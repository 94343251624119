import { subDays } from "date-fns";
import {
    FETCH_PROJECT_COST_LIST_SUCCESS,
    FETCH_PROJECT_COST_LIST_FAILED,
    PROJECT_COSTING_DATE_SELECTION_SUCCESS,
    FETCH_PROJECT_COST_TASK_SUCCESS,
    FETCH_PROJECT_COST_TASK_FAILED,
    FETCH_PROJECT_COST_PROJECT_SUCCESS,
    FETCH_PROJECT_COST_PROJECT_FAILED,
    FETCH_MORE_PROJECT_COST_PROJECT_SUCCESS,
    FETCH_MORE_PROJECT_COST_PROJECT_FAILED,
    FETCH_MORE_PROJECT_COST_TASK_SUCCESS,
    FETCH_MORE_PROJECT_COST_TASK_FAILED,
    // PROJECT_COSTING_DATE_SELECTION_FAILED
} from "../actions/Types";
import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";
let selectedDateRange = JSON.parse(getLocalStorageValue("Project_Cost_Date_Selection"));
let initialState = {
    fetchProjectCostData: { projects: [] },
    projectCostProjects: { list: [], loadMore: false },
    projectCostTasks: { list: [], loadMore: false },
    projectCostDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PROJECT_COST_LIST_SUCCESS:
            return {
                ...state,
                fetchProjectCostData: payload.fetchProjectCostData,
            };

        case FETCH_PROJECT_COST_LIST_FAILED:
            return {
                ...state,
                fetchProjectCostData: { projects: [] }
            };

        case PROJECT_COSTING_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Project_Cost_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                projectCostDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };
        //
        case FETCH_PROJECT_COST_PROJECT_SUCCESS:
            let projs = payload.projectCostProjects;
            projs.projects.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                projectCostProjects: { list: projs.projects, loadMore: payload.loadMore },
            };

        case FETCH_PROJECT_COST_PROJECT_FAILED:
            return {
                ...state,
                projectCostProjects: { list: [], loadMore: false },
            };

        case FETCH_MORE_PROJECT_COST_PROJECT_SUCCESS:
            let listProjs = state.projectCostProjects;
            let con = listProjs.list.concat(payload.projectCostProjects.projects);
            con.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                projectCostProjects: { list: con, loadMore: payload.loadMore },
            };

        case FETCH_MORE_PROJECT_COST_PROJECT_FAILED:
            return {
                ...state,
                projectCostProjects: { list: [], loadMore: false },
            };

        ///

        case FETCH_PROJECT_COST_TASK_SUCCESS:
            let tasks = payload.projectCostTasks;
            tasks.tasks.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                projectCostTasks: { list: tasks.tasks, loadMore: payload.loadMore },
            };

        case FETCH_PROJECT_COST_TASK_FAILED:
            return {
                ...state,
                projectCostTasks: { list: [], loadMore: false },
            };

        case FETCH_MORE_PROJECT_COST_TASK_SUCCESS:

            let listTasks = state.projectCostTasks;
            let concat = listTasks.list.concat(payload.projectCostTasks);
            concat.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                projectCostTasks: { list: concat, loadMore: payload.loadMore },
            };

        case FETCH_MORE_PROJECT_COST_TASK_FAILED:
            return {
                ...state,
                projectCostTasks: { list: [], loadMore: false },
            };


        default:
            return state;
    }
};