import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL, API_URL_PHP } from "../utils/Constants";

const fetchDashboardComulativeTime = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/timetracks/stats", requestData, { headers: authHeader() })
    .then((response) => {
      // console.log("res: ", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};


const fetchDashboardWebsitesAppsProductive = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/topcatalog/usage", requestData, { headers: authHeader() })
    .then((response) => {
      // console.log("productive web", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchDashboardWebsitesAppsUnProductive = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/topcatalog/usage", requestData, { headers: authHeader() })
    .then((response) => {
      // console.log("Un-Productive web", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchDashboardTrackingMostHours = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/totaltimetrack", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchDashboardTrackingLeastHours = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/totaltimetrack", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchDashboardTopUsersProductive = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/topusers/percentage", requestData, { headers: authHeader() })
    .then((response) => {
      // console.log("TopUsers-Productive: ", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchDashboardTopUsersUnProductive = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/topusers/percentage", requestData, { headers: authHeader() })
    .then((response) => {
      // console.log("TopUsers-UnProductive: ", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};


const fetchDashboardUsersDropDown = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/org/usergroup", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};
const fetchDashboardUsersGroups = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/usergroupsearch", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchDashboardTopProjects = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/team-dashboard/topprojects/timetracked", requestData, { headers: authHeader() })
    .then((response) => {
      // console.log("top projects: ", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

export default {
  fetchDashboardComulativeTime,
  fetchDashboardWebsitesAppsProductive,
  fetchDashboardWebsitesAppsUnProductive,
  fetchDashboardTrackingMostHours,
  fetchDashboardTrackingLeastHours,
  fetchDashboardTopUsersProductive,
  fetchDashboardTopUsersUnProductive,
  fetchDashboardUsersDropDown,
  fetchDashboardTopProjects,
  fetchDashboardUsersGroups,
};