export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SIGNUP_FAIL = "SIGNUP_FAIL"

export const LOGOUT = "LOGOUT"

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"
export const RESET_PASSWORD_DEFAULT = "RESET_PASSWORD_DEFAULT"

export const SET_MESSAGE = "SET_MESSAGE"
export const CLEAR_MESSAGE = "CLEAR_MESSAGE"

export const USER_DETAIL_FETCH = "USER_DETAIL_FETCH"
export const FETCH_LOGGED_IN_USER_DETAILS_SUCCESS = "FETCH_LOGGED_IN_USER_DETAILS_SUCCESS"
export const FETCH_LOGGED_IN_USER_DETAILS_FAILED = "FETCH_LOGGED_IN_USER_DETAILS_FAILED"

export const USER_DETAIL_FETCH_SUCCESS = "USER_DETAIL_FETCH_SUCCESS"
export const USER_DETAIL_FETCH_FAILED = "USER_DETAIL_FETCH_FAILED"

export const FETCH_USER_COMPANIES_SUCCESS = "FETCH_USER_COMPANIES_SUCCESS"
export const FETCH_USER_COMPANIES_FAILED = "FETCH_USER_COMPANIES_FAILED"

export const FETCH_ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS"
export const FETCH_ADD_NEW_USER_FAILED = "ADD_NEW_USER_FAILED"

export const UPDATE_PROFILE_SETTINGS_SUCCESS = "UPDATE_PROFILE_SETTINGS_SUCCESS"
export const UPDATE_PROFILE_SETTINGS_FAILED = "UPDATE_PROFILE_SETTINGS_FAILED"

export const ADD_NEW_USER_SUCCUCCESS_RESET = "ADD_NEW_USER_SUCCUCCESS_RESET"

export const UPDATE_PROFILE_IMAGE_SUCCESS = "UPDATE_PROFILE_IMAGE_SUCCESS"
export const UPDATE_PROFILE_IMAGE_FAILED = "UPDATE_PROFILE_IMAGE_FAILED"

export const SELECTED_COMPANY_SELECTION_SUCCESS = "SELECTED_COMPANY_SELECTION_SUCCESS"

export const FETCH_DASHBOARD_COMULATIVETIME_SUCCESS = "FETCH_DASHBOARD_COMULATIVETIME_SUCCESS"
export const FETCH_DASHBOARD_COMULATIVETIME_FAILED = "FETCH_DASHBOARD_COMULATIVETIME_FAILED"

export const FETCH_DASHBOARD_TRACKING_MOST_HOURS_SUCCESS = "FETCH_DASHBOARD_TRACKING_MOST_HOURS_SUCCESS"
export const FETCH_DASHBOARD_TRACKING_MOST_HOURS_FAILED = "FETCH_DASHBOARD_TRACKING_MOST_HOURS_FAILED"

export const FETCH_DASHBOARD_TRACKING_LEAST_HOURS_SUCCESS = "FETCH_DASHBOARD_TRACKING_LEAST_HOURS_SUCCESS"
export const FETCH_DASHBOARD_TRACKING_LEAST_HOURS_FAILED = "FETCH_DASHBOARD_TRACKING_LEAST_HOURS_FAILED"

export const FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_SUCCESS = "FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_SUCCESS"
export const FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_FAILED = "FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_FAILED"
export const FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_SUCCESS = "FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_SUCCESS"
export const FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_FAILED = "FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_FAILED"

export const FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_SUCCESS = "FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_SUCCESS"
export const FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_FAILED = "FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_FAILED"

export const FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_SUCCESS = "FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_SUCCESS"
export const FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_FAILED = "FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_FAILED"

export const FETCH_DASHBOARD_USERS_DROPDOWN_SUCCESS = "FETCH_DASHBOARD_USERS_DROPDOWN_SUCCESS"
export const FETCH_DASHBOARD_USERS_DROPDOWN_FAILED = "FETCH_DASHBOARD_USERS_DROPDOWN_FAILED"

export const FETCH_DASHBOARD_TOP_PROJECTS_SUCCESS = "FETCH_DASHBOARD_TOP_PROJECTS_SUCCESS"
export const FETCH_DASHBOARD_TOP_PROJECTS_FAILED = "FETCH_DASHBOARD_TOP_PROJECTS_FAILED"

export const DASHBOARD_DATE_SELECTION_SUCCESS = "DASHBOARD_DATE_SELECTION_SUCCESS"
export const DASHBOARD_USER_SELECTION_SUCCESS = "DASHBOARD_USER_SELECTION_SUCCESS"

export const FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_SUCCESS = "FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_SUCCESS"
export const FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_FAILED = "FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_FAILED"

export const USER_DASHBOARD_PROJECT_TASK_LIST = "USER_DASHBOARD_PROJECT_TASK_LIST"
export const USER_DASHBOARD_PROJECT_CHANGE = "USER_DASHBOARD_PROJECT_CHANGE"

export const DASHBOARD_GROUP_SELECTION_UPDATE = "DASHBOARD_GROUP_SELECTION_UPDATE"

export const TEAMDASHBOARD_USER_REMOVE_SUCCESS = "TEAMDASHBOARD_USER_REMOVE_SUCCESS"
export const TEAMDASHBOARD_USER_ADDED_SUCCESS = "TEAMDASHBOARD_USER_ADDED_SUCCESS"

export const TEAMDASHBOARD_USERS_CLEAR_SUCCESS = "TEAMDASHBOARD_USERS_CLEAR_SUCCESS"
export const TEAMDASHBOARD_GROUPS_CLEAR_SUCCESS = "TEAMDASHBOARD_GROUPS_CLEAR_SUCCESS"
export const TEAMDASHBOARD_TAB_CHANGE_SUCCESS = "TEAMDASHBOARD_TAB_CHANGE_SUCCESS"

// ********* Left Nav Keys  **********\\
export const SET_LEFTNAV_SUCCESS = "SET_LEFTNAV_SUCCESS"

// *********  SINGLE USER KEYS  **********\\

export const USER_SELECTED_FROM_TEAM_DASHBOARD = "USER_SELECTED_FROM_TEAM_DASHBOARD"
export const USER_DASHBOARD_USER_SELECTION_UPDATE = "USER_DASHBOARD_USER_SELECTION_UPDATE"
export const USER_DASHBOARD_USER_SELECTION_SUCCESS = "USER_DASHBOARD_USER_SELECTION_SUCCESS"
export const USER_DASHBOARD_DATE_SELECTION_SUCCESS = "USER_DASHBOARD_DATE_SELECTION_SUCCESS"

export const FETCH_USER_DASHBOARD_USERS_DROPDOWN_SUCCESS = "FETCH_USER_DASHBOARD_USERS_DROPDOWN_SUCCESS"
export const FETCH_USER_DASHBOARD_USERS_DROPDOWN_FAILED = "FETCH_USER_DASHBOARD_USERS_DROPDOWN_FAILED"

export const FETCH_USER_DASHBOARD_COMULATIVETIME_SUCCESS = "FETCH_USER_DASHBOARD_COMULATIVETIME_SUCCESS"
export const FETCH_USER_DASHBOARD_COMULATIVETIME_FAILED = "FETCH_USER_DASHBOARD_COMULATIVETIME_FAILED"

export const FETCH_USER_DASHBOARD_USERS_TIMELINE_SUCCESS = "FETCH_USER_DASHBOARD_USERS_TIMELINE_SUCCESS"
export const FETCH_USER_DASHBOARD_USERS_TIMELINE_FAILED = "FETCH_USER_DASHBOARD_USERS_TIMELINE_FAILED"

export const FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_SUCCESS = "FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_SUCCESS"
export const FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_FAILED = "FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_FAILED"

export const FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_SUCCESS = "FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_SUCCESS"
export const FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_FAILED = "FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_FAILED"


export const FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_SUCCESS = "FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_SUCCESS"
export const FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_FAILED = "FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_FAILED"


export const FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS = "FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS"
export const FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED = "FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED"

export const FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS = "FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS"
export const FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED = "FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED"

export const FETCH_MORE_USER_WEB_APP_USAGE_SUCCESS = "FETCH_MORE_USER_WEB_APP_USAGE_SUCCESS"
export const FETCH_MORE_USER_WEB_APP_USAGE_FAILED = "FETCH_MORE_USER_WEB_APP_USAGE_FAILED"

export const FETCH_USER_WEB_APP_USAGE_SUCCESS = "FETCH_USER_WEB_APP_USAGE_SUCCESS"
export const FETCH_USER_WEB_APP_USAGE_FAILED = "FETCH_USER_WEB_APP_USAGE_FAILED"

export const FETCH_USER_WPRKED_PROJECT_TASK_SUCCESS = "FETCH_USER_WPRKED_PROJECT_TASK_SUCCESS"
export const FETCH_USER_WPRKED_PROJECT_TASK_FAILED = "FETCH_USER_WPRKED_PROJECT_TASK_FAILED"

export const FETCH_CATALOG_TITLE_DETAIL_SUCCESS = "FETCH_CATALOG_TITLE_DETAIL_SUCCESS"
export const FETCH_CATALOG_TITLE_DETAIL_FAILED = "FETCH_CATALOG_TITLE_DETAIL_FAILED"

export const FETCH_MORE_CATALOG_TITLE_DETAIL_SUCCESS = "FETCH_MORE_CATALOG_TITLE_DETAIL_SUCCESS"
export const FETCH_MORE_CATALOG_TITLE_DETAIL_FAILED = "FETCH_MORE_CATALOG_TITLE_DETAIL_FAILED"



// *********  Catalog Rating scope page  **********\\

export const FETCH_CATALOG_RATING_SCOPE_SUCCESS = "FETCH_CATALOG_RATING_SCOPE_SUCCESS"
export const FETCH_CATALOG_RATING_SCOPE_FAILED = "FETCH_CATALOG_RATING_SCOPE_FAILED"

export const FETCH_MORE_CATALOG_RATING_SCOPE_SUCCESS = "FETCH_MORE_CATALOG_RATING_SCOPE_SUCCESS"
export const FETCH_MORE_CATALOG_RATING_SCOPE_FAILED = "FETCH_MORE_CATALOG_RATING_SCOPE_FAILED"

export const FETCH_CATALOG_RATING_UESER_DROPDOWN_SUCCESS = "FETCH_CATALOG_RATING_UESER_DROPDOWN_SUCCESS"
export const FETCH_CATALOG_RATING_UESER_DROPDOWN_FAILED = "FETCH_CATALOG_RATING_UESER_DROPDOWN_FAILED"

export const CATALOG_RATING_USER_SELECTION_UPDATE = "CATALOG_RATING_USER_SELECTION_UPDATE"
export const CATALOG_RATING_GROUP_SELECTION_UPDATE = "CATALOG_RATING_GROUP_SELECTION_UPDATE"


export const FETCH_CATALOG_RATING_UPDATE_SUCCESS = "FETCH_CATALOG_RATING_UPDATE_SUCCESS"
export const FETCH_CATALOG_RATING_UPDATE_FAILED = "FETCH_CATALOG_RATING_UPDATE_FAILED"


// *********  Hours Tracked page  **********\\
export const HOURS_TRACKED_DATE_SELECTION_SUCCESS = "HOURS_TRACKED_DATE_SELECTION_SUCCESS"

export const FETCH_HOURS_TRACKED_SUCCESS = "FETCH_HOURS_TRACKED_SUCCESS"
export const FETCH_HOURS_TRACKED_FAILED = "FETCH_HOURS_TRACKED_FAILED"

export const FETCH_MORE_HOURS_TRACKED_SUCCESS = "FETCH_MORE_HOURS_TRACKED_SUCCESS"
export const FETCH_MORE_HOURS_TRACKED_FAILED = "FETCH_MORE_HOURS_TRACKED_FAILED"

// USER AND GROUP LIST
export const FETCH_HOURS_TRACKED_USERS_DROPDOWN_SUCCESS = "FETCH_HOURS_TRACKED_USERS_DROPDOWN_SUCCESS"
export const FETCH_HOURS_TRACKED_USERS_DROPDOWN_FAILED = "FETCH_HOURS_TRACKED_USERS_DROPDOWN_FAILED"
export const HOURS_TRACKED_USER_ADDED_SUCCESS = "HOURS_TRACKED_USER_ADDED_SUCCESS"
export const HOURS_TRACKED_USER_REMOVE_SUCCESS = "HOURS_TRACKED_USER_REMOVE_SUCCESS"
export const HOURS_TRACKED_USERS_CLEAR_SUCCESS = "HOURS_TRACKED_USERS_CLEAR_SUCCESS"
export const HOURS_TRACKED_GROUPS_CLEAR_SUCCESS = "HOURS_TRACKED_GROUPS_CLEAR_SUCCESS"
export const HOURS_TRACKED_GROUP_SELECTION_UPDATE = "HOURS_TRACKED_GROUP_SELECTION_UPDATE"
export const HOURS_TRACKED_USER_SELECTION_SUCCESS = "HOURS_TRACKED_USER_SELECTION_SUCCESS"
// END USER AND GROUP LIST


// *********  Activity Summary page  **********\\

export const ACTIVITY_SUMMARY_DATE_SELECTION_SUCCESS = "ACTIVITY_SUMMARY_DATE_SELECTION_SUCCESS"

export const FETCH_ACTIVITY_SUMMARY_SUCCESS = "FETCH_ACTIVITY_SUMMARY_SUCCESS"
export const FETCH_ACTIVITY_SUMMARY_FAILED = "FETCH_ACTIVITY_SUMMARY_FAILED"

export const FETCH_MORE_ACTIVITY_SUMMARY_SUCCESS = "FETCH_MORE_ACTIVITY_SUMMARY_SUCCESS"
export const FETCH_MORE_ACTIVITY_SUMMARY_FAILED = "FETCH_MORE_ACTIVITY_SUMMARY_FAILED"

// USER AND GROUP LIST
export const FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_SUCCESS = "FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_SUCCESS"
export const FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_FAILED = "FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_FAILED"
export const ACTIVITY_SUMMARY_USER_ADDED_SUCCESS = "ACTIVITY_SUMMARY_USER_ADDED_SUCCESS"
export const ACTIVITY_SUMMARY_USER_REMOVE_SUCCESS = "ACTIVITY_SUMMARY_USER_REMOVE_SUCCESS"
export const ACTIVITY_SUMMARY_USERS_CLEAR_SUCCESS = "ACTIVITY_SUMMARY_USERS_CLEAR_SUCCESS"
export const ACTIVITY_SUMMARY_GROUPS_CLEAR_SUCCESS = "ACTIVITY_SUMMARY_GROUPS_CLEAR_SUCCESS"
export const ACTIVITY_SUMMARY_GROUP_SELECTION_UPDATE = "ACTIVITY_SUMMARY_GROUP_SELECTION_UPDATE"
export const ACTIVITY_SUMMARY_USER_SELECTION_SUCCESS = "ACTIVITY_SUMMARY_USER_SELECTION_SUCCESS"
// END USER AND GROUP LIST

// *********  Webs n Apps page  **********\\
// USER AND GROUP LIST
export const FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_SUCCESS = "FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_SUCCESS"
export const FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_FAILED = "FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_FAILED"
export const WEBS_AND_APPS_USER_ADDED_SUCCESS = "WEBS_AND_APPS_USER_ADDED_SUCCESS"
export const WEBS_AND_APPS_USER_REMOVE_SUCCESS = "WEBS_AND_APPS_USER_REMOVE_SUCCESS"
export const WEBS_AND_APPS_USERS_CLEAR_SUCCESS = "WEBS_AND_APPS_USERS_CLEAR_SUCCESS"
export const WEBS_AND_APPS_GROUPS_CLEAR_SUCCESS = "WEBS_AND_APPS_GROUPS_CLEAR_SUCCESS"
export const WEBS_AND_APPS_GROUP_SELECTION_UPDATE = "WEBS_AND_APPS_GROUP_SELECTION_UPDATE"

export const FETCH_WEBS_AND_APPS_SUCCESS = "FETCH_WEBS_AND_APPS_SUCCESS"
export const FETCH_WEBS_AND_APPS_FAILED = "FETCH_WEBS_AND_APPS_FAILED"

export const FETCH_WEBS_AND_APPS_USERS_USAGE = "FETCH_WEBS_AND_APPS_USERS_USAGE"
export const FETCH_WEBS_AND_APPS_USERS_USAGE_FAILED = "FETCH_WEBS_AND_APPS_USERS_USAGE_FAILED"
export const FETCH_WEBS_AND_APPS_MORE_USERS_USAGE = "FETCH_WEBS_AND_APPS_MORE_USERS_USAGE"

export const FETCH_CATALOG_TITLE_DETAILS_SUCCESS = "FETCH_CATALOG_TITLE_DETAILS_SUCCESS"
export const FETCH_CATALOG_TITLE_DETAILS_FAILED = "FETCH_CATALOG_TITLE_DETAILS_FAILED"

export const FETCH_MORE_CATALOG_TITLE_DETAILS_SUCCESS = "FETCH_MORE_CATALOG_TITLE_DETAILS_SUCCESS"
export const FETCH_MORE_CATALOG_TITLE_DETAILS_FAILED = "FETCH_MORE_CATALOG_TITLE_DETAILS_FAILED"

// END USER AND GROUP LIST

export const FETCH_WEBS_AND_APPS_USERS_DROPDOWN_SUCCESS = "FETCH_WEBS_AND_APPS_USERS_DROPDOWN_SUCCESS"
export const FETCH_WEBS_AND_APPS_USERS_DROPDOWN_FAILED = "FETCH_WEBS_AND_APPS_USERS_DROPDOWN_FAILED"

export const WEBS_AND_APPS_USER_SELECTION_SUCCESS = "WEBS_AND_APPS_USER_SELECTION_SUCCESS"
export const WEBS_AND_APPS_USER_SELECTION_UPDATE = "WEBS_AND_APPS_USER_SELECTION_UPDATE"

export const WEBS_AND_APPS_DATE_SELECTION_SUCCESS = "WEBS_AND_APPS_DATE_SELECTION_SUCCESS"

export const FETCH_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS = "FETCH_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS"
export const FETCH_WEBS_AND_APPS_USAGE_DETAILS_FAILED = "FETCH_WEBS_AND_APPS_USAGE_DETAILS_FAILED"

export const FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS = "FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS"
export const FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_FAILED = "FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_FAILED"



// ********* Screenshot page  **********\\

export const FETCH_SCREENSHOT_USERS_DROPDOWN_SUCCESS = "FETCH_SCREENSHOT_USERS_DROPDOWN_SUCCESS"
export const FETCH_SCREENSHOT_USERS_DROPDOWN_FAILED = "FETCH_SCREENSHOT_USERS_DROPDOWN_FAILED"

export const FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_SUCCESS = "FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_SUCCESS"
export const FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_FAILED = "FETCH_SCREENSHOT_USERS_DROPDOWN_LIST_FAILED"
export const SCREENSHOT_USER_ADDED_SUCCESS = "SCREENSHOT_USER_ADDED_SUCCESS"
export const SCREENSHOT_USER_REMOVE_SUCCESS = "SCREENSHOT_USER_REMOVE_SUCCESS"
export const SCREENSHOT_USERS_CLEAR_SUCCESS = "SCREENSHOT_USERS_CLEAR_SUCCESS"
export const SCREENSHOT_GROUPS_CLEAR_SUCCESS = "SCREENSHOT_GROUPS_CLEAR_SUCCESS"

export const FETCH_SCREENSHOT_SUCCESS = "FETCH_SCREENSHOT_SUCCESS"
export const FETCH_SCREENSHOT_FAILED = "FETCH_SCREENSHOT_FAILED"

export const FETCH_MORE_SCREENSHOT_SUCCESS = "FETCH_MORE_SCREENSHOT_SUCCESS"
export const FETCH_MORE_SCREENSHOT_FAILED = "FETCH_MORE_SCREENSHOT_FAILED"

export const SCREENSHOT_DATE_SELECTION_SUCCESS = "SCREENSHOT_DATE_SELECTION_SUCCESS"
export const SCREENSHOT_USER_SELECTION_SUCCESS = "SCREENSHOT_USER_SELECTION_SUCCESS"
export const SCREENSHOT_USER_SELECTION_UPDATE = "SCREENSHOT_USER_SELECTION_UPDATE"
export const SCREENSHOT_GROUP_SELECTION_UPDATE = "SCREENSHOT_GROUP_SELECTION_UPDATE"
export const SCREENSHOT_ARCHIVE_SUCCESS = "SCREENSHOT_ARCHIVE_SUCCESS"

// *********  SUPPORT SERVICE  **********\\
export const FETCH_MAC_SUCCESS = "FETCH_MAC_SUCCESS"
export const FETCH_MAC_FAILED = "FETCH_MAC_FAILED"

export const FETCH_WINDOWS_SUCCESS = "FETCH_WINDOWS_SUCCESS"
export const FETCH_WINDOWS_FAILED = "FETCH_WINDOWS_FAILED"

export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS"
export const FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED"

export const LEFT_NAV_EXPANDED_TRUE_FALSE = "LEFT_NAV_EXPANDED_TRUE_FALSE"
export const INTERNET_AVAILABILITY = "INTERNET_AVAILABILITY"
export const IS_CLIENT_VIEW = "IS_CLIENT_VIEW"


// *********  USER SETTINGS SERVICE SERVICE  **********\\
export const FETCH_USER_SETTINGS_SUCCESS = "FETCH_USER_SETTINGS_SUCCESS"
export const FETCH_USER_SETTINGS_FAILED = "FETCH_USER_SETTINGS_FAILED"

export const FETCH_SINGLE_USER_SETTINGS_SUCCESS = "FETCH_SINGLE_USER_SETTINGS_SUCCESS"
export const FETCH_SINGLE_USER_SETTINGS_FAILED = "FETCH_SINGLE_USER_SETTINGS_FAILED"

export const UPDATE_SINGLE_USER_HOUR_RATE_SUCCESS = "UPDATE_SINGLE_USER_HOUR_RATE_SUCCESS"
export const UPDATE_SINGLE_USER_HOUR_RATE_FAILED = "UPDATE_SINGLE_USER_HOUR_RATE_FAILED"

export const UPDATE_SINGLE_USER_FULL_NAME_SUCCESS = "UPDATE_SINGLE_USER_FULL_NAME_SUCCESS"
export const UPDATE_SINGLE_USER_FULL_NAME_FAILED = "UPDATE_SINGLE_USER_FULL_NAME_FAILED"

export const FETCH_MORE_USER_SETTINGS_SUCCESS = "FETCH_MORE_USER_SETTINGS_SUCCESS"
export const FETCH_MORE_USER_SETTINGS_FAILED = "FETCH_MORE_USER_SETTINGS_FAILED"

export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS"
export const UPDATE_USER_SETTINGS_FAILED = "UPDATE_USER_SETTINGS_FAILED"


export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS"
export const UPDATE_USER_ROLE_FAILED = "UPDATE_USER_ROLE_FAILED"

export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS"
export const UPDATE_USER_STATUS_FAILED = "UPDATE_USER_STATUS_FAILED"

export const UPDATE_SHOWABLE_COLUMNS_SUCCESS = "UPDATE_SHOWABLE_COLUMNS_SUCCESS"
export const UPDATE_USER_HOUR_RATE_SUCCESS = "UPDATE_USER_HOUR_RATE_SUCCESS"

// *********  Add Project  **********\\

export const FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS = "FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS"
export const FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED = "FETCH_ADDPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED"

export const FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS = "FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS"
export const FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED = "FETCH_ADDPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED"


export const ADDPROJECT_MEMBER_ADDED_SUCCESS = "ADDPROJECT_MEMBER_ADDED_SUCCESS"
export const ADDPROJECT_MEMBER_REMOVE_SUCCESS = "ADDPROJECT_MEMBER_REMOVE_SUCCESS"

export const ADDPROJECT_ADMIN_ADDED_SUCCESS = "ADDPROJECT_ADMIN_ADDED_SUCCESS"
export const ADDPROJECT_ADMIN_REMOVE_SUCCESS = "ADDPROJECT_ADMIN_REMOVE_SUCCESS"

export const ADDPROJECT_MEMBER_ARRAY_RESET = "ADDPROJECT_MEMBER_ARRAY_RESET"
export const ADDPROJECT_ADMIN_ARRAY_RESET = "ADDPROJECT_ADMIN_ARRAY_RESET"


export const FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS = "FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_SUCCESS"
export const FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED = "FETCH_MODIFYPROJECTS_SEARCH_ADMINS_DROPDOWN_FAILED"

export const FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS = "FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_SUCCESS"
export const FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED = "FETCH_MODIFYPROJECTS_SEARCH_MEMBERS_DROPDOWN_FAILED"


export const MODIFYPROJECTS_MEMBER_ADDED_SUCCESS = "MODIFYPROJECTS_MEMBER_ADDED_SUCCESS"
export const MODIFYPROJECTS_MEMBER_REMOVE_SUCCESS = "MODIFYPROJECTS_MEMBER_REMOVE_SUCCESS"

export const MODIFYPROJECTS_ADMIN_ADDED_SUCCESS = "MODIFYPROJECTS_ADMIN_ADDED_SUCCESS"
export const MODIFYPROJECTS_ADMIN_REMOVE_SUCCESS = "MODIFYPROJECTS_ADMIN_REMOVE_SUCCESS"

export const FETCH_PROJECT_LIST_SUCCESS = "FETCH_PROJECT_LIST_SUCCESS"
export const FETCH_PROJECT_LIST_FAILED = "FETCH_PROJECT_LIST_FAILED"

export const FETCH_MORE_PROJECT_LIST_SUCCESS = "FETCH_MORE_PROJECT_LIST_SUCCESS"
export const FETCH_MORE_PROJECT_LIST_FAILED = "FETCH_MORE_PROJECT_LIST_FAILED"

export const FETCH_PROJECT_DETAILS_SUCCESS = "FETCH_PROJECT_DETAILS_SUCCESS"
export const FETCH_PROJECT_DETAILS_FAILED = "FETCH_PROJECT_DETAILS_FAILED"

export const SET_ADD_PROJECT_SUCCESS = "SET_ADD_PROJECT_SUCCESS"
export const SET_ADD_PROJECT_FAILED = "SET_ADD_PROJECT_FAILED"

export const SET_UPDATE_PROJECT_SUCCESS = "SET_UPDATE_PROJECT_SUCCESS"
export const SET_UPDATE_PROJECT_FAILED = "SET_UPDATE_PROJECT_FAILED"

export const MODIFYPROJECTS_MEMBER_POPULATED_SUCCESS = "MODIFYPROJECTS_MEMBER_POPULATED_SUCCESS"
export const MODIFYPROJECTS_ADMIN_POPULATED_SUCCESS = "MODIFYPROJECTS_ADMIN_POPULATED_SUCCESS"
export const SET_CLOSE_MODEL_SUCCESS = "SET_CLOSE_MODEL_SUCCESS"

export const SET_PROJECT_SUCCESS = "SET_PROJECT_SUCCESS"
export const SET_PROJECT_AS_ARCHIVE = "SET_PROJECT_AS_ARCHIVE"
/* Add Update Task */
export const ADDTASK_MEMBER_ADDED_SUCCESS = "ADDTASK_MEMBER_ADDED_SUCCESS"
export const ADDTASK_MEMBER_REMOVE_SUCCESS = "ADDTASK_MEMBER_REMOVE_SUCCESS"
export const ADDTASK_ADMIN_ADDED_SUCCESS = "ADDTASK_ADMIN_ADDED_SUCCESS"
export const ADDPTASK_ADMIN_REMOVE_SUCCESS = "ADDPTASK_ADMIN_REMOVE_SUCCESS"
export const SET_ADD_TASK_SUCCESS = "SET_ADD_TASK_SUCCESS"
export const SET_ADD_TASK_FAILED = "SET_ADD_TASK_FAILED"
export const FETCH_TASK_LIST_SUCCESS = "FETCH_TASK_LIST_SUCCESS"
export const FETCH_TASK_LIST_FAILED = "FETCH_TASK_LIST_FAILED"
export const FETCH_TASK_DETAILS_SUCCESS = "FETCH_TASK_DETAILS_SUCCESS"
export const FETCH_TASK_DETAILS_FAILED = "FETCH_TASK_DETAILS_FAILED"
export const FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS = "FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS"
export const FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_FAILED = "FETCH_ADDTASK_SEARCH_MEMBERS_DROPDOWN_FAILED"
export const FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS = "FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS"
export const FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_FAILED = "FETCH_ADDTASK_SEARCH_ADMINS_DROPDOWN_FAILED"
export const MODIFYTASK_MEMBER_ADDED_SUCCESS = "MODIFYTASK_MEMBER_ADDED_SUCCESS"
export const MODIFYTASK_MEMBER_REMOVE_SUCCESS = "MODIFYTASK_MEMBER_REMOVE_SUCCESS"
export const MODIFYTASK_ADMIN_ADDED_SUCCESS = "MODIFYTASK_ADMIN_ADDED_SUCCESS"
export const MODIFYTASK_ADMIN_REMOVE_SUCCESS = "MODIFYTASK_ADMIN_REMOVE_SUCCESS"
export const FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS = "FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_SUCCESS"
export const FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_FAILED = "FETCH_MODIFYTASK_SEARCH_MEMBERS_DROPDOWN_FAILED"
export const FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS = "FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_SUCCESS"
export const FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_FAILED = "FETCH_MODIFYTASK_SEARCH_ADMINS_DROPDOWN_FAILED"
export const SET_UPDATE_TASK_FAILED = "SET_UPDATE_TASK_FAILED"
export const SET_UPDATE_TASK_SUCCESS = "SET_UPDATE_TASK_SUCCESS"
export const MODIFYTASK_ADMIN_POPULATED_SUCCESS = "MODIFYTASK_ADMIN_POPULATED_SUCCESS"
export const MODIFYTASK_MEMBER_POPULATED_SUCCESS = "MODIFYTASK_MEMBER_POPULATED_SUCCESS"
export const SET_CLOSE_TASK_MODEL_SUCCESS = "SET_CLOSE_TASK_MODEL_SUCCESS"
export const SET_TASK_ARCHIVE_DB = "SET_TASK_ARCHIVE_DB"
export const FETCH_MORE_TASK_LIST_SUCCESS = "FETCH_MORE_TASK_LIST_SUCCESS"
export const FETCH_MORE_TASK_LIST_FAILED = "FETCH_MORE_TASK_LIST_FAILED"
export const TASK_MEMBER_ARRAY_RESET = "TASK_MEMBER_ARRAY_RESET"


/* group module types */

export const ADDGROUP_MEMBER_ADDED_SUCCESS = "ADDGROUP_MEMBER_ADDED_SUCCESS"
export const ADDGROUP_MEMBER_REMOVE_SUCCESS = "ADDGROUP_MEMBER_REMOVE_SUCCESS"
export const ADDGROUP_ADMIN_ADDED_SUCCESS = "ADDGROUP_ADMIN_ADDED_SUCCESS"
export const ADDGROUP_ADMIN_REMOVE_SUCCESS = "ADDGROUP_ADMIN_REMOVE_SUCCESS"
export const SET_ADD_GROUP_SUCCESS = "SET_ADD_GROUP_SUCCESS"
export const SET_ADD_GROUP_FAILED = "SET_ADD_GROUP_FAILED"
export const FETCH_GROUP_LIST_SUCCESS = "FETCH_GROUP_LIST_SUCCESS"
export const FETCH_GROUP_LIST_FAILED = "FETCH_GROUP_LIST_FAILED"
export const FETCH_GROUP_DETAILS_SUCCESS = "FETCH_GROUP_DETAILS_SUCCESS"
export const FETCH_GROUP_DETAILS_FAILED = "FETCH_GROUP_DETAILS_FAILED"
export const FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS = "FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS"
export const FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED = "FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED"
export const FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS = "FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS"
export const FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_FAILED = "FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_FAILED"
export const MODIFYGROUP_MEMBER_ADDED_SUCCESS = "MODIFYGROUP_MEMBER_ADDED_SUCCESS"
export const MODIFYGROUP_MEMBER_REMOVE_SUCCESS = "MODIFYGROUP_MEMBER_REMOVE_SUCCESS"
export const MODIFYGROUP_ADMIN_ADDED_SUCCESS = "MODIFYGROUP_ADMIN_ADDED_SUCCESS"
export const MODIFYGROUP_ADMIN_REMOVE_SUCCESS = "MODIFYGROUP_ADMIN_REMOVE_SUCCESS"
export const MODIFY_GROUP_ARCHIVE_STATUS = "MODIFY_GROUP_ARCHIVE_STATUS"
export const FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS = "FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS"
export const FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED = "FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED"
export const FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS = "FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS"
export const FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_FAILED = "FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_FAILED"
export const SET_UPDATE_GROUP_FAILED = "SET_UPDATE_GROUP_FAILED"
export const SET_UPDATE_GROUP_SUCCESS = "SET_UPDATE_GROUP_SUCCESS"
export const MODIFYGROUP_ADMIN_POPULATED_SUCCESS = "MODIFYGROUP_ADMIN_POPULATED_SUCCESS"
export const MODIFYGROUP_MEMBER_POPULATED_SUCCESS = "MODIFYGROUP_MEMBER_POPULATED_SUCCESS"
export const SET_CLOSE_GROUP_MODEL_SUCCESS = "SET_CLOSE_GROUP_MODEL_SUCCESS"
export const FETCH_MORE_GROUP_LIST_SUCCESS = "FETCH_MORE_GROUP_LIST_SUCCESS"
export const FETCH_MORE_GROUP_LIST_FAILED = "FETCH_MORE_GROUP_LIST_FAILED"

/* project costing module types */
export const FETCH_PROJECT_COST_PROJECT_SUCCESS = "FETCH_PROJECT_COST_PROJECT_SUCCESS"
export const FETCH_PROJECT_COST_PROJECT_FAILED = "FETCH_PROJECT_COST_PROJECT_FAILED"
export const FETCH_MORE_PROJECT_COST_PROJECT_SUCCESS = "FETCH_MORE_PROJECT_COST_PROJECT_SUCCESS"
export const FETCH_MORE_PROJECT_COST_PROJECT_FAILED = "FETCH_MORE_PROJECT_COST_PROJECT_FAILED"
export const FETCH_PROJECT_COST_TASK_SUCCESS = "FETCH_PROJECT_COST_TASK_SUCCESS"
export const FETCH_PROJECT_COST_TASK_FAILED = "FETCH_PROJECT_COST_TASK_FAILED"
export const FETCH_MORE_PROJECT_COST_TASK_SUCCESS = "FETCH_MORE_PROJECT_COST_TASK_SUCCESS"
export const FETCH_MORE_PROJECT_COST_TASK_FAILED = "FETCH_MORE_PROJECT_COST_TASK_FAILED"
export const FETCH_PROJECT_COST_LIST_SUCCESS = "FETCH_PROJECT_COST_LIST_SUCCESS"
export const FETCH_PROJECT_COST_LIST_FAILED = "FETCH_PROJECT_COST_LIST_FAILED"
export const PROJECT_COSTING_DATE_SELECTION_SUCCESS = "PROJECT_COSTING_DATE_SELECTION_SUCCESS"
export const PROJECT_COSTING_DATE_SELECTION_FAILED = "PROJECT_COSTING_DATE_SELECTION_FAILED"

/* Performance comparison report module types */
export const FETCH_MONTHLY_PERFORMANCE_REPORT_SUCCESS = "FETCH_MONTHLY_PERFORMANCE_REPORT_SUCCESS"
export const FETCH_MONTHLY_PERFORMANCE_REPORT_FAILED = "FETCH_MONTHLY_PERFORMANCE_REPORT_FAILED"
export const FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_SUCCESS = "FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_SUCCESS"
export const FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_FAILED = "FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_FAILED"
export const PERFORMANCE_REPORT_DATE_SELECTION_SUCCESS = "PERFORMANCE_REPORT_DATE_SELECTION_SUCCESS"
export const FETCH_USERS_PERFORMANCE_DROPDOWN_SUCCESS = "FETCH_USERS_PERFORMANCE_DROPDOWN_SUCCESS"
export const FETCH_USERS_PERFORMANCE_DROPDOWN_FAILED = "FETCH_USERS_PERFORMANCE_DROPDOWN_FAILED"
// export const REPORT_PERFORMANCE_USER_SELECTION_SUCCESS = "REPORT_PERFORMANCE_USER_SELECTION_SUCCESS"
export const PERFORMANCE_USER_DROPDOWN_SELECTION_UPDATE = "PERFORMANCE_USER_DROPDOWN_SELECTION_UPDATE"
// USER AND GROUP LIST
export const REPORT_PERFORMANCE_USER_ADDED_SUCCESS = "REPORT_PERFORMANCE_USER_ADDED_SUCCESS"
export const REPORT_PERFORMANCE_USER_REMOVE_SUCCESS = "REPORT_PERFORMANCE_USER_REMOVE_SUCCESS"
export const REPORT_PERFORMANCE_USERS_CLEAR_SUCCESS = "REPORT_PERFORMANCE_USERS_CLEAR_SUCCESS"
export const REPORT_PERFORMANCE_GROUPS_CLEAR_SUCCESS = "REPORT_PERFORMANCE_GROUPS_CLEAR_SUCCESS"
export const REPORT_PERFORMANCE_GROUP_SELECTION_UPDATE = "REPORT_PERFORMANCE_GROUP_SELECTION_UPDATE"
export const REPORT_PERFORMANCE_USER_SELECTION_SUCCESS = "REPORT_PERFORMANCE_USER_SELECTION_SUCCESS"
// END USER AND GROUP LIST

/* Comapny profile settings module types */
export const FETCH_COMANY_PROFILE_SETTING_SUCCESS = "FETCH_COMANY_PROFILE_SETTING_SUCCESS"
export const FETCH_COMANY_PROFILE_SETTING_FAILED = "FETCH_COMANY_PROFILE_SETTING_FAILED"
export const UPDATE_COMANY_PROFILE_SETTING_SUCCESS = "UPDATE_COMANY_PROFILE_SETTING_SUCCESS"
export const UPDATE_COMANY_PROFILE_SETTING_FAILED = "UPDATE_COMANY_PROFILE_SETTING_FAILED"

/* Billing Details module types */
export const FETCH_BILLING_DETAILS_SUCCESS = "FETCH_BILLING_DETAILS_SUCCESS"
export const FETCH_BILLING_DETAILS_FAILED = "FETCH_BILLING_DETAILS_FAILED"

export const FETCH_BILLING_UPGRADE_DETAILS_SUCCESS = "FETCH_BILLING_UPGRADE_DETAILS_SUCCESS"
export const FETCH_BILLING_UPGRADE_DETAILS_FAILED = "FETCH_BILLING_UPGRADE_DETAILS_FAILED"

/* User pay role module types */
export const USER_PAY_ROLE__DATE_SELECTION_SUCCESS = "USER_PAY_ROLE__DATE_SELECTION_SUCCESS"
export const FETCH_USER_PAY_ROLE_DETAILS_SUCCESS = "FETCH_USER_PAY_ROLE_DETAILS_SUCCESS"
export const FETCH_USER_PAY_ROLE_DETAILS_FAILED = "FETCH_USER_PAY_ROLE_DETAILS_FAILED"
export const FETCH_MORE_USER_PAY_ROLE_SUCCESS = "FETCH_MORE_USER_PAY_ROLE_SUCCESS"
export const FETCH_MORE_USER_PAY_ROLE_FAILED = "FETCH_MORE_USER_PAY_ROLE_FAILED"
export const UPDATE_USER_PAYROLE_CURRENCY = "UPDATE_USER_PAYROLE_CURRENCY"
export const UPDATE_USER_ADJUSTMENT_PRICE = "UPDATE_USER_ADJUSTMENT_PRICE"
export const UPDATE_USER_HOUR_RATE = "UPDATE_USER_HOUR_RATE"

export const FETCH_MORE_PAYROLL_CLIENT_FAILED = "FETCH_MORE_PAYROLL_CLIENT_FAILED"
export const FETCH_MORE_PAYROLL_CLIENT_SUCCESS = "FETCH_MORE_PAYROLL_CLIENT_SUCCESS"
export const FETCH_PAYROLL_CLIENT_SUCCESS = "FETCH_PAYROLL_CLIENT_SUCCESS"
export const FETCH_PAYROLL_CLIENT_FAILED = "FETCH_PAYROLL_CLIENT_FAILED"

export const FETCH_PAYROLL_USERS_DROPDOWN_SUCCESS = "FETCH_PAYROLL_USERS_DROPDOWN_SUCCESS"
export const FETCH_PAYROLL_USERS_DROPDOWN_FAILED = "FETCH_PAYROLL_USERS_DROPDOWN_FAILED"

export const PAYROLL_USER_SELECTION_SUCCESS = "PAYROLL_USER_SELECTION_SUCCESS"
export const PAYROLL_GROUP_SELECTION_UPDATE = "PAYROLL_GROUP_SELECTION_UPDATE"
export const PAYROLL_CLIENT_SELECTION_UPDATE = "PAYROLL_CLIENT_SELECTION_UPDATE"
export const PAYROLL_USER_ADDED_SUCCESS = "PAYROLL_USER_ADDED_SUCCESS"
export const PAYROLL_USER_REMOVE_SUCCESS = "PAYROLL_USER_REMOVE_SUCCESS"
export const PAYROLL_USERS_CLEAR_SUCCESS = "PAYROLL_USERS_CLEAR_SUCCESS"
export const PAYROLL_GROUPS_CLEAR_SUCCESS = "PAYROLL_GROUPS_CLEAR_SUCCESS"
export const PAYROLL_CLIENTS_CLEAR_SUCCESS = "PAYROLL_CLIENTS_CLEAR_SUCCESS"


/* Project Progress Constant */
export const FETCH_ALL_ORGANIZATION_PROJECT_SUCCESS = "FETCH_ALL_ORGANIZATION_PROJECT_SUCCESS"
export const FETCH_ALL_ORGANIZATION_PROJECT_FAILED = "FETCH_ALL_ORGANIZATION_PROJECT_FAILED"

export const FETCH_MORE_ORGANIZATION_PROJECT_SUCCESS = "FETCH_MORE_ORGANIZATION_PROJECT_SUCCESS"
export const FETCH_MORE_ORGANIZATION_PROJECT_FAILED = "FETCH_MORE_ORGANIZATION_PROJECT_FAILED"

export const PROJECT_PROGRESS_DATE_SELECTION_SUCCESS = "PROJECT_PROGRESS_DATE_SELECTION_SUCCESS"

export const FETCH_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS = "FETCH_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS"
export const FETCH_ALL_ORGANIZATION_PROJECT_TASK_FAILED = "FETCH_ALL_ORGANIZATION_PROJECT_TASK_FAILED"

export const FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS = "FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_SUCCESS"
export const FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_FAILED = "FETCH_MORE_ALL_ORGANIZATION_PROJECT_TASK_FAILED"

export const FETCH_ALL__PROJECT_TASK_USERS_SUCCESS = "FETCH_ALL__PROJECT_TASK_USERS_SUCCESS"
export const FETCH_ALL__PROJECT_TASK_USERS_FAILED = "FETCH_ALL__PROJECT_TASK_USERS_FAILED"

export const FETCH_MORE_ALL__PROJECT_TASK_USERS_SUCCESS = "FETCH_MORE_ALL__PROJECT_TASK_USERS_SUCCESS"
export const FETCH_MORE_ALL__PROJECT_TASK_USERS_FAILED = "FETCH_MORE_ALL__PROJECT_TASK_USERS_FAILED"

export const PROJECT_PROGRESS_USERS_DROPDOWN_SUCCESS = "PROJECT_PROGRESS_USERS_DROPDOWN_SUCCESS"
export const PROJECT_PROGRESS_USERS_DROPDOWN_FAILED = "PROJECT_PROGRESS_USERS_DROPDOWN_FAILED"

export const PROJECT_PROGRESS_DASHBOARD_SELECTED_USER = "PROJECT_PROGRESS_DASHBOARD_SELECTED_USER"

export const PROJECT_PROGRESS_USER_SELECTION_SUCCESS = "PROJECT_PROGRESS_USER_SELECTION_SUCCESS"
export const PROJECT_PROGRESS_USER_ADDED_SUCCESS = "PROJECT_PROGRESS_USER_ADDED_SUCCESS"
export const PROJECT_PROGRESS_USER_REMOVE_SUCCESS = "PROJECT_PROGRESS_USER_REMOVE_SUCCESS"
export const PROJECT_PROGRESS_USER_CLEAR_SUCCESS = "PROJECT_PROGRESS_USER_CLEAR_SUCCESS"

export const PROJECT_PROGRESS_GROUP_SELECTION_SUCCESS = "PROJECT_PROGRESS_GROUP_SELECTION_SUCCESS"
export const PROJECT_PROGRESS_GROUPS_CLEAR_SUCCESS = "PROJECT_PROGRESS_USER_REMOVE_SUCCESS"

// ************************* CLIENT PAGE KEYS *********************  //
export const FETCH_CLIENT_SETTINGS_SUCCESS = "FETCH_CLIENT_SETTINGS_SUCCESS"
export const FETCH_CLIENT_SETTINGS_FAILED = "FETCH_CLIENT_SETTINGS_FAILED"
export const FETCH_MORE_CLIENT_SETTINGS_FAILED = "FETCH_MORE_CLIENT_SETTINGS_FAILED"
export const FETCH_MORE_CLIENT_SETTINGS_SUCCESS = "FETCH_MORE_CLIENT_SETTINGS_SUCCESS"
export const UPDATE_CLIENT_STATUS_SUCCESS = "UPDATE_CLIENT_STATUS_SUCCESS"
export const UPDATE_CLIENT_STATUS_FAILED = "UPDATE_CLIENT_STATUS_FAILED"
export const SEARCH_USERS_FOR_CLIENT_SUCCESS = "SEARCH_USERS_FOR_CLIENT_SUCCESS"
export const SEARCH_USERS_FOR_CLIENT_FAILED = "SEARCH_USERS_FOR_CLIENT_FAILED"
export const ADD_USER_TO_CLIENT_SUCCESS = "ADD_USER_TO_CLIENT_SUCCESS"
export const ADD_USER_TO_CLIENT_FAILED = "ADD_USER_TO_CLIENT_FAILED"
export const FETCH_CLIENT_TEAM_SUCCESS = "FETCH_CLIENT_TEAM_SUCCESS"
export const FETCH_CLIENT_TEAM_FAILED = "FETCH_CLIENT_TEAM_FAILED"
export const FETCH_MORE_CLIENT_TEAM_SUCCESS = "FETCH_MORE_CLIENT_TEAM_SUCCESS"
export const FETCH_MORE_CLIENT_TEAM_FAILED = "FETCH_MORE_CLIENT_TEAM_FAILED"
export const CLEARE_SEARCH_USER_SUCCESS = "CLEARE_SEARCH_USER_SUCCESS"
export const FETCH_CLIENT_TOKEN_SUCCESS = "FETCH_CLIENT_TOKEN_SUCCESS"
export const FETCH_CLIENT_TOKEN_FAILED = "FETCH_CLIENT_TOKEN_FAILED"

/* ATTENDANCE REPORT types */
export const ATTENDANCE_REPORT_DATE_SELECTION_SUCCESS = "ATTENDANCE_REPORT_DATE_SELECTION_SUCCESS"
export const FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_SUCCESS = "FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_SUCCESS"
export const FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_FAILED = "FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_FAILED"
export const ATTENDANCE_REPORT_USER_ADDED_SUCCESS = "ATTENDANCE_REPORT_USER_ADDED_SUCCESS"
export const ATTENDANCE_REPORT_USER_REMOVE_SUCCESS = "ATTENDANCE_REPORT_USER_REMOVE_SUCCESS"
export const ATTENDANCE_REPORT_USERS_CLEAR_SUCCESS = "ATTENDANCE_REPORT_USERS_CLEAR_SUCCESS"
export const ATTENDANCE_REPORT_USER_SELECTION_SUCCESS = "ATTENDANCE_REPORT_USER_SELECTION_SUCCESS"
export const ATTENDANCE_REPORT_GROUP_SELECTION_UPDATE = "ATTENDANCE_REPORT_GROUP_SELECTION_UPDATE"
export const ATTENDANCE_REPORT_GROUPS_CLEAR_SUCCESS = "ATTENDANCE_REPORT_GROUPS_CLEAR_SUCCESS"
export const FETCH_ATTENDANCE_REPORT_RECORD_SUCCESS = "FETCH_ATTENDANCE_REPORT_RECORD_SUCCESS"
export const FETCH_ATTENDANCE_REPORT_RECORD_FAILED = "FETCH_ATTENDANCE_REPORT_RECORD_FAILED"
export const FETCH_MORE_ATTENDANCE_REPORT_RECORD_SUCCESS = "FETCH_MORE_ATTENDANCE_REPORT_RECORD_SUCCESS"
export const FETCH_MORE_ATTENDANCE_REPORT_RECORD_FAILED = "FETCH_MORE_ATTENDANCE_REPORT_RECORD_FAILED"
export const FETCH_COMANY_ATTENDANCE_SETTING_SUCCESS = "FETCH_COMANY_ATTENDANCE_SETTING_SUCCESS"
export const FETCH_COMANY_ATTENDANCE_SETTING_FAILED = "FETCH_COMANY_ATTENDANCE_SETTING_FAILED"
export const UPDATE_COMANY_ATTENDANCE_SETTING_SUCCESS = "UPDATE_COMANY_ATTENDANCE_SETTING_SUCCESS"

/* Email notification module types */
export const EMAIL_NOTIFICATION_DATE_SELECTION_SUCCESS = "EMAIL_NOTIFICATION_DATE_SELECTION_SUCCESS"

export const FETCH_EMAIL_NOTIFICATION_SETTINGS_SUCCESS = "FETCH_EMAIL_NOTIFICATION_SETTINGS_SUCCESS"
export const FETCH_EMAIL_NOTIFICATION_SETTINGS_FAILED = "FETCH_EMAIL_NOTIFICATION_SETTINGS_FAILED"
export const FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS = "FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS"
export const FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_FAILED = "FETCH_MORE_EMAIL_NOTIFICATION_SETTINGS_FAILED"

export const GET_EMAIL_NOTIFICATION_USERS_GROUPS_SUCCESS = "GET_EMAIL_NOTIFICATION_USERS_GROUPS_SUCCESS"
export const GET_EMAIL_NOTIFICATION_USERS_GROUPS_FAILED = "GET_EMAIL_NOTIFICATION_USERS_GROUPS_FAILED"

export const ADD_SELECTED_USERS_EMAIL_NOTIFICATION = "ADD_SELECTED_USERS_EMAIL_NOTIFICATION"
export const REMOVE_SELECTED_USERS_EMAIL_NOTIFICATION = "REMOVE_SELECTED_USERS_EMAIL_NOTIFICATION"
export const ADD_NEW_SELECTED_USER_EMAIL_NOTIFICATION = "ADD_NEW_SELECTED_USER_EMAIL_NOTIFICATION"

export const ADD_SELECTED_GROUPS_EMAIL_NOTIFICATION = "ADD_SELECTED_GROUPS_EMAIL_NOTIFICATION"
export const REMOVE_SELECTED_GROUPS_EMAIL_NOTIFICATION = "REMOVE_SELECTED_GROUPS_EMAIL_NOTIFICATION"
export const ADD_NEW_SELECTED_GROUP_EMAIL_NOTIFICATION = "ADD_NEW_SELECTED_GROUP_EMAIL_NOTIFICATION"

export const UPDATE_SINGLE_EMAIL_NOTIFICATION_STATUS = "UPDATE_SINGLE_EMAIL_NOTIFICATION_STATUS"
export const RESET_SELECTED_USERS_AND_GROUPS_EMAIL_NOTIFICATION = "RESET_SELECTED_USERS_AND_GROUPS_EMAIL_NOTIFICATION"

// USER PROFITIBILITY REPORT 

export const USER_PROFITIBILITY_DATE_SELECTION_SUCCESS = "USER_PROFITIBILITY_DATE_SELECTION_SUCCESS"
export const FETCH_USERS_PROFITIBILITY_SUCCESS = "FETCH_USERS_PROFITIBILITY_SUCCESS"
export const FETCH_USERS_PROFITIBILITY_FAILED = "FETCH_USERS_PROFITIBILITY_FAILED"
export const FETCH_MORE_USERS_PROFITIBILITY_SUCCESS = "FETCH_MORE_USERS_PROFITIBILITY_SUCCESS"
export const FETCH_MORE_USERS_PROFITIBILITY_FAILED = "FETCH_MORE_USERS_PROFITIBILITY_FAILED"
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS"
export const FETCH_CLIENTS_FAILED = "FETCH_CLIENTS_FAILED"
export const FETCH_MORE_CLIENTS_SUCCESS = "FETCH_MORE_CLIENTS_SUCCESS"
export const FETCH_MORE_CLIENTS_FAILED = "FETCH_MORE_CLIENTS_FAILED"
export const FETCH_CLIENT_PROJECTS_SUCCESS = "FETCH_CLIENT_PROJECTS_SUCCESS"
export const FETCH_CLIENT_PROJECTS_FAILED = "FETCH_CLIENT_PROJECTS_FAILED"
export const FETCH_MORE_CLIENT_PROJECTS_SUCCESS = "FETCH_MORE_CLIENT_PROJECTS_SUCCESS"
export const FETCH_MORE_CLIENT_PROJECTS_FAILED = "FETCH_MORE_CLIENT_PROJECTS_FAILED"

// USER SETTINGS PAGE USER SEARH COMPONENT

export const SEARCH_USERS_FOR_USER_SETTINGS_SUCCESS = "SEARCH_USERS_FOR_USER_SETTINGS_SUCCESS"
export const SEARCH_USERS_FOR_USER_SETTINGS_FAILED = "SEARCH_USERS_FOR_USER_SETTINGS_FAILED"
export const ADD_USER_TO_SETTINGS_SUCCESS = "ADD_USER_TO_SETTINGS_SUCCESS"
export const  ADD_USER_TO_SETTINGS_FAILED = "ADD_USER_TO_SETTINGS_FAILED"
export const CLEARE_SEARCH_USERS_SUCCESS = "CLEARE_SEARCH_USERS_SUCCESS"
export const CLEARE_SEARCH_SELECTED_USERS_SUCCESS = "CLEARE_SEARCH_SELECTED_USERS_SUCCESS"

