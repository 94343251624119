import {
  FETCH_CLIENT_SETTINGS_SUCCESS,
  FETCH_CLIENT_SETTINGS_FAILED,
  FETCH_MORE_CLIENT_SETTINGS_FAILED,
  FETCH_MORE_CLIENT_SETTINGS_SUCCESS,
  UPDATE_CLIENT_STATUS_SUCCESS,
  UPDATE_CLIENT_STATUS_FAILED,
  SEARCH_USERS_FOR_CLIENT_SUCCESS,
  SEARCH_USERS_FOR_CLIENT_FAILED,
  ADD_USER_TO_CLIENT_SUCCESS,
  ADD_USER_TO_CLIENT_FAILED,
  FETCH_CLIENT_TEAM_SUCCESS,
  FETCH_CLIENT_TEAM_FAILED,
  FETCH_MORE_CLIENT_TEAM_FAILED,
  FETCH_MORE_CLIENT_TEAM_SUCCESS,
  CLEARE_SEARCH_USER_SUCCESS,
  FETCH_CLIENT_TOKEN_SUCCESS,
  FETCH_CLIENT_TOKEN_FAILED,

} from "../actions/Types";

let initialState = {
  clientSettings: { list: [], loadMore: false },
  clientTeam: { list: [], loadMore: false },
  enabledDisabledClients: { list: [] },
  searchedUsers: [],
  userAddedRemoved: { "message": "" },
  clientToken: {
    "accessToken": "",
    "userId": "",
    "scopedOrganizationId": "",
    "scopedUserRole": "",
    "expiry": "",
    "organizationDetails": {
      "id": "",
      "name": "",
      "orgStatus": "",
      "userRole": [],
      "userStatus": "",
      "timeZone": "",
      "timeZoneOffset": ""
    },
    "userDetails": {
      "countryCode": "",
      "dateCreated": "",
      "dateModified": "",
      "email": "",
      "id": "",
      "name": "",
      "phone": "",
      "status": "",
      "profileUrl": null,
      "timeZone": "",
      "timeZoneOffset": ""
    }
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CLIENT_SETTINGS_SUCCESS:
      let newClientSettings = payload.clientSettings;
      newClientSettings.forEach((i) => {
        i["loading"] = false;
      });
      return {
        ...state,
        clientSettings: { list: newClientSettings, loadMore: payload.loadMore },
      };

    case FETCH_CLIENT_SETTINGS_FAILED:
      return {
        ...state,
        clientSettings: { list: [], loadMore: false },
      };


    case FETCH_MORE_CLIENT_SETTINGS_SUCCESS:
      let us = state.clientSettings;
      let con = us.list.concat(payload.clientSettings);
      con.forEach((i) => {
        i["loading"] = false;
      });
      return {
        ...state,
        clientSettings: { list: con, loadMore: payload.loadMore },
      };

    case FETCH_MORE_CLIENT_SETTINGS_FAILED:
      return {
        ...state,
        clientSettings: { list: [], loadMore: false },
      };


    case UPDATE_CLIENT_STATUS_SUCCESS:
      let newclientEDSettings = state.clientSettings;
      if (payload.clientStatus.status === 200) {
        newclientEDSettings.list.forEach((element) => {
          if (element.id === payload.clientId) {
            element.status = payload.uzerStatus;
            element.loading = false;
          }
        });
      } else {
        newclientEDSettings.list.forEach((element) => {
          if (element.clientId === payload.clientId) {
            element.loading = false;
          }
        });
      }

      return {
        ...state,
        clientSettings: newclientEDSettings,
      };

    case UPDATE_CLIENT_STATUS_FAILED:
      return {
        ...state,
        clientSettings: { list: [], loadMore: false },
      };


    case FETCH_CLIENT_TEAM_SUCCESS:
      let newUserSettings = payload.clientTeam;
      newUserSettings.forEach((i) => {
        i["loading"] = false;
      });
      return {
        ...state,
        clientTeam: { list: newUserSettings, loadMore: payload.loadMore },
      };

    case FETCH_CLIENT_TEAM_FAILED:
      return {
        ...state,
        clientTeam: { list: [], loadMore: false },
      };


    case FETCH_MORE_CLIENT_TEAM_SUCCESS:
      let ct = state.clientTeam;
      let conct = ct.list.concat(payload.clientTeam);
      conct.forEach((i) => {
        i["loading"] = false;
      });
      return {
        ...state,
        clientTeam: { list: conct, loadMore: payload.loadMore },
      };

    case FETCH_MORE_CLIENT_TEAM_FAILED:
      return {
        ...state,
        clientTeam: { list: [], loadMore: false },
      };


    case SEARCH_USERS_FOR_CLIENT_SUCCESS:
      var selected = state.clientTeam.list;
      var gotten = payload.searchedUsers;
      const filteredAddAdmins = gotten.orgUsers.filter(item => !selected.some(a => a.userId === item.id));
      return {
        ...state,
        searchedUsers: gotten.orgUsers,
        // searchedUsers: filteredAddAdmins,
      };

    case SEARCH_USERS_FOR_CLIENT_FAILED:
      return {
        ...state,
      };


    case ADD_USER_TO_CLIENT_SUCCESS:
      return {
        ...state,
        userAddedRemoved: payload.addRemoveUser,
      };

    case ADD_USER_TO_CLIENT_FAILED:
      return {
        ...state,
        userAddedRemoved: { "message": "" }
      };

    case CLEARE_SEARCH_USER_SUCCESS:
      return {
        ...state,
        searchedUsers: []
      };

    case FETCH_CLIENT_TOKEN_SUCCESS:
      return {
        ...state,
        clientToken: payload.clientToken,
      };

    case FETCH_CLIENT_TOKEN_FAILED:
      return {
        ...state,
        clientToken: {
          "accessToken": "",
          "userId": "",
          "scopedOrganizationId": "",
          "scopedUserRole": "",
          "expiry": "",
          "organizationDetails": {
            "id": "",
            "name": "",
            "orgStatus": "",
            "userRole": [],
            "userStatus": "",
            "timeZone": "",
            "timeZoneOffset": ""
          },
          "userDetails": {
            "countryCode": "",
            "dateCreated": "",
            "dateModified": "",
            "email": "",
            "id": "",
            "name": "",
            "phone": "",
            "status": "",
            "profileUrl": null,
            "timeZone": "",
            "timeZoneOffset": ""
          }
        }
      };


    default:
      return state;
  }
}
