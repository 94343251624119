import {
    SET_MESSAGE,
    FETCH_DASHBOARD_SUCCESS,
    FETCH_DASHBOARD_FAILED,
    FETCH_MAC_SUCCESS,
    FETCH_MAC_FAILED,
    FETCH_WINDOWS_SUCCESS,
    FETCH_WINDOWS_FAILED,
    INTERNET_AVAILABILITY,

} from "./Types";
import SupportService from "../../services/SupportService";


export const fetchMacLink = () => (dispatch) => {

    var data = {
        "appName": "mac"
    }
    return SupportService.fetchMacLink(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_MAC_SUCCESS,
                    payload: { macLink: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {

                dispatch({
                    type: FETCH_MAC_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_MAC_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};


export const fetchWindowsLink = () => (dispatch) => {

    var data = {
        "appName": "windows"
    }
    return SupportService.fetchWindowsLink(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WINDOWS_SUCCESS,
                    payload: { windowsLink: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WINDOWS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_WINDOWS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};


export const fetchReactVersion = () => (dispatch) => {

    var data = {
        "appName": "dashboard"
    }
    return SupportService.fetchReactVersion(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_SUCCESS,
                    payload: { reactLink: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            console.log("err: ", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
