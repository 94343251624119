
import React, { useEffect, useState, useRef } from 'react'
import { Button, Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, connect } from "react-redux";
import { updateCompanyProfileSettings } from "../../redux/actions/CompanyProfileAction";
import { Link } from 'react-router-dom';
import './style.css';
import { allowedBasedOnRole } from '../../common/GlobalFunctions';
import { ADMIN, OWNER } from '../../utils/Constants';
import ResultMessage from '../ResultMessage';
import CompanyTimeZone from '../CompanyTimeZone';
import Loading from '../loading';


const CompanyProfile = (props) => {
  const { show, handleClose, companyProfileSetting, resMessage, companySettingsLoading, companyRecord } = props
  // console.log('companyProfileSetting',companyProfileSetting.timeZone)
  const dispatch = useDispatch();
  const [company_name, setCompanyName] = useState(companyRecord.name);
  const [companySettingsUpdateLoading, setCompanySettingsUpdateLoading] = useState(false);
  const [warning, setWarning] = useState("");
  const [resultClass, setResultClass] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const screenShotsidleTimeRef = useRef(undefined);
  const idleTimeRef = useRef(undefined);
  const silentMonitoringRef = useRef(undefined);
  const timeZoneRef = useRef(undefined);
  const defaultCurrencyRef = useRef(undefined);
  const [timeZoneValue, setTimeZoneValue] = useState("UTC");
  const [OffSetValue, setOffSetValue] = useState("+00:00");

  const [key, setKey] = useState('');
  const configRef = useRef();

  // console.log(companyProfileSetting)
  useEffect(() => {
    setTimeZoneValue(companyProfileSetting.timeZone)
    setOffSetValue(companyProfileSetting.timeZoneOffset)
  }, [companyProfileSetting.organizationId.length])

  const companyNameOnChange = (e) => {
    setCompanyName(e.target.value);
  };

  useEffect(() => {
    key === "configuration" && configRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    setTimeout(() => {
      setKey("");
    }, 1000);
  }, [key])


  const updateCompanySettings = (event) => {
    event.preventDefault();
    const screenShots_val = screenShotsidleTimeRef.current.value;
    const idleTime_val = idleTimeRef.current.value;
    const silentMonitoringRef_val = silentMonitoringRef.current.value;

    setCompanySettingsUpdateLoading(true);
    dispatch(updateCompanyProfileSettings(companyRecord.id, company_name, screenShots_val, idleTime_val, silentMonitoringRef_val, OffSetValue, timeZoneValue))
      .then(() => {
        setCompanySettingsUpdateLoading(false);
        setWarning("Record update successfully.");
        setResultClass("success")
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        console.log("error: ", error);
        setCompanySettingsUpdateLoading(false);
      });
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className="company-modal">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>
            {/* <h3 className='modal-heading'>Company Profile</h3> */}
            <div className="head name-left d-flex align-items-center">

              <div className="d-block ms-2 ms-md-2 margin py-3 modal-heading">
                <h3 className="m-0 text-black modal-heading">Organization Profile</h3>
                {/* <p className="m-0 fs-16 text-black">Update your organization details here.</p> */}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        {Object.keys(companyProfileSetting).length > 0 && companySettingsLoading ?
          <Modal.Body className='p-0'>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              onSelect={(k) => { setKey(k); }}
              className="mb-3"
            >
              <Tab eventKey="profile" title="Organization details" className='active'>
                <div className='overview-box mt-4 ' >

                  <h3 className={"heading-company organization-details" + (key === "profile" ? " active" : " ")}>Organization Details</h3>

                  <Form onSubmit={updateCompanySettings}>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Organization Name</Form.Label>
                          <Form.Control type="text" value={company_name} onChange={companyNameOnChange} disabled={!allowedBasedOnRole([OWNER], companyRecord.userRole)} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Owner Name</Form.Label>
                          <Form.Control type="text" value={companyProfileSetting.ownerName} disabled={true} />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control value={companyProfileSetting.ownerEmail} disabled={true} />
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* {companySettingsUpdateLoading ?
                      <div className="text-center loader my-4 pt-1">
                        <Loading/>
                      </div> :
                      companyRecord.userRole[0] === "Owner" ?
                        <>
                          <Form.Group className=" save-btn-box">
                            <Button className='cancel' variant="secondary" onClick={handleClose}>
                              cancel
                            </Button>
                            <Button className='save' variant="primary" type="submit" >
                              save
                            </Button>
                          </Form.Group>
                        </> : ''
                    }
                    {showMessage && <div className='mt-3'><ResultMessage messageType={resultClass} message={warning} /></div>} */}
                  </Form>

                </div>

                <div className='overview-box pb-4 panel' id='configuration' ref={configRef}>
                  <h3 className={"heading-company configuration" + (key === "configuration" ? " active" : " ")}>Configuration</h3>
                  <Form onSubmit={updateCompanySettings}>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Take Screenshots every:</Form.Label>
                          <Form.Select defaultValue={companyProfileSetting.settings.screenshotCaptureTime} disabled=
                            {!allowedBasedOnRole([ADMIN, OWNER], companyRecord.userRole)} ref={screenShotsidleTimeRef}>
                            <option value='2'>2 minutes</option>
                            <option value='3'>3 minutes</option>
                            <option value='5'>5 minutes</option>
                            <option value='10'>10 minutes</option>
                            <option value='15'>15 minutes</option>
                            <option value='20'>20 minutes</option>
                            <option value='25'>25 minutes</option>
                            <option value='30'>30 minutes</option>
                            <option value='Never'>Never</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Timeout After</Form.Label>
                          <Form.Select defaultValue={companyProfileSetting.settings.idleTime} disabled={!allowedBasedOnRole([ADMIN, OWNER], companyRecord.userRole)} ref={idleTimeRef}>
                            <option value='2'>2 minutes</option>
                            <option value='3'>3 minutes</option>
                            <option value='5'>5 minutes</option>
                            <option value='10'>10 minutes</option>
                            <option value='15'>15 minutes</option>
                            <option value='20'>20 minutes</option>
                            <option value='25'>25 minutes</option>
                            <option value='30'>30 minutes</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Silent Monitoring</Form.Label>
                          <Form.Select defaultValue={companyProfileSetting.settings.silentMonitoring} disabled={!allowedBasedOnRole([ADMIN, OWNER], companyRecord.userRole)} ref={silentMonitoringRef}>
                            <option value='true'>On</option>
                            <option value='false'>Off</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <label>Time Zone</label>
                        <CompanyTimeZone defaultTimeZone={companyProfileSetting.timeZone} currentUserRole={companyRecord.userRole}
                          timeZoneRef={timeZoneRef} setTimeZoneValue={setTimeZoneValue}
                          setOffSetValue={setOffSetValue} />
                      </Col>
                    </Row>

                    <Row>

                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Currency</Form.Label>
                          <Form.Select defaultValue={companyProfileSetting.currency}
                            disabled={!allowedBasedOnRole([ADMIN, OWNER],
                              companyRecord.userRole)} ref={defaultCurrencyRef}>
                            <option value='USD'>USD</option>
                            <option value='Euro'>Euro</option>
                            <option value='Pound'>Pound</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>

                      </Col>
                    </Row>

                    {showMessage && <div className='mt-3'><ResultMessage messageType={resultClass} message={warning} /></div>}

                    {companySettingsUpdateLoading ?
                      <div className="text-center loader my-4 pt-1">
                        <Loading />
                      </div> :
                      companyRecord.userRole[0] === "Owner" || companyRecord.userRole[0] === "Admin" ?
                        <>
                          <Form.Group className=" save-btn-box mt-4">
                            <Button className='cancel' variant="secondary" onClick={handleClose}>
                              Cancel
                            </Button>
                            <Button className='save' variant="primary" type="submit" >
                              Save
                            </Button>
                          </Form.Group>
                        </> : ''
                    }

                  </Form>
                </div>


              </Tab>
              <Tab eventKey="configuration" title="Configuration">
                <div className='overview-box px-4'>

                </div>
              </Tab>
            </Tabs>
          </Modal.Body> :
          <div className="text-center loader my-4 pt-1">
            <Loading />
          </div>
        }
      </Modal >
    </>
  );
}
const mapStateToProps = state => ({
  companyProfileSetting: state.CompanyProfileReducer.companySettingsData,
  resMessage: state.CompanyProfileReducer.resMessage,
});
export default connect(mapStateToProps)(CompanyProfile);
