import {
  SET_MESSAGE,
  FETCH_LOGGED_IN_USER_DETAILS_SUCCESS,
  FETCH_LOGGED_IN_USER_DETAILS_FAILED,
  FETCH_USER_COMPANIES_SUCCESS,
  FETCH_USER_COMPANIES_FAILED,
  FETCH_ADD_NEW_USER_FAILED,
  FETCH_ADD_NEW_USER_SUCCESS,
  UPDATE_PROFILE_SETTINGS_SUCCESS,
  UPDATE_PROFILE_SETTINGS_FAILED,
  INTERNET_AVAILABILITY,
} from "./Types";
import UserService from "../../services/UserService";

export const fetchLogedInUserProfile = () => (dispatch) => {
  return UserService.fetchProfile().then(
    (data) => {
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: FETCH_LOGGED_IN_USER_DETAILS_SUCCESS,
          payload: { userProfile: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: FETCH_LOGGED_IN_USER_DETAILS_FAILED,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: FETCH_LOGGED_IN_USER_DETAILS_FAILED,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const fetchUserCompanies = () => (dispatch) => {
  return UserService.fetchUserCompanies().then(
    (data) => {
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: FETCH_USER_COMPANIES_SUCCESS,
          payload: { userCompanies: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: FETCH_USER_COMPANIES_FAILED,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: FETCH_USER_COMPANIES_FAILED,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const fetchAddNewUser = (users, orgId) => (dispatch) => {
  var data = {
    "users": users,
    "organizationId": orgId,
  }
  return UserService.fetchAddNewUser(data).then(
    (data) => {
      // console.log('action', data)
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: FETCH_ADD_NEW_USER_SUCCESS,
          payload: { addNewUsers: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: FETCH_USER_COMPANIES_FAILED,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: FETCH_ADD_NEW_USER_FAILED,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateProfileSettings = (name, password, OffSetValue, timeZoneValue) => (dispatch) => {
  var data = {
    "name": name,
    "password": password,
    "timeZoneOffset": OffSetValue,
    "timeZone": timeZoneValue,
  }
  // console.log("payload: ", data);
  return UserService.updateProfileSettings(data).then(
    (data) => {
      if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
        dispatch({
          type: UPDATE_PROFILE_SETTINGS_SUCCESS,
          payload: { updatedProfile: data },
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: true },
        });
        return Promise.resolve();
      }
      else if (data === "ERR_NETWORK") {
        dispatch({
          type: UPDATE_PROFILE_SETTINGS_FAILED,
        });
        dispatch({
          type: INTERNET_AVAILABILITY,
          payload: { internetAvailable: false },
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_PROFILE_SETTINGS_FAILED,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const addNewCompanyAction = (inputCompany) => (dispatch) => {
  var data = {
    "organizationName": inputCompany,
  }
  return UserService.addNewCompanyService(data).then(
    (data) => {
      if (data.request.status === 200) {
        return data.data;
      } else {
        return data.response.data;
      }
    },
    (error) => {
      return error;
    }
  );
};
