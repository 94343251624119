import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL_PHP } from "../utils/Constants";


const fetchWebnAppsUsersDropDown = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/org/usergroup", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchWebnAppsUsageDetails = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/user-dashboard/userwebappusage", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchWebnAppsUsersGroups = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/usergroupsearch", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};

const fetchWebnAppsCatalogUsers = (requestData) => {
  return axios
    .post(API_URL_PHP + "api/v1/user/webnapp-users", requestData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        return error.code;
      }
      console.log("Error:  ", error);
    })
};


export default {
  fetchWebnAppsUsersDropDown,
  fetchWebnAppsUsageDetails,
  fetchWebnAppsUsersGroups,
  fetchWebnAppsCatalogUsers
};