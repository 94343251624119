import './style.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { ADMIN, GROUPADMIN, MEMBER, OWNER, CLIENT, PREMIUM, ULTRA } from '../../utils/Constants';
import { useDispatch, connect } from "react-redux";
import { LEFT_NAV_EXPANDED_TRUE_FALSE, SET_LEFTNAV_SUCCESS } from '../../redux/actions/Types';
import { NavDropdown } from 'react-bootstrap';
import { allowedBasedOnPackage, allowedBasedOnRole } from '../../common/GlobalFunctions';
import NoInternet from '../NoIntenet';
import PackageExpire from '../PackageExpire';


const LeftNav = (props) => {
  const { selectedCompany, leftNavExpand, leftNavSelectedItem, internetAvailable } = props;
  const dispatch = useDispatch();

  const [isActive, setActive] = useState(leftNavExpand === "No" ? true : false);
  const [showAllowed, setShowAllowed] = useState(false);
  const [page, setPage] = useState("");
  const [lock_page, setLockPage] = useState("");
  const [lock_parentMenu, setLockParentMenu] = useState("");
  const navigate = useNavigate();  
  const location = useLocation();

  const [showDashboard, setShowDashboard] = useState(leftNavSelectedItem.parentName === "Dashboard" ? true : false);
  const [showReports, setShowReports] = useState(leftNavSelectedItem.parentName === "Reports" ? true : false);
  const [showSettings, setShowSettings] = useState(leftNavSelectedItem.parentName === "Settings" ? true : false);
  const [showBillings, setShowBillings] = useState(leftNavSelectedItem.parentName === "Billings" ? true : false);


  useEffect(() => {
    chekUserRole();
  }, [])

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/");
    const lastWord = parts[parts.length - 1];
    const pageUrl = lastWord.split("?")[0];
    setPage(pageUrl);
  }, [location])



  useEffect(() => {

    //condition for member
    if (selectedCompany.userRole[0] === MEMBER && selectedCompany.userRole.length === 1) {
      if (page === "") {
        dispatch({
          type: SET_LEFTNAV_SUCCESS,
          payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
        });
      }
      else if (page === "webs-apps") {
        dispatch({
          type: SET_LEFTNAV_SUCCESS,
          payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
        });
      }
    }
    //Condition for Dashboard parent
    if (page === "team-dashboard") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Dashboard" } },
      });
    }
    else if (page === "user-dashboard") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Dashboard" } },
      });
    }
    //Condition for Reports parent
    else if (page === "hours-track") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "activity-summary") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "quarterly-performance") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "project-costing") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "project-costing-new") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "project-hours") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "profitability-report") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    //Condition for Web & Apps
    else if (page === "webs-apps") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "attendance-report") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    //Condition for Settings parent
    else if (page === "projects") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "tasks") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: "projects", parentName: "Settings" } },
      });
    }
    else if (page === "groups") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "webapp-rating") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "user-management") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    //Condition for Billings parent
    else if (page === "billing-details") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Billings" } },
      });
    }
    else if (page === "payroll") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Billings" } },
      });
    }
    //Condition for Client
    else if (page === "clients") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "client-team") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: "clients", parentName: "Settings" } },
      });
    }
    else if (page === "my-team") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    //Conditon for email notifications
    else if (page === "email-notification") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    //Condition for Screenshots
    else if (page === "screenshots") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }
    //Condition for Downloads
    else if (page === "downloads") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }
    //Condition for organization
    else if (page === "organization") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }
    // Conditon for profile
    else if (page === "profile") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }

  }, [page])

  useEffect(() => {
    handleCollapseMenu(leftNavSelectedItem.parentName, 'parent', null);
  }, [leftNavSelectedItem])

  // const handleCollapseMenu = (section, source, event) => {
  //   if (event) {
  //     event.stopPropagation(); // Prevent the event from propagating to parent elements
  //   }
  //   // console.log(section, source)

  //   // setShowDashboard(section === 'Dashboard');
  //   // setShowReports(section === 'Reports');
  //   // setShowSettings(section === 'Settings');
  //   // setShowBillings(section === 'Billings');

  //   switch (section) {
  //     case 'Dashboard':
  //       setShowDashboard((prev) => !prev);
  //       setShowReports(false);
  //       setShowSettings(false);
  //       setShowBillings(false);
  //       break;
  //     case 'Reports':
  //       setShowDashboard(false);
  //       setShowReports((prev) => !prev);
  //       setShowSettings(false);
  //       setShowBillings(false);
  //       break;
  //     case 'Settings':
  //       setShowDashboard(false);
  //       setShowReports(false);
  //       setShowSettings((prev) => !prev);
  //       setShowBillings(false);
  //       break;
  //     case 'Billings':
  //       setShowDashboard(false);
  //       setShowReports(false);
  //       setShowSettings(false);
  //       setShowBillings((prev) => !prev);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleCollapseMenu = (section, source, event) => {
    if (event) {
      event.stopPropagation(); // Prevent the event from propagating to parent elements
    }
  
    // Update states based on the section and source
    switch (section) {
      case 'Dashboard':
        setShowDashboard(source === 'child' ? true : (prev) => !prev);
        setShowReports(source === 'child' ? false : false);
        setShowSettings(source === 'child' ? false : false);
        setShowBillings(source === 'child' ? false : false);
        break;
      case 'Reports':
        setShowDashboard(source === 'child' ? false : false);
        setShowReports(source === 'child' ? true : (prev) => !prev);
        setShowSettings(source === 'child' ? false : false);
        setShowBillings(source === 'child' ? false : false);
        break;
      case 'Settings':
        setShowDashboard(source === 'child' ? false : false);
        setShowReports(source === 'child' ? false : false);
        setShowSettings(source === 'child' ? true : (prev) => !prev);
        setShowBillings(source === 'child' ? false : false);
        break;
      case 'Billings':
        setShowDashboard(source === 'child' ? false : false);
        setShowReports(source === 'child' ? false : false);
        setShowSettings(source === 'child' ? false : false);
        setShowBillings(source === 'child' ? true : (prev) => !prev);
        break;
      default:
        break;
    }
  };
  
  const ToggleClass = () => {
    setActive(!isActive);
    dispatch({
      type: LEFT_NAV_EXPANDED_TRUE_FALSE,
      payload: { leftNavExpand: isActive ? "Expanded" : "No" },
    });
  };

  const chekUserRole = () => {
    if (allowedBasedOnRole([ADMIN, OWNER], selectedCompany.userRole)) {
      setShowAllowed(true);
    }
  };

  const upgradePageFrom = (paged_name, parent_menu, title, description, imgSrc, minPackage) => {
    dispatch({
      type: SET_LEFTNAV_SUCCESS,
      payload: { leftNavSelectedItem: { selectedItem: paged_name, parentName: parent_menu } },
    });
    const paramsObject = {
      paged_name: paged_name,
      parent_menu: parent_menu,
      title: title,
      description: description,
      imgSrc: imgSrc,
      minPackage: minPackage
    };
    // Convert object to JSON string and encode to Base64
    const encodedParams = btoa(JSON.stringify(paramsObject));
    navigate(`/upgrade-please?data=${encodedParams}`);
  };


  

  return (
    <>
      {[false].map((expand) => (
        <Navbar collapseOnSelect expand="md" key={false}>
          <Container className='p-0' fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <div className={isActive ? "active" : "inactive"}>
                <button onClick={ToggleClass} className="menu-icon">
                  <img src={"images/menu-icon-black.svg"} /></button>
                <div className='left-nav'>
                  <Navbar.Brand className='py-md-4 mb-4 me-0 d-block px-4'>
                    <Link to="/">
                      <div className='logo-left-nav'>
                        <img src={"images/TimebugLogoOnly.svg"} />
                        <img className='time-bee-ico' src={"images/timebee-ico.svg"} />
                      </div>
                    </Link>
                  </Navbar.Brand>
                  <Nav defaultActiveKey="/" as="ul" className='d-block'>

                  {
  allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN, CLIENT], selectedCompany.userRole) ? (
    <Nav.Item as="li" className={'mb-2 arrow ' + (leftNavSelectedItem.parentName === "Dashboard" ? "active-tab" : "default-tab")}>
      <div className='dashbaord-li'>
        <Nav.Link className={'d-flex align-items-center'}>
          <img src={leftNavSelectedItem.parentName === "Dashboard" ? "images/dashbaord-active.svg" : "images/dashbaord.svg"} />
          <span> Dashbaord</span>
        </Nav.Link>
        <Nav as="ul" className='hover-menu'>
          <Nav.Item as="li">
            <Link to="/team-dashboard" onClick={(e) => handleCollapseMenu("Dashboard", "child", e)}> Team Dashboard </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/user-dashboard" onClick={(e) => handleCollapseMenu("Dashboard", "child", e)}> User Dashboard </Link>
          </Nav.Item>
        </Nav>
      </div>

      <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
        <img width={15} src={"images/dashbaord.svg"} className='default' />
        <NavDropdown
          show={showDashboard}
          title="Dashbaord"
          id="collapsible-nav-dropdown"
          onClick={(e) => handleCollapseMenu("Dashboard", "parent", e)}
          className='arrow'>
          <img width={15} src={"images/dashbaord-active.svg"} className='on-active' />
          <ul className='p-0'>
            <Nav.Item as="li">
              <Link className={(leftNavSelectedItem.selectedItem === "team-dashboard" ? " selected " : "")} to="/team-dashboard" onClick={(e) => handleCollapseMenu("Dashboard", "child", e)}> Team Dashboard </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className={(leftNavSelectedItem.selectedItem === "user-dashboard" ? " selected " : "")} to="/user-dashboard" onClick={(e) => handleCollapseMenu("Dashboard", "child", e)}> User Dashboard </Link>
            </Nav.Item>
          </ul>
        </NavDropdown>
      </div>
    </Nav.Item>
  ) : (
    <Nav.Item as="li" className='mb-2'>
      <Link className={"d-flex align-items-center for-member-role " + (leftNavSelectedItem.selectedItem === "user-dashboard" ? " selected for-member-role-selected" : "")} to="/" >
        <img src={"images/dashbaord.svg"} className='default' />
        <img src={"images/dashbaord-active.svg"} className='on-active' />
        <span>My Dashboard</span>
      </Link>
      <Nav as="ul" className='hover-menu'>
        <p>My Dashboard</p>
      </Nav>
    </Nav.Item>
  )
}



                    {/* {allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN, CLIENT], selectedCompany.userRole) ?

                      <Nav.Item as="li" className={'mb-2 arrow ' + (leftNavSelectedItem.parentName === "Dashboard" ? "active-tab" : "default-tab")}>
                        <div className='dashbaord-li'>
                          <Nav.Link className={'d-flex align-items-center' }>
                           <img src={leftNavSelectedItem.parentName === "Dashboard" ? "images/dashbaord-active.svg" : "images/dashbaord.svg"} /> 
                            <span> Dashbaord</span></Nav.Link>
                          <Nav as="ul" className='hover-menu'>
                            <Nav.Item as="li">
                              <Link to="/team-dashboard" onClick={() => handleCollapseMenu("Dashboard", "child")}> Team Dashboard </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Link to="/user-dashboard" onClick={() => handleCollapseMenu("Dashboard", "child")}> User Dashboard </Link>
                            </Nav.Item>
                          </Nav>
                        </div>

                        <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                          <img width={15} src={"images/dashbaord.svg"} className='default' />
                          <NavDropdown 
                            show={showDashboard} 
                            title="Dashbaord" 
                            id="collapsible-nav-dropdown" 
                            onClick={() => { handleCollapseMenu("Dashboard"); }}  
                            className='arrow'>
                          <img width={15} src={"images/dashbaord-active.svg"} className='on-active' />
                            <ul className='p-0'>
                              <Nav.Item as="li">
                                <Link className={(leftNavSelectedItem.selectedItem === "team-dashboard" ? " selected " : "")} to="/team-dashboard" onClick={() => handleCollapseMenu("Dashboard", "child")}> Team Dashboard </Link>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <Link className={(leftNavSelectedItem.selectedItem === "user-dashboard" ? " selected " : "")} to="/user-dashboard" onClick={() => handleCollapseMenu("Dashboard", "child")}> User Dashboard </Link>
                              </Nav.Item>
                            </ul>
                          </NavDropdown>
                        </div>
                      </Nav.Item>
                      :
                      <Nav.Item as="li" className='mb-2'>
                        <Link className={"d-flex align-items-center for-member-role " + (leftNavSelectedItem.selectedItem === "user-dashboard" ? " selected for-member-role-selected" : "")} to="/" >
                          <img src={"images/dashbaord.svg"} className='default'/>
                          <img src={"images/dashbaord-active.svg"} className='on-active'/>
                          <span>My Dashboard</span></Link>
                        <Nav as="ul" className='hover-menu'>
                        <p >My Dashboard</p>
                        </Nav>
                      </Nav.Item>
                    } */}

                    {allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN, CLIENT], selectedCompany.userRole) ?
                      <Nav.Item as="li" className={'mb-2 arrow ' + (leftNavSelectedItem.parentName === "Reports" ? "active-tab" : "default-tab")}>
                        <div className='dashbaord-li'>
                          <Nav.Link className='d-flex align-items-center'>
                            <img src={leftNavSelectedItem.parentName === "Reports" ? "images/reports-active.svg" :"images/reports.svg"} />
                            <span>Reports</span></Nav.Link>
                          <Nav as="ul" className='hover-menu'>
                            <Nav.Item as="li">
                              <p onClick={() => { navigate('/hours-track', { state: "users" }); }} > Hours Tracked </p>
                            </Nav.Item>

                            <Nav.Item as="li">
                              <p onClick={() => { navigate('/activity-summary', { state: "users" }); }} > Activity Summary </p>
                            </Nav.Item>
                            {allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/webs-apps"> Web & Apps </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Websites & Apps Usage", description: "Total time spent on websites and apps, classified by productivity.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }

                            {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/project-costing"> Project Costing </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Costing", description: "Project budget and costing", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Project Costing
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }</>}
                            {/* {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/project-costing-new"> Project Costing New</Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Costing", description: "Project budget and costing", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Project Costing New
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            } */}

                            {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/project-hours"> Project Progress </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Progress", description: "Progress Monitor all projects at a glance.", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} > Project Progress
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }</>}

                            {showAllowed && <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/quarterly-performance"> Quarterly Performance </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Quarterly Performance", description: "Work hour trends over a quarter.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Quarterly Performance
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}</>}

                            {/* <Nav.Item as="li">
                              <p className={(leftNavSelectedItem.selectedItem === "attendance-report" ? " selected " : "")} onClick={() => { navigate('/attendance-report'); }} > Attendance Report </p>
                            </Nav.Item> */}
                            {showAllowed && <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/profitability-report"> Profitability Report </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Profitability", description: "Check Profit Of Your Organization", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Profitability Report
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}</>}

                            {showAllowed && <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/attendance-report"> Attendance Report </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Attendance Report", description: "Breakdown of activity, by productivity per user.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Attendance Report
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}</>}
                          </Nav>
                        </div>

                        <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                          <img width={15} src={"images/reports.svg"} />
                          <NavDropdown title="Reports" id="collapsible-nav-dropdown" show={showReports} onClick={() => { handleCollapseMenu("Reports"); }} className='arrow'>
                            <img width={15} src={"images/reports-active.svg"} />
                            <ul className='p-0'>
                              <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "hours-track" ? " selected " : "")} onClick={() => { navigate('/hours-track', { state: "users" }); }} > Hours Tracked </p>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "activity-summary" ? " selected " : "")} onClick={() => { navigate('/activity-summary', { state: "users" }); }} > Activity Summary </p>
                              </Nav.Item>


                              <>
                                {allowedBasedOnRole([ADMIN, OWNER, CLIENT, GROUPADMIN], selectedCompany.userRole) &&
                                  <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                    <Nav.Item as="li">
                                      <Link className={(leftNavSelectedItem.selectedItem === "webs-apps" ? " selected " : "")} to="/webs-apps"> Web & Apps </Link>
                                    </Nav.Item>
                                    :
                                    <Nav.Item as="li" className=''>
                                      {(leftNavSelectedItem.selectedItem === "webs-apps" ?
                                      <img src='images/padlock.svg' /> : 
                                      <img src='images/padlock-blue.svg' />)}
                                      <h6 className={(leftNavSelectedItem.selectedItem === "webs-apps" ? " selected " : "")} onClick={() => { 
                                        upgradePageFrom("webs-apps", "Reports", "Websites & Apps Usage", "Total time spent on websites and apps, classified by productivity.", "images/login-black.svg", "Premium"); }} >  Web & Apps </h6>
                                    </Nav.Item>}</>}
                              </>

                              {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <>{allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <Link className={(leftNavSelectedItem.selectedItem === "project-costing" ? " selected " : "")} to="/project-costing"> Project Costing </Link>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "project-costing" ?
                                      <img src='images/padlock.svg' /> : 
                                      <img src='images/padlock-blue.svg' />)}
                                  <h6 className={(leftNavSelectedItem.selectedItem === "project-costing" ? " selected " : "")} onClick={() => { 
                                    upgradePageFrom("project-costing", "Reports", "Project Costing", "Project budget and costing", "images/login-black.svg", "Ultra"); }} >  Project Costing  </h6>
                                </Nav.Item>}</>}

                              {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <>
                                {allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <Link className={(leftNavSelectedItem.selectedItem === "project-hours" ? " selected " : "")} to="/project-hours"> Project Progress </Link>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "project-hours" ?
                                      <img src='images/padlock.svg' /> : 
                                      <img src='images/padlock-blue.svg' />)}
                                    <h6 className={(leftNavSelectedItem.selectedItem === "project-hours" ? " selected " : "")} onClick={() => { 
                                      upgradePageFrom("project-hours", "Reports", "Project Progress", "Progress Monitor all projects at a glance.", "images/login-black.svg", "Ultra"); }} >  Project Progress  </h6>
                                  </Nav.Item>}</>}

                              {showAllowed && <>
                                {allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <Link className={(leftNavSelectedItem.selectedItem === "quarterly-performance" ? " selected " : "")} to="/quarterly-performance"> Quarterly Performance </Link>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "quarterly-performance" ?
                                      <img src='images/padlock.svg' /> : 
                                      <img src='images/padlock-blue.svg' />)}
                                    <h6 className={(leftNavSelectedItem.selectedItem === "quarterly-performance" ? " selected " : "")} onClick={() => { 
                                      upgradePageFrom("quarterly-performance", "Reports", "Quarterly Performance", "Work hour trends over a quarter.", "images/login-black.svg", "Premium"); }} >  Quarterly Performance  </h6>
                                  </Nav.Item>} </>}

                              {showAllowed &&
                                <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <Link className={(leftNavSelectedItem.selectedItem === "profitability-report" ? " selected " : "")} to='/profitability-report' >  Profitability Report </Link>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "profitability-report" ?
                                      <img src='images/padlock.svg' /> : 
                                      <img src='images/padlock-blue.svg' />)}
                                    <h6 className={(leftNavSelectedItem.selectedItem === "profitability-report" ? " selected " : "")} onClick={() => { 
                                    upgradePageFrom("profitability-report", "Reports", "Profitability Report", "Check Profit Of Your Organization", "images/login-black.svg", "Premium"); }} > Profitability Report </h6>
                                  </Nav.Item>
                                }</>}

                              {showAllowed &&
                                <> {allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <Link className={(leftNavSelectedItem.selectedItem === "attendance-report" ? " selected " : "")} to='/attendance-report' >  Attendance Report </Link>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "attendance-report" ?
                                      <img src='images/padlock.svg' /> : 
                                      <img src='images/padlock-blue.svg' />)}
                                    <h6 className={(leftNavSelectedItem.selectedItem === "attendance-report" ? " selected " : "")} onClick={() => { 
                                    upgradePageFrom("attendance-report", "Reports", "Attendance Report", "Breakdown of activity, by productivity per user.", "images/login-black.svg", "Premium"); }} > Attendance Report </h6>
                                  </Nav.Item>}
                                </>}
                            </ul>
                          </NavDropdown>
                        </div>
                      </Nav.Item>
                      :
                      <>
                        {
                          allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                            
                            <Nav.Item as="li" className='mb-2'>
                              <Link className={"d-flex align-items-center for-member-role " + (leftNavSelectedItem.selectedItem === "webs-apps" ? " selected for-member-role-selected" : "")} to="/webs-apps" >
                                <img src={"images/web.svg"} className='default'/>
                                <img src={"images/web-active.svg"} className='on-active'/>
                                <span>Web & Apps</span></Link>
                              <Nav as="ul" className='hover-menu'>
                                <p>Web & Apps</p>
                              </Nav>
                            </Nav.Item>
                            :
                            <Nav.Item as="li" className=''>
                              <img src='images/padlock.svg' />
                              <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Websites & Apps Usage", description: "Total time spent on websites and apps, classified by productivity.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps  </h6>
                            </Nav.Item>
                        }
                      </>

                    }

                    {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) &&
                      <Nav.Item as="li" className={'mb-2 arrow ' + (leftNavSelectedItem.parentName === "Settings" ? "active-tab" : "default-tab")}>
                        <div className='dashbaord-li'>
                          <Nav.Link className='d-flex align-items-center'>
                            <img src={leftNavSelectedItem.parentName === "Settings"? "images/setting-active.svg"  :"images/setting.svg"} />
                            <span>Settings</span></Nav.Link>
                          <Nav as="ul" className='hover-menu'>
                            {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <Nav.Item as="li">
                              <p onClick={() => { navigate('/projects', { state: "users" }); }} > Projects &Tasks </p>
                            </Nav.Item>}
                            {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <p onClick={() => { navigate('/groups', { state: "users" }); }} > Groups </p>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className='d-flex'>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Groups", description: "Create, edit, or archive groups for your organization", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Groups
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }
                            {showAllowed &&
                              <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <p onClick={() => { navigate('/webapp-rating', { state: "users" }); }} >  Web & Apps Rating </p>
                                </Nav.Item>
                                : <Nav.Item as="li" className='d-flex'>
                                  <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Web & Apps Rating", description: "Rate websites and apps based on productivity for your organization.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps Rating
                                    <img src='images/padlock.svg' className='ms-2' />
                                  </p>
                                </Nav.Item>
                              }</>}

                            {showAllowed && <Nav.Item as="li">
                              <p onClick={() => { navigate('/user-management', { state: "users" }); }} >  Users</p>
                            </Nav.Item>}

                            {showAllowed && <>{allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <p onClick={() => { navigate('/clients', { state: "users" }); }} >  Clients</p>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className='d-flex'>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Clients", description: "Manage your clients.", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Client
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}</>}

                            {showAllowed && <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <p onClick={() => { navigate('/email-notification', { state: "users" }); }} >  Email Notification</p>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className='d-flex'>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Email Notification", description: "Customize report preferences, receive timely updates in your inbox.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Email Notification
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }</>}
                          </Nav>
                        </div>


                        <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                          <img width={15} src={"images/setting.svg"} />
                          <NavDropdown title="Settings" id="collapsible-nav-dropdown" show={showSettings} onClick={() => { handleCollapseMenu("Settings"); }} className='arrow'>
                            <img width={15} src={"images/setting-active.svg"} />
                            <ul className='p-0'>
                              {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "projects" ? " selected " : "")} onClick={() => { navigate('/projects', { state: "users" }); }} >  Projects & Tasks</p>
                              </Nav.Item>}

                              {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <>
                                {allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <p className={(leftNavSelectedItem.selectedItem === "groups" ? " selected " : "")} onClick={() => { navigate('/groups', { state: "users" }); }} > Groups </p>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                    {(leftNavSelectedItem.selectedItem === "groups" ?
                                    <img src='images/padlock.svg' /> : 
                                    <img src='images/padlock-blue.svg' />)}
                                    <h6 className={(leftNavSelectedItem.selectedItem === "groups" ? " selected " : "")}  onClick={() => { 
                                      upgradePageFrom("groups", "Settings", "Groups", "Create, edit, or archive groups for your organization.", "images/login-black.svg", "Premium"); 
                                    }} > Groups </h6>
                                  </Nav.Item>
                                }</>}

                              {showAllowed &&
                                <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <p className={(leftNavSelectedItem.selectedItem === "webapp-rating" ? " selected " : "")} onClick={() => { navigate('/webapp-rating', { state: "users" }); }} > Web & Apps Rating </p>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "webapp-rating" ?
                                  <img src='images/padlock.svg' /> : 
                                  <img src='images/padlock-blue.svg' />)}
                                  <h6 className={(leftNavSelectedItem.selectedItem === "webapp-rating" ? " selected " : "")} onClick={() => { 
                                    upgradePageFrom("webapp-rating", "Settings", "Web & Apps Rating", "Rate websites and apps based on productivity for your organization.", "images/login-black.svg", "Premium");
                                     }} >  Web & Apps Rating </h6>
                                  </Nav.Item>
                                }</>}

                              {showAllowed && <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "user-management" ? " selected " : "")} onClick={() => { navigate('/user-management', { state: "users" }); }} > Users </p>
                              </Nav.Item>
                              }

                              {allowedBasedOnRole([ADMIN, OWNER], selectedCompany.userRole) && <>
                                {allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <p className={(leftNavSelectedItem.selectedItem === "clients" ? " selected " : "")} onClick={() => { navigate('/clients', { state: "clients" }); }}> Clients </p>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "clients" ?
                                  <img src='images/padlock.svg' /> : 
                                  <img src='images/padlock-blue.svg' />)}
                                    <h6 className={(leftNavSelectedItem.selectedItem === "clients" ? " selected " : "")} onClick={() => { 
                                      upgradePageFrom("clients", "Settings", "Clients", "Manage your clients.", "images/login-black.svg", "Ultra");
                                    }} > Clients  </h6>
                                  </Nav.Item>
                                }</>}

                              {allowedBasedOnRole([CLIENT], selectedCompany.userRole) &&
                                <Nav.Item as="li" className='mb-2 '>
                                  <p className={(leftNavSelectedItem.selectedItem === "my-team" ? " selected " : "")} onClick={() => { navigate('/my-team', { state: "clients" }); }}> My Team </p>
                                </Nav.Item>
                              }

                              {showAllowed &&
                                <>{allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li" className='mb-2 '>
                                    <p className={(leftNavSelectedItem.selectedItem === "email-notification" ? " selected " : "")} onClick={() => { navigate('/email-notification', { state: "clients" }); }}> Email Notification </p>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                  {(leftNavSelectedItem.selectedItem === "email-notification" ?
                                  <img src='images/padlock.svg' /> : 
                                  <img src='images/padlock-blue.svg' />)}
                                    <h6 className={(leftNavSelectedItem.selectedItem === "email-notification" ? " selected " : "")} onClick={() => { 
                                      upgradePageFrom("email-notification", "Settings", "Email Notification", "Customize report preferences, receive timely updates in your inbox.", "images/login-black.svg", "Premium");
                                    }} >  Email Notification  </h6>
                                  </Nav.Item>
                                }</>}
                            </ul>
                          </NavDropdown>
                        </div>
                      </Nav.Item>}



                    {allowedBasedOnRole([CLIENT], selectedCompany.userRole) &&
                      <Nav.Item as="li" className='mb-2'>
                      <Link className={"d-flex align-items-center for-member-role " + (leftNavSelectedItem.selectedItem === "payroll" ? " selected for-member-role-selected" : "")} to="/payroll" >
                        <img src={"images/billing-details.svg"} className='default'/>
                        <img src={"images/billing-details-active.svg"} className='on-active'/>
                        <span>Payroll</span></Link>
                      <Nav as="ul" className='hover-menu'>
                        <p>Payroll</p>
                      </Nav>
                    </Nav.Item>
                    }

                    {showAllowed && <Nav.Item as="li" className={'mb-2 arrow ' + (leftNavSelectedItem.parentName === "Billings" ? "active-tab" : "default-tab")}>
                      <div className='dashbaord-li'>
                        <Nav.Link className={'d-flex align-items-center ' }>
                          <img src={leftNavSelectedItem.parentName === "Billings" ? "images/billing-details-active.svg" : "images/billing-details.svg"} />
                          <span>Billings</span></Nav.Link>
                        <Nav as="ul" className='hover-menu'>
                          <Nav.Item as="li">
                            <p onClick={() => { navigate('/payroll', { state: "users" }); }} >  Payroll </p>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <p onClick={() => { navigate('/billing-details', { state: "users" }); }} > Billing Details </p>
                          </Nav.Item>

                        </Nav>
                      </div>



                      <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                        <img width={15} src={"images/billing-details.svg"} />
                        <NavDropdown title="Billings" id="collapsible-nav-dropdown" show={showBillings} onClick={() => { handleCollapseMenu("Billings"); }} className='arrow'>
                          <img width={15} src={"images/billing-details-active.svg"} className=''/>
                          <ul className='p-0'>
                            <Nav.Item as="li">
                              <p className={(leftNavSelectedItem.selectedItem === "billing-details" ? " selected " : "")} onClick={() => { navigate('/billing-details', { state: "users" }); }} >  Billing Details</p>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <p className={(leftNavSelectedItem.selectedItem === "payroll" ? " selected " : "")} onClick={() => { navigate('/payroll', { state: "users" }); }} > Payroll </p>
                            </Nav.Item>

                          </ul>
                        </NavDropdown>
                      </div>
                    </Nav.Item>}



                    <Nav.Item as="li" className='mb-2'>
                      <Link className={"d-flex align-items-center for-member-role " + (leftNavSelectedItem.selectedItem === "screenshots" ? " selected for-member-role-selected" : "")} to="/screenshots" >
                        <img src={"images/screenshot.svg"} className='default'/>
                        <img src={"images/screenshot-active.svg"} className='on-active'/>
                        <span>Screenshots</span></Link>
                      <Nav as="ul" className='hover-menu'>
                        <p>Screenshots</p>
                      </Nav>
                    </Nav.Item>

                    <Nav.Item as="li" className='mb-2'>
                      <Link className={"d-flex align-items-center for-member-role" + (leftNavSelectedItem.selectedItem === "downloads" ? " selected for-member-role-selected" : "")} to="/downloads" >
                        <img src={"images/download.svg"} className='default' />
                        <img src={"images/download-active.svg"} className='on-active' />
                        <span>Downloads</span></Link>
                      <Nav as="ul" className='hover-menu'>
                        <p>Downloads</p>
                      </Nav>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </Navbar.Offcanvas>
          </Container>
        </Navbar >

      ))
      }

      {!internetAvailable && <NoInternet />}
    </>
  );
}

const mapStateToProps = state => ({
  selectedCompany: state.UserReducer.selectedCompany,
  leftNavExpand: state.SupportReducer.leftNavExpand,
  internetAvailable: state.SupportReducer.internetAvailable,
  leftNavSelectedItem: state.LeftNavReducer.leftNavSelectedItem,
});

export default connect(mapStateToProps)(LeftNav);
