import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './style.css';

export default function AnimatedSaveButton(props) {
  const { text, isDisabled, callingFunction, loaderLoading, showTick, messageType, displayErrorMsg } = props;

  // const isError = messageType && messageType.includes("already")
  // console.log("loading:", loaderLoading, " |tick:", showTick, " |err:", displayErrorMsg, " |time:", new Date());

  const handleSave = (e) => {
    e.preventDefault();
    callingFunction();
  };


  return (
    isDisabled ?
      <div className='disabled-animated-button '>
        <Button disabled>{text}</Button>
      </div>
      :
      <div className={"animated-save-button-div " + (messageType ? "msgType" : "")}>
        <Button
          className="fs-16 text-white font-regular py-1 my-2"
          type="submit"
          disabled={loaderLoading || showTick}
          onClick={handleSave}
        >
          {loaderLoading ? (
            <span className="spinner-border spinner-border-sm">&nbsp;</span>
          ) : showTick ? (
            <img src={'images/loder-new.svg'} alt="Saved" style={{ width: '38px', height: '38px' }} />
          ) : (
            <span className="text-white">{text}</span>
          )}
        </Button>

        {displayErrorMsg &&
          <>
            <div className='animated-div-msg'>{displayErrorMsg}</div>
          </>
        }
      </div >

  );
}
