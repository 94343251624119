import axios from "axios";
import authHeader from "./AuthHeader";
import { API_URL_PHP } from "../utils/Constants";

const fetchUserSettings = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/org-users", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const fetchSingleUserSettingdata = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/org-user-detail", requestData, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const updateUserSettings = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/settings/update-user-settings", requestData, { headers: authHeader() })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const updateUserRole = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/organization/update-user-role", requestData, { headers: authHeader() })
        .then((response) => {
            // console.log("response", response);
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};
const updateUserStatus = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/organization/update-user-status", requestData, { headers: authHeader() })
        .then((response) => {
            console.log("response", response);
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};
const updateUserHoursRate = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/project-costing-update", requestData, { headers: authHeader() })
        .then((response) => {
            // console.log("response", response);
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};
const updateUserNameVal = (requestData) => {
    return axios
        .post(API_URL_PHP + "api/v1/user/change-username", requestData, { headers: authHeader() })
        .then((response) => {
            // console.log("response", response);
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};

const updateProfileImage = (requestData) => {
    return axios
        .post("https://dapi.timebee.app/api/payload/pr/profilepic", requestData, { headers: authHeader() })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.code === "ERR_NETWORK") {
                return error.code;
            }
            console.log("Error:  ", error);
        })
};


export default {
    fetchUserSettings,
    updateUserSettings,
    updateUserRole,
    updateUserStatus,
    updateUserHoursRate,
    fetchSingleUserSettingdata,
    updateUserNameVal,
    updateProfileImage
};