import subDays from 'date-fns/subDays'

import {
    ACTIVITY_SUMMARY_DATE_SELECTION_SUCCESS,
    FETCH_ACTIVITY_SUMMARY_SUCCESS,
    FETCH_ACTIVITY_SUMMARY_FAILED,
    FETCH_MORE_ACTIVITY_SUMMARY_SUCCESS,
    FETCH_MORE_ACTIVITY_SUMMARY_FAILED,
    FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_SUCCESS,
    FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_FAILED,
    ACTIVITY_SUMMARY_USER_ADDED_SUCCESS,
    ACTIVITY_SUMMARY_USER_REMOVE_SUCCESS,
    ACTIVITY_SUMMARY_USERS_CLEAR_SUCCESS,
    ACTIVITY_SUMMARY_GROUP_SELECTION_UPDATE,
    ACTIVITY_SUMMARY_GROUPS_CLEAR_SUCCESS,
    ACTIVITY_SUMMARY_USER_SELECTION_SUCCESS
} from "../actions/Types"
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager'

let selectedDateRange = JSON.parse(getLocalStorageValue("Activity_Summary_Date_Selection"))
let selectedUsers = JSON.parse(getLocalStorageValue("Activity_Summary_User_Selection"))
let selectedGroups = JSON.parse(getLocalStorageValue("Activity_Summary_Group_Selection"))
let listUsers = []
let listGroups = []

let initialState = {
    activitySummaryDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },
    activitySummary: { "list": [], "loadMore": false },
    activitySummaryUsersDropDown: { users: [], groups: [] },
    activitySummarySelectedUsers: selectedUsers ? selectedUsers : [],
    activitySummarySelectedGroups: selectedGroups ? selectedGroups : [],
}


export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {

        case ACTIVITY_SUMMARY_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Activity_Summary_Date_Selection", JSON.stringify(newDt))
            return {
                ...state,
                activitySummaryDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            }


        case FETCH_ACTIVITY_SUMMARY_SUCCESS:
            return {
                ...state,
                activitySummary: { "list": payload.activitySummary.list, loadMore: payload.loadMore }
            }

        case FETCH_ACTIVITY_SUMMARY_FAILED:
            return {
                ...state,
                activitySummary: { "list": [], "loadMore": false },
            }

        case FETCH_MORE_ACTIVITY_SUMMARY_SUCCESS:
            let ss = state.activitySummary
            let con = ss.list.concat(payload.activitySummary.list)
            return {
                ...state,
                activitySummary: { "list": con, loadMore: payload.loadMore },
            }

        case FETCH_MORE_ACTIVITY_SUMMARY_FAILED:
            return {
                ...state,
                activitySummary: { "list": [], "loadMore": false },
            }

        // user and group dropdown code bellow
        case FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Activity_Summary_User_Selection"))
            selectedGroups = JSON.parse(getLocalStorageValue("Activity_Summary_Group_Selection"))

            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false
            })

            listUsers = payload.listUsersDropDown.users
            const filteredAddMembers = selectedUsers !== null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users

            listGroups = payload.listUsersDropDown.groups
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false
                if (selectedGroups) {
                    selectedGroups.forEach((selectedGroup) => {
                        if (selectedGroup.id === group.id) group.checked = true
                    })
                }
            })

            return {
                ...state,
                activitySummaryUsersDropDown: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            }

        case FETCH_ACTIVITY_SUMMARY_USERS_DROPDOWN_FAILED:
            return {
                ...state,
                activitySummaryUsersDropDown: { users: [], groups: [] },
            }

        // tested
        case ACTIVITY_SUMMARY_GROUP_SELECTION_UPDATE:

            let newUserListState_0 = state.activitySummaryUsersDropDown
            newUserListState_0.groups.forEach((group, index) => {
                if (payload.changedGroup.id === group.id) {
                    group.checked = payload.changedGroup.checked
                } else {
                    group.checked = false
                }
            })
            setLocalStorageValue("Activity_Summary_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)))
            return {
                ...state,
                activitySummaryUsersDropDown: { users: newUserListState_0.users, groups: newUserListState_0.groups }
            }

        // tested
        case ACTIVITY_SUMMARY_USER_ADDED_SUCCESS:
            let users = state.activitySummarySelectedUsers
            users = users.concat(payload.userAdded)
            setLocalStorageValue("Activity_Summary_User_Selection", JSON.stringify(users))
            let searched = state.activitySummaryUsersDropDown.users
            var filter = searched.filter(a => !(a === payload.userAdded))
            return {
                ...state,
                activitySummarySelectedUsers: users,
                activitySummaryUsersDropDown: { users: filter, groups: state.activitySummaryUsersDropDown.groups }
            }

        // tested
        case ACTIVITY_SUMMARY_USER_REMOVE_SUCCESS:
            let uzers = state.activitySummaryUsersDropDown.users
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false
                uzers.push(payload.userRemoved)
                uzers.sort((a, b) => a.name.localeCompare(b.name))
            }

            let arr = state.activitySummarySelectedUsers
            var filter1 = arr.filter(a => !(a === payload.userRemoved))
            setLocalStorageValue("Activity_Summary_User_Selection", JSON.stringify(filter1))
            return {
                ...state,
                activitySummarySelectedUsers: filter1,
                activitySummaryUsersDropDown: { users: uzers, groups: state.activitySummaryUsersDropDown.groups }
            }

        // tested
        case ACTIVITY_SUMMARY_USERS_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false
            })

            setLocalStorageValue("Activity_Summary_User_Selection", JSON.stringify(null))
            return {
                ...state,
                activitySummarySelectedUsers: [],
                activitySummaryUsersDropDown: { users: listUsers, groups: state.activitySummaryUsersDropDown.groups }
            }

        // tested
        case ACTIVITY_SUMMARY_GROUPS_CLEAR_SUCCESS:
            console.log("Reducer, listGroups: ", listGroups)
            listGroups.forEach((group) => {
                group['checked'] = false
            })

            setLocalStorageValue("Screenshot_Group_Selection", JSON.stringify(null))
            return {
                ...state,
                activitySummaryUsersDropDown: { users: state.activitySummaryUsersDropDown.users, groups: listGroups }
            }

        case ACTIVITY_SUMMARY_USER_SELECTION_SUCCESS:
            setLocalStorageValue("Activity_Summary_User_Selection", JSON.stringify(payload.selectedUsers))
            return {
                ...state,
                activitySummarySelectedUsers: payload.selectedUsers
            }
        // end users dropdown

        default:
            return state
    }
}
