import React, { useState, useEffect } from 'react'
import { Form, ProgressBar } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, connect } from "react-redux";
import { getcharacter, isoToCustom, noDataSimple, timeFormatInHMorMS, webOrApp, getRandomColor } from '../../common/GlobalFunctions';
import CustomToolTip from "../CustomToolTip/index"
import './style.css';
import Loading from '../loading';
import { fetchCatalogTitleDetails, fetchMoreCatalogTitleDetails } from '../../redux/actions/WebsnAppsAction';


const CatalogTitleDetails = (props) => {
  const { show, handleClose, selectedCompany, user, dateRange, selectedCatalog, catalogTitles } = props
  const dispatch = useDispatch();

  const [catalogDetailLoaidng, setCatalogDetailLoading] = useState(false)
  const [loadMoreCatalogDetailLoaidng, setLoadMoreCatalogDetailLoading] = useState(false)
  const [titleSkipIndex, setTitleSkipIndex] = useState(0);
  const titleLimit = 15;

  useEffect(() => {
    // console.log("user: ", user);
    // console.log("dateRange: ", dateRange);
    // console.log("selecteCatalog: ", selectedCatalog);
    loadCatalogDetails();
  }, [])

  const percentage = (obtain) => {
    let total = catalogTitles.catalog.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.totalActiveTime;
    }, 0);
    return Math.round(total <= 0 ? 0 : (obtain * 100) / total);
  };

  const loadCatalogDetails = () => {
    setCatalogDetailLoading(true);
    dispatch(fetchCatalogTitleDetails(selectedCompany.id, user.id, dateRange, selectedCatalog.id, 0, titleLimit))
      .then(() => {
        setCatalogDetailLoading(false);
        setTitleSkipIndex(titleSkipIndex + titleLimit);
      })
      .catch((error) => {
        console.log("error: ", error)
        setCatalogDetailLoading(false);
      })
  }

  const loadMoreCatalogDetails = () => {
    setLoadMoreCatalogDetailLoading(true);
    dispatch(fetchMoreCatalogTitleDetails(selectedCompany.id, user.id, dateRange, selectedCatalog.id, titleSkipIndex, titleLimit))
      .then(() => {
        setLoadMoreCatalogDetailLoading(false);
        setTitleSkipIndex(titleSkipIndex + titleLimit);
      })
      .catch((error) => {
        console.log("error: ", error)
        setLoadMoreCatalogDetailLoading(false);
      })
  }


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        className="singleUserTime-modal google-chrome-modal one"
      >
        <Modal.Header closeButton className='py-3 border-0 web-app-modal-header'>
          <Modal.Title className='w-100'>
            <div className="head d-flex align-items-center flex-wrap justify-content-between pe-4">
              <div className='name-left d-flex align-items-center'>
                <span className="d-flex justify-content-center align-items-center fs-12 me-2 text-white">{getcharacter(user.name)}</span>
                {/* <span className="d-flex justify-content-center align-items-center fs-12 me-2 text-white">{getcharacter(user.name)}</span> */}
                <div className='d-block ms-2 ms-md-2 margin'>

                  <div className='d-flex'>
                    <h3 className="m-0 text-black modal-heading">{user.name} </h3>
                    <h3 className="m-0 text-black modal-heading ms-1">- {selectedCatalog.name}</h3>
                  </div>
                  <p>{timeFormatInHMorMS(selectedCatalog.activeTime)}</p>
                </div>

              </div>
              <p className='date-right'>{isoToCustom(dateRange.dateRange[0]) + " TO " + isoToCustom(dateRange.dateRange[1])}</p>
            </div>
          </Modal.Title>

        </Modal.Header>
        <Modal.Body className='p-0 modal-min-height'>
          <div className='travas-model show' >



            {catalogDetailLoaidng ?
              <p className="text-center my-5 loader">
                <Loading />
              </p> :
              <>
                <div className='height-100vh mb-5 pb-lg-3 top-ten'>
                  <div div className='row-tr d-flex align-items-center pe-5 table-header-bg border-0' >
                    <div className='td tbl-td table-heading'>Title</div>
                    <div className='td tbl-td table-heading'>Time Tracked</div>
                    <div className='td tbl-td table-heading'>Productive Time</div>

                  </div>
                  <div className='scroll-websites table-paragraph'>
                    {catalogTitles.catalog.length > 0 ?
                     catalogTitles.catalog.map((v, index) => (
                      <div className='header-box d-flex align-items-center' key={index}>
                        <div div className='row-tr d-flex align-items-center w-100 pe-5' >
                          <div className='td d-flex align-items-center'> <img className='me-2' width={20} src="images/tab-svg.svg" alt="" /> {v.title}</div>
                          <div className='td ms-xl-1'>{timeFormatInHMorMS(v.totalActiveTime)}</div>
                          <div className='td-last d-flex align-items-center pe-5'>
                            <div className={selectedCatalog.ratingType + ' progress-box d-flex'}>
                              <p className='d-flex percentage'>{percentage(v.totalActiveTime) > 1 ? percentage(v.totalActiveTime) : "< 1"} %</p>
                              <ProgressBar variant="success" now={percentage(v.totalActiveTime)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )) : noDataSimple()}
                  </div>
                </div>
                {
                  (() => {
                    if (loadMoreCatalogDetailLoaidng) {
                      return (
                        <p className="loader-blue d-flex justify-content-center align-items-center my-4">
                          <Loading />
                        </p>
                      )
                    }
                    else if (catalogTitles.loadMore) {
                      return (
                        <>
                          <div className='loader-blue d-flex justify-content-center align-items-center my-4' onClick={loadMoreCatalogDetails}>
                            <span className='loading'>Show More</span>
                          </div>
                        </>
                      )
                    }
                    else {
                      return (
                        <></>
                      )
                    }
                  })()
                }
              </>
            }
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
}


const mapStateToProps = state => ({
  selectedCompany: state.UserReducer.selectedCompany,
  catalogTitles: state.WebsnAppsReducer.catalogTitles,
});

export default connect(mapStateToProps)(CatalogTitleDetails);

