import React, { useState } from 'react'
import './style.css';
import { NO_INTERNET } from '../../utils/Constants';



export default function NoInternet() {
  const [isVisible, setIsVisible] = useState(true);

  return isVisible ? (
    <>
      <div className='noInternetMainDiv d-flex'>
        <div className='noInternetIcon me-2'>
          <img src={"images/wifi.svg"} alt="NoInernet" />
        </div>
        <div className='noInternetText ms-2'>
          <p>{NO_INTERNET}</p>
        </div>
        <button className="btnClose" onClick={() => { setIsVisible(false); }}>
          <img src={"images/crossNotification.svg"} alt="NoInernet" />
        </button>
      </div>
    </>
  ) : null
}
