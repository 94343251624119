import React, {useState, useEffect} from 'react'
import './style.css';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker, Stack } from 'rsuite';
import { format, isToday, setHours, setMinutes, setSeconds } from 'date-fns';


const DateArrangePicker = (props) => {
	const { dateRange, onSelectionOK, selectedDate, maxAllowedDays } = props;
	const { combine, allowedMaxDays, afterToday } = DateRangePicker;
	const [selectDate, setSelectDate] = useState(selectedDate);
	useEffect(() => {
		setSelectDate(selectedDate)
	}, [selectedDate.dateRange])

	const onSelectionOKLocal = (range) => {
		const currentDate = new Date();
		let [startDate, endDate] = range;
	
		if (isToday(endDate)) {
		  endDate = setHours(setMinutes(setSeconds(endDate, currentDate.getSeconds()), currentDate.getMinutes()), currentDate.getHours());
		}
	
		setSelectDate({
		  ...selectedDate,
		  dateRange: [startDate, endDate]
		});
		onSelectionOK([startDate, endDate])
	};

	return (
		<div className='calendar-box d-flex justify-content-end'>
			<div className='calendar'>
				<div className='icon'>
					<Stack direction="column" spacing={8} alignItems="flex-start">
						<DateRangePicker
							character=" To "
							format='dd-MMM-yyyy'
							shouldDisableDate={combine(allowedMaxDays(maxAllowedDays), afterToday())}
							value={selectDate.dateRange}
							ranges={dateRange}
							placeholder={selectDate.label}
							onOk={onSelectionOKLocal}
						/>
					</Stack>
				</div>
			</div>
		</div>
	);
}
export default DateArrangePicker