
import React, { useState, useEffect, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, connect } from "react-redux";
import { Link } from 'react-router-dom';
import { fetchLogedInUserProfile, updateProfileSettings } from '../../redux/actions/UserAction';
import { updateProfileImage, fetchSingleUserSettings } from '../../redux/actions/UserManagementAction';
import './style.css';
import ResultMessage from '../ResultMessage';
import CompanyTimeZone from '../CompanyTimeZone';
import Loading from '../loading';
import { getLocalStorageValue } from '../../utils/PersistanceManager';


const ProfileView = (props) => {
  const { show, handleClose, updatedProfile, orgId, singleUserData, profileImgLoading } = props
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState(JSON.parse(getLocalStorageValue("User_Profile")));
  const [u_name, setName] = useState(userProfile.name);
  const [updateProfileSettingsLoading, setUpdateProfileSettingsLoading] = useState(false);
  const [updateFormLoading, setUpdateFormLoading] = useState(false);
  const [updateProfileImageLoading, setUpdateProfileImageLoading] = useState(false);
  const [userSettingsLoading, setUserSettingsLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [resultClass, setResultClass] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [warning, setWarning] = useState("");
  const [key, setKey] = useState('');
  const configRef = useRef();
  const [timeZoneValue, setTimeZoneValue] = useState("UTC");
  const [OffSetValue, setOffSetValue] = useState("+00:00");

  let save = false;

  useEffect(() => {
    setTimeZoneValue(singleUserData.timeZone)
    setOffSetValue(singleUserData.timeZoneOffset)
  }, [singleUserData.userId.length])


  useEffect(() => {
    dispatch(fetchSingleUserSettings(orgId, userProfile.id))
      .then(() => {
        setUserSettingsLoading(true);
      })
      .catch((error) => {
        setUserSettingsLoading(false);
      });
  }, []);

  useEffect(() => {
    key === "Settings" && configRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', });

    setTimeout(() => {
      setKey("");
    }, 1000);
  }, [key])


  const handleOnChange = (e) => {
    setName(e.target.value);
  }

  function setProfileSettings() {
    setUpdateFormLoading(true);
    if (password.length > 0) {
      if (password !== confirmPassword) {
        setResultClass("warning")
        setWarning("Password and Confirm Password mismatch");
        setUpdateFormLoading(false);
      } else if (password.length < 6) {
        setResultClass("warning")
        setWarning("Password should be at least six characters long")
        setUpdateFormLoading(false);
      } else {
        if (validate(password)) {
          UdateProfile();
        }
        else {
          setResultClass("warning")
          setWarning("Password must have at lease one special character and one capital letter")
          setUpdateFormLoading(false);
        }
      }
    } else {
      UdateProfile();
    }
  }

  function UdateProfile() {
    setUpdateProfileSettingsLoading(true);
    dispatch(updateProfileSettings(u_name, password, OffSetValue, timeZoneValue))
      .then(() => {
        console.log(OffSetValue)
        console.log(timeZoneValue)
        dispatch(fetchLogedInUserProfile()).then(() => {
          setIsAlertVisible(true);
          setResultClass("success")
          setWarning("Profile updated successfuly")
          setUpdateFormLoading(false);
          setTimeout(() => {
            setWarning("")
            setPassword("");
            setConfirmPassword("");
            setIsAlertVisible(false);
            setUpdateProfileSettingsLoading(false)
          }, 5000);
        })
      })
      .catch((error) => {
        console.log("error: ", error);
        setUpdateProfileSettingsLoading(false);
      });
  }

  function validate(password) {
    const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (reg.test(password) === false) {
      return false;
    }
    return true;
  }

  const [selectedImage, setSelectedImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setSelectedImage(acceptedFiles[0]);
    }
  };

  const handleScaleChange = (event) => {
    const newScale = parseFloat(event.target.value);
    setScale(newScale);
  };

  function uploadProfileImage(imgUrl) {
    setUpdateProfileImageLoading(true);
    profileImgLoading(true);
    var url_parts = imgUrl.split(';');
    var ext_type = url_parts[0].split('/')[1];
    var base64_img = url_parts[1].split(',')[1];
    dispatch(updateProfileImage(ext_type, base64_img))
      .then(() => {
        dispatch(fetchLogedInUserProfile()).then(() => {
          setSelectedImage(null)
          setUpdateProfileImageLoading(false);
          profileImgLoading(false);
        })
      })
      .catch((error) => {
        setUpdateProfileImageLoading(false);
        console.log("error: ", error);
      });

  }

  return (
    <>
      <Modal
        show={show} onHide={handleClose} backdrop="static" keyboard={false} className="Profile-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className='modal-heading m-0'>My Account</h3>
            {/* <p className='m-0 fs-14 fs-lg-16 text-black font-medium'>User profile</p> */}
          </Modal.Title>
        </Modal.Header>
        {Object.keys(singleUserData).length > 0 && userSettingsLoading ?
          <Modal.Body className='p-0'>

            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(k) => { setKey(k); }}
            >
              <Tab eventKey="profile" title="User Profile " className='active'>
                <div className='px-4 py-4 pb-5'>
                  {updateProfileImageLoading ?
                    <div className="text-center loader my-4 pt-1">
                      <Loading />
                    </div> :
                    <>
                      <div className='profile-img mb-2'>
                        <Dropzone onDrop={handleDrop} accept="image/*" className='mb-4 mt-5'>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <input {...getInputProps()} />
                              <Button {...getRootProps()}>
                                <img src={"images/pencil.svg"} />
                              </Button>
                              {selectedImage ? (
                                <AvatarEditor
                                  ref={(editor) => setEditor(editor)}
                                  image={selectedImage}
                                  border={0}
                                  scale={scale}
                                />
                              ) : (
                                (singleUserData.profileUrl).length > 0 ?
                                  <img src={singleUserData.profileUrl} /> :
                                  <img src={"images/user.svg"} />
                              )}
                            </div>
                          )}
                        </Dropzone>

                      </div>
                      {selectedImage && (
                        <div className='update-btn bg-transparent mb-3'>
                          <input
                            type="range"
                            value={scale}
                            min={1}
                            max={5}
                            step={0.01}
                            onChange={handleScaleChange}
                          />
                          <button
                            onClick={() => {
                              if (editor) {
                                const canvas = editor.getImageScaledToCanvas();
                                const imageURL = canvas.toDataURL();
                                uploadProfileImage(imageURL)
                              }
                            }}
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </>
                  }

                  <Form className='mt-5'>
                    <div className='overview-box '>
                      <h3 className={"user-profile  " + (key === "profile" ? " active" : " ")}>User Profile</h3>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={u_name} onChange={handleOnChange} />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control defaultValue={singleUserData.userEmail} disabled="true" />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}> <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control name='password' type="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value.trim()); setWarning(""); }} onBlur={() => { }} />
                        </Form.Group>
                        </Col>

                        <Col sm={6}>
                          <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control name='confirmPassword' type="password" placeholder="Password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value.trim()); setWarning(""); }} />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <label>Time Zone</label>
                          <CompanyTimeZone defaultTimeZone={singleUserData.timeZone} setTimeZoneValue={setTimeZoneValue} setOffSetValue={setOffSetValue} />
                        </Col>
                      </Row>
                    </div>

                    <div className='overview-box ' ref={configRef}>
                      <h3 className={"settings " + (key === "Settings" ? " active" : " ")}>Settings</h3>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3" controlId="screenshots">
                            <Form.Label>Screenshots</Form.Label>
                            <Form.Control defaultValue={singleUserData.settings.screenshotCaptureTime + " minutes"} disabled="true" />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="mb-3" controlId="timeafter">
                            <Form.Label>Timeout After</Form.Label>
                            <Form.Control defaultValue={singleUserData.settings.idleTime + " minutes"} disabled="true" />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3" controlId="userrole">
                            <Form.Label>User  Role</Form.Label>
                            <Form.Control defaultValue={singleUserData.userRole} disabled="true" />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group
                            className="mb-3 rate-group"
                            controlId="formGrouphour"
                          >
                            <Form.Label className="mb-2">
                              Hourly Rate
                            </Form.Label>
                            <div className="doller d-flex align-items-center">
                              <span>$</span>
                              <Form.Control type="number" defaultValue={singleUserData.hourRate} disabled="true" />
                              <p>/PerHour</p>
                            </div>

                          </Form.Group>
                        </Col>
                      </Row>
                      {warning.length > 0 && <ResultMessage messageType={resultClass} message={warning} />}
                      {updateFormLoading ?
                        <div className="text-center loader my-4 pt-1">
                          <Loading />
                        </div> :

                        <Form.Group>
                          <Button className='save-btn mt-3' variant="primary" disabled={u_name.trim().length <= 0 ? true : false} onClick={() => { setProfileSettings() }} >
                            Save
                          </Button>
                        </Form.Group>
                      }
                    </div>

                  </Form>
                </div>
              </Tab>



              <Tab eventKey="Settings" title="Settings">

              </Tab>

            </Tabs>





          </Modal.Body> :
          <div className="text-center loader my-4 pt-1">
            <Loading />
          </div>
        }
      </Modal >
    </>
  );
}
const mapStateToProps = state => ({
  updatedProfile: state.UserReducer.updatedProfile,
  singleUserData: state.UserManagementReducer.singleUserData,

});
export default connect(mapStateToProps)(ProfileView);
