import React, { useEffect, useState } from 'react'
import { VERSION } from '../../utils/Constants';
import { fetchReactVersion } from '../../redux/actions/SupportAction';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogedInUserProfile, fetchUserCompanies } from '../../redux/actions/UserAction';
import { SET_LEFTNAV_SUCCESS } from '../../redux/actions/Types';

const OnInitializtion = () => {
  const dispatch = useDispatch();
  const reactLink = useSelector((state) => state.SupportReducer.reactLink);
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const isClientView = useSelector((state) => state.SupportReducer.isClientView);



  useEffect(() => {
    dispatch({
      type: SET_LEFTNAV_SUCCESS,
      payload: { leftNavSelectedItem: { selectedItem: "", parentName: "" } },
    });


    if (isLoggedIn && !isClientView) {
      setInterval(() => {
        FetchReactVersion();
      }, 1200000);

      setTimeout(() => {
        FetchReactVersion();
      }, 5000);

      FetchUserDetials();
      FetchUserCompanies();
    }
  }, [])

  function FetchUserCompanies() {
    dispatch(fetchUserCompanies())
      .then(() => {
      })
      .catch(() => {
      });
  }

  const FetchReactVersion = () => {
    dispatch(fetchReactVersion())
      .then(() => {
        if (reactLink.length > 0) {
          if (VERSION !== reactLink[0].version) {
            // console.log("version checked");
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }

  const FetchUserDetials = () => {
    dispatch(fetchLogedInUserProfile())
    // .then(() => {
    //   console.log("User Profile Fetched");
    // })
    // .catch((error) => {
    //   console.log("error: ", error);
    // });
  }

  return null;
}

export default OnInitializtion