import {
    ADDGROUP_MEMBER_ADDED_SUCCESS,
    ADDGROUP_MEMBER_REMOVE_SUCCESS,
    ADDGROUP_ADMIN_ADDED_SUCCESS,
    ADDGROUP_ADMIN_REMOVE_SUCCESS,
    SET_ADD_GROUP_SUCCESS,
    SET_ADD_GROUP_FAILED,
    FETCH_GROUP_LIST_SUCCESS,
    FETCH_GROUP_LIST_FAILED,
    FETCH_GROUP_DETAILS_SUCCESS,
    FETCH_GROUP_DETAILS_FAILED,
    FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS,
    FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED,
    FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS,
    FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_FAILED,
    MODIFYGROUP_MEMBER_ADDED_SUCCESS,
    MODIFYGROUP_MEMBER_REMOVE_SUCCESS,
    MODIFYGROUP_ADMIN_ADDED_SUCCESS,
    MODIFYGROUP_ADMIN_REMOVE_SUCCESS,
    FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS,
    FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED,
    FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS,
    FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_FAILED,
    SET_UPDATE_GROUP_FAILED,
    SET_UPDATE_GROUP_SUCCESS,
    MODIFYGROUP_ADMIN_POPULATED_SUCCESS,
    MODIFYGROUP_MEMBER_POPULATED_SUCCESS,
    SET_CLOSE_GROUP_MODEL_SUCCESS,
    FETCH_MORE_GROUP_LIST_SUCCESS,
    FETCH_MORE_GROUP_LIST_FAILED,
    MODIFY_GROUP_ARCHIVE_STATUS,
} from "../actions/Types";


let initialState = {
    addGroupSearchedMembers: [],
    addGroupSearchedAdmins: [],
    addGroupSelectedMembers: [],
    addGroupSelectedAdmins: [],

    groupList: { groupList: [], loadMore: false },
    groupDetails: { groupId: "", users: [] },
    groupAdded: { message: "", status: 0, subCode: 0 },
    groupUpdated: { message: "", status: 0, subCode: 0 },

    modifyGroupSearchedMembers: [],
    modifyGroupSearchedAdmins: [],
    modifyGroupSelectedMembers: [],
    modifyGroupSelectedAdmins: [],
};


export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS:
            var selected = state.addGroupSelectedMembers;
            var gotten = payload.addGroupSearchedMembers;
            const filteredAddMembers = gotten.orgUsers.filter(item => !selected.some(a => a.userId === item.id));
            return {
                ...state,
                addGroupSearchedMembers: filteredAddMembers,
            };
        case FETCH_ADDGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED:
            return {
                ...state,
                addGroupSearchedMembers: [],
            };
        case FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS:
            var selected = state.addGroupSelectedAdmins;
            var gotten = payload.addGroupSearchedAdmins;
            const filteredAddAdmins = gotten.orgUsers.filter(item => !selected.some(a => a.userId === item.id));
            return {
                ...state,
                addGroupSearchedAdmins: filteredAddAdmins,
            };
        case FETCH_ADDGROUP_SEARCH_ADMINS_DROPDOWN_FAILED:
            return {
                ...state,
                addGroupSearchedAdmins: [],
            };

        case FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_SUCCESS:
            var selected = state.modifyGroupSelectedMembers;
            var gotten = payload.modifyGroupSearchedMembers;
            const filteredModifyMembers = gotten.orgUsers.filter(item => !selected.some(a => a.userId === item.id));
            return {
                ...state,
                modifyGroupSearchedMembers: filteredModifyMembers,
            };
        case FETCH_MODIFYGROUP_SEARCH_MEMBERS_DROPDOWN_FAILED:
            return {
                ...state,
                modifyGroupSearchedMembers: [],
            };
        case FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_SUCCESS:
            var selected = state.modifyGroupSelectedAdmins;
            var gotten = payload.modifyGroupSearchedAdmins;
            const filteredModifyAdmins = gotten.orgUsers.filter(item => !selected.some(a => a.userId === item.id));
            return {
                ...state,
                modifyGroupSearchedAdmins: filteredModifyAdmins,
            };
        case FETCH_MODIFYGROUP_SEARCH_ADMINS_DROPDOWN_FAILED:
            return {
                ...state,
                modifyGroupSearchedAdmins: [],
            };

        case ADDGROUP_MEMBER_ADDED_SUCCESS:
            console.log('as: ',payload.memberAdded);
            let memebers = state.addGroupSelectedMembers;
            memebers = memebers.concat(payload.memberAdded);

            return {
                ...state,
                addGroupSelectedMembers: memebers,
            };
        case ADDGROUP_MEMBER_REMOVE_SUCCESS:
            let arr = state.addGroupSelectedMembers;
            var filter = arr.filter(a => !(a === payload.memberRemoved));
            console.log(filter);
            return {
                ...state,
                addGroupSelectedMembers: filter
            };
        case ADDGROUP_ADMIN_ADDED_SUCCESS:
            let admins = state.addGroupSelectedAdmins;
            admins = admins.concat(payload.adminAdded);

            return {
                ...state,
                addGroupSelectedAdmins: admins,
            };
        case ADDGROUP_ADMIN_REMOVE_SUCCESS:
            let arr1 = state.addGroupSelectedAdmins;
            var filterapsa = arr1.filter(a => !(a === payload.adminRemoved));
            console.log(filterapsa);
            return {
                ...state,
                addGroupSelectedAdmins: filterapsa
            };

        case FETCH_GROUP_LIST_SUCCESS:
            return {
                ...state,
                groupList: {
                    groupList: payload.groupList.data
                    , loadMore: payload.loadMore
                },
            };
        case FETCH_GROUP_LIST_FAILED:
            return {
                ...state,
                groupList: { groupList: [], loadMore: false },
            };

        case FETCH_MORE_GROUP_LIST_SUCCESS:
            let list = state.groupList;
            let con = list.groupList.concat(payload.groupList.data);
            return {
                ...state,
                groupList: {
                    groupList: con
                    , loadMore: payload.loadMore
                },
            };
        case FETCH_MORE_GROUP_LIST_FAILED:
            return {
                ...state,
                groupList: { groupList: [], loadMore: false },
            };

        case FETCH_GROUP_DETAILS_SUCCESS:
            return {
                ...state,
                groupDetails: payload.groupDetails,
            };
        case FETCH_GROUP_DETAILS_FAILED:
            return {
                ...state,
                groupDetails: { grouptId: "", users: [] },
            };

        case SET_ADD_GROUP_SUCCESS:
            return {
                ...state,
                groupAdded: payload.groupAdded,
            };
        case SET_ADD_GROUP_FAILED:
            return {
                ...state,
                groupAdded: { message: "", status: 0, subCode: 0 },
            };

        case MODIFYGROUP_MEMBER_POPULATED_SUCCESS:
            return {
                ...state,
                modifyGroupSelectedMembers: payload.memberAdded,
            };
        case MODIFYGROUP_ADMIN_POPULATED_SUCCESS:
            return {
                ...state,
                modifyGroupSelectedAdmins: payload.adminAdded,
            };

        case MODIFYGROUP_MEMBER_ADDED_SUCCESS:
            let modify_memebers = state.modifyGroupSelectedMembers;
            modify_memebers = modify_memebers.concat(payload.memberAdded);

            return {
                ...state,
                modifyGroupSelectedMembers: modify_memebers,
            };
        case MODIFYGROUP_MEMBER_REMOVE_SUCCESS:
            let modifyGroupSelecteMembers = state.modifyGroupSelectedMembers;
            var filtermpsm = modifyGroupSelecteMembers.filter(a => !(a === payload.memberRemoved));
            return {
                ...state,
                modifyGroupSelectedMembers: filtermpsm
            };

        case MODIFYGROUP_ADMIN_ADDED_SUCCESS:
            let modifySelectedAdmins = state.modifyGroupSelectedAdmins;
            modifySelectedAdmins = modifySelectedAdmins.concat(payload.adminAdded);

            return {
                ...state,
                modifyGroupSelectedAdmins: modifySelectedAdmins,
            };
        case MODIFYGROUP_ADMIN_REMOVE_SUCCESS:
            let mpsa = state.modifyGroupSelectedAdmins;
            var filtermps = mpsa.filter(a => !(a === payload.adminRemoved));
            console.log(filtermps);
            return {
                ...state,
                modifyGroupSelectedAdmins: filtermps
            };

        case SET_UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                groupUpdated: payload.groupUpdated,
            };
        case SET_UPDATE_GROUP_FAILED:
            return {
                ...state,
                groupUpdated: { message: "", status: 0, subCode: 0 },
            };

        case SET_CLOSE_GROUP_MODEL_SUCCESS:
            return {
                ...state,

                groupAdded: { message: "", status: 0, subCode: 0 },
                groupUpdated: { message: "", status: 0, subCode: 0 },

                addGroupSearchedMembers: [],
                addGroupSearchedAdmins: [],
                addGroupSelectedMembers: [],
                addGroupSelectedAdmins: [],

                modifyGroupSearchedMembers: [],
                modifyGroupSearchedAdmins: [],
                modifyGroupSelectedMembers: [],
                modifyGroupSelectedAdmins: [],
            };

        case MODIFY_GROUP_ARCHIVE_STATUS:
            let old_grp = state.groupList;
            let ndt = old_grp.groupList.filter(a => !(a.groupId === payload.group));
            return {
                ...state,
                groupList: { groupList: ndt, loadMore: old_grp.loadMore },
            };

        default:
            return state;
    }
}
